import React from "react";
import { useScreenSize } from "../../../../Context/ScreenContext";

function PrivacyPolicy() {
  const { isSmallerThanLargeScreen } = useScreenSize();
  const detailStyle: any = {
    color: "var(--Text---primary, #323439)",
    fontFamily: "Satoshi-Regular",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px" /* 150% */,
  };

  const titleStyle: any = {
    color: "var(--Text---primary, #323439)",
    fontFamily: "Satoshi-Regular",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px" /* 150% */,
  };
  return (
    <div
      className={` mt-6 ${isSmallerThanLargeScreen ? "w-full" : "w-[500px]"}`}
    >
      <div>
        <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
          <li className="mb-[10px]">
            <div style={titleStyle}>1. Introduction</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                We are committed to protecting your privacy. This policy
                outlines our practices concerning the collection, use, and
                disclosure of your information when you use ChatFindr.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>2. Information Collection</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                We collect information you provide directly to us, such as when
                you create an account, and automatically through your use of our
                app, such as usage data.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>3. Use of Information</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <li className="mb-[5px]">
                  To provide and maintain our service.{" "}
                </li>{" "}
                <li className="mb-[5px]">
                  To notify you about changes to our service.
                </li>{" "}
                <li>
                  To allow you to participate in interactive features when you
                  choose to do so.
                </li>
              </li>
            </ul>
          </li>

          <li className="mb-[10px]">
            <div style={titleStyle}>4. Sharing of Information</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <li>
                  {" "}
                  We may share your information with third parties in specific
                  circumstances, such as complying with legal obligations or
                  protecting the safety of our users.
                </li>
              </li>
            </ul>
          </li>

          <li className="mb-[10px]">
            <div style={titleStyle}> 5. Data Security</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <li>
                  We strive to use commercially acceptable means to protect your
                  Personal Data, but we cannot guarantee its absolute security.
                </li>
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 6. Changes to This Privacy Policy</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 7. Contact Us</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                If you have any questions about these Terms or our Privacy
                Policy, please contact us at support@chatfindr.com
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
