import React, { useContext, useEffect, useRef, useState } from "react";
import { SIDE_BAR_ITEMS } from "../../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../../Context/NotificationContext";
import { useDash } from "../../../Context/DashboardContext";
import { useAuth } from "../../../Context/AuthContext";
import { useScreenSize } from "../../../Context/ScreenContext";
import {
  LogoutIcon,
  SettingsIcon,
  ArrowRightIcon,
  ProfileIcon,
  ChevronDownSideBarIcon,
  ChevronUpSideBarIcon,
} from "../../../Icons/Icons";
import { useChat } from "../../../Context/ChatContext";

function Sidebar({
  sideBarSelection,
  sideBarVisibility,
  setSideBarSelection,
  setSideBarVisibility,
  isBasicUserOnChatPage,
}: any) {
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const webSocketContext = useContext(NotificationContext);
  const { unreadMessagesCount } = useChat();
  const { unreadCount } = webSocketContext as any;
  const { user } = useDash();

  const { handleLogout } = useAuth();
  const { isMobile } = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);
  const [totalChatUnreadMessagesCount, setTotalChatUnreadMessagesCount] =
    useState<any>();

  useEffect(() => {
    const updateDvh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--dvh", `${vh}px`);
    };

    updateDvh();
    window.addEventListener("resize", updateDvh);

    return () => window.removeEventListener("resize", updateDvh);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSideBarVisibility(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [setSideBarVisibility]);

  const handleSidebarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (sideBarVisibility) {
      // Prevent scrolling on body
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh"; // Lock the body height
      document.body.style.position = "fixed";
      window.scrollTo(0, 0); // Prevent scrolling on iOS devices
    } else {
      // Re-enable scrolling on body
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.body.style.position = "";
    }
  }, [sideBarVisibility]);

  useEffect(() => {
    const totalUnreadMessages = Object.values(unreadMessagesCount).reduce(
      (acc: any, currentValue: any) => acc + currentValue,
      0,
    );
    setTotalChatUnreadMessagesCount(totalUnreadMessages);
  }, [unreadMessagesCount]);

  const handleMenuItemClick = (itemLabel: any, itemValue: any) => {
    const route = itemValue.toLowerCase();
    navigate(`/${route}`);
    setSideBarSelection(itemLabel);
    setSideBarVisibility(false);
    setIsOpen(false);
  };

  const handleLogoutAttempt = async () => {
    try {
      await handleLogout();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div>
      {/* {isBasicUserOnChatPage && (
        <div
          style={{ backdropFilter: "blur(8px)" }}
          className={`fixed top-0 ${
            isMobile ? "left-0" : "left-[280px]"
          } right-0 bottom-0 bg-black bg-opacity-[85%] z-40 flex justify-center items-center`}
        >
          <div className="text-center text-white p-4">
            <div style={{ fontSize: "32px" }}>🔒</div>
            <div
              style={{
                fontFamily: "Satoshi-Bold",
                fontStyle: "normal",
                lineHeight: "40px",
                fontSize: "32px",
              }}
            >
              Subscribe to access
            </div>
            <div
              className="mb-4"
              style={{
                fontFamily: "Satoshi-Regular",
                fontStyle: "normal",
                lineHeight: "24px",
                fontSize: "16px",
                color: "#7E858B",
              }}
            >
              Chat features are only available for paid subscribers
            </div>
            <button
              type="button"
              className="bg-regal-blue px-2 py-2 rounded-lg  items-center "
              onClick={() =>
                navigate("settings", {
                  state: { tab: 1 },
                })
              }
            >
              <div className="flex">
                <div
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginRight: "2px",
                  }}
                >
                  Subscribe Now
                </div>
                <ArrowRightIcon />
              </div>
            </button>
          </div>
        </div>
      )} */}

      <aside
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 xl:w-[320px] lg:w-[320px] md:w-[320px] sm:w-[320px] w-full transition-transform ${
          sideBarVisibility ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
        onClick={handleSidebarClick}
        style={{ height: "calc(var(--dvh, 1vh) * 100)" }}
      >
        <div className="h-full overflow-y-auto bg-white py-[2.96vh] px-[16px] border border-r-gray ">
          <div className="flex  items-center pb-[5.92vh]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 150 150"
              height="50"
              width="50"
            >
              <path
                fill="#5081ff"
                d="M82.29,19.74v10.1c18.26,0,33.1,14.85,33.1,33.1s-14.85,33.1-33.1,33.1c-3.12,0-6.14-.44-9-1.26-3.05-.85-5.94-2.14-8.56-3.8-2.56-1.59-4.87-3.53-6.9-5.75l-6.6,7.7-25.65,29.93c-1.6,1.87-1.39,4.71,.49,6.33,.84,.72,1.89,1.08,2.92,1.08,1.27,0,2.52-.53,3.41-1.57l25.7-29.98c2.52,1.72,5.25,3.18,8.12,4.32,4.97,2.01,10.39,3.11,16.07,3.11,23.82,0,43.2-19.38,43.2-43.2s-19.38-43.2-43.2-43.2Z"
              />
              <path
                fill="#5081ff"
                d="M45.6,85.72l7-8.17c-1.64-3.29-2.73-6.88-3.18-10.68h17.16c2.48,0,4.49-2.01,4.49-4.49,0-1.64-.88-3.06-2.18-3.84-.67-.42-1.47-.65-2.31-.65h-17.01c1.99-12.88,11.41-23.34,23.74-26.81v-10.4c-17.92,3.79-31.77,18.74-33.93,37.21-.19,1.66-.29,3.34-.29,5.05,0,1.32,.06,2.64,.18,3.93,.62,6.88,2.86,13.29,6.33,18.85Z"
              />
              <path
                fill="#5081ff"
                d="M82.29,67.99c1.12,0,2.17-.36,3-.99,.89-.65,1.51-1.58,1.83-2.66,.07-.21,.12-.42,.16-.63,.02-.12,.04-.26,.04-.38,.02-.13,.02-.26,.02-.39,0-.16-.01-.31-.02-.47-.24-2.57-2.39-4.58-5.03-4.58s-4.85,2.05-5.04,4.68c-.01,.12-.01,.25-.01,.37,0,2.79,2.26,5.05,5.05,5.05Z"
              />
              <path
                fill="#5081ff"
                d="M97.44,67.99c2.79,0,5.05-2.26,5.05-5.05s-2.26-5.05-5.05-5.05c-.7,0-1.36,.13-1.96,.39-1.82,.77-3.09,2.56-3.09,4.66s1.28,3.89,3.1,4.66c.59,.26,1.26,.39,1.95,.39Z"
              />
            </svg>

            <div
              style={{
                fontFamily: "Satoshi-Bold",
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "-1px",
              }}
            >
              ChatFindr
            </div>
          </div>
          <ul>
            {SIDE_BAR_ITEMS.map((item, index) => (
              <li key={index} className="mb-[1.85vh]">
                <button
                  type="button"
                  onClick={() => handleMenuItemClick(item.label, item.value)}
                  className={`px-1 py-1 flex w-full items-center justify-between rounded-lg hover:bg-slate-50 font-light  ${
                    sideBarSelection === item.label ? "bg-slate-50 border " : ""
                  }`}
                >
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-10 h-7  ${
                        sideBarSelection === item.label
                          ? "text-regal-blue"
                          : "text-[#7E858B]"
                      }`}
                    >
                      <path d={item.icon} />

                      {item.secondIcon && <path d={item.secondIcon} />}
                    </svg>

                    <span
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontStyle: "normal",
                      }}
                      className={` ${
                        sideBarSelection === item.label
                          ? "text-regal-blue"
                          : "text-[#7E858B]"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>

                  {item.label === "Notifications" && unreadCount > 0 && (
                    <span
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                      className="text-white bg-regal-blue border rounded px-3 py-[1px]"
                    >
                      {unreadCount}
                    </span>
                  )}

                  {/* {item.label === "Chat" &&
                    user?.subscription?.tier === "FREE" && (
                      <span
                        style={{
                          fontFamily: "Satoshi-Regular",
                          fontSize: "12px",
                          lineHeight: "16px",
                        }}
                        className="text-white bg-regal-blue border rounded px-3 py-[1px]"
                      >
                        Subscribe
                      </span>
                    )} */}

                  {item.label === "Chat" &&
                    totalChatUnreadMessagesCount > 0 && (
                      <span
                        style={{
                          fontFamily: "Satoshi-Regular",
                          fontSize: "12px",
                          lineHeight: "16px",
                        }}
                        className="text-white bg-regal-blue border rounded px-3 py-[1px]"
                      >
                        {totalChatUnreadMessagesCount}
                      </span>
                    )}
                </button>
              </li>
            ))}
          </ul>
          {isOpen && (
            <div className="fixed bottom-16 inset-x-0 mb-10 flex flex-col justify-between px-[12px] py-[16px] rounded-lg border mx-4">
              <button
                type="button"
                className="flex items-center mb-[20px] w-full"
                onClick={() => {
                  navigate(`profile/${user?.id}`);
                  setSideBarVisibility(false);
                  setIsOpen(false);
                }}
              >
                <ProfileIcon />

                <div
                  style={{
                    fontFamily: "Satoshi-regular",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    marginLeft: "8px",
                  }}
                >
                  Profile
                </div>
              </button>

              <button
                type="button"
                className="flex items-center w-full mb-[20px]"
                onClick={() => {
                  navigate("settings");
                  setSideBarVisibility(false);
                  setIsOpen(false);
                }}
              >
                <SettingsIcon />

                <div
                  style={{
                    fontFamily: "Satoshi-regular",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    marginLeft: "8px",
                  }}
                >
                  Settings
                </div>
              </button>

              <button
                type="button"
                className="flex items-center  w-full"
                onClick={() => {
                  handleLogoutAttempt();
                }}
              >
                <LogoutIcon />

                <div
                  style={{
                    fontFamily: "Satoshi-regular",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    marginLeft: "8px",
                  }}
                >
                  Logout
                </div>
              </button>
            </div>
          )}
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="fixed bottom-0 inset-x-0 mb-10 flex flex-col justify-between px-2 py-2 rounded-lg border mx-4"
          >
            <div className="flex items-center justify-between w-full">
              {/* User info and icon */}
              <div className="flex items-center">
                <img
                  className="rounded-full"
                  src={user?.photo}
                  alt={user?.name}
                  style={{
                    width: "36px",
                    height: "36px",
                    objectFit: "cover",
                    marginRight: "8px",
                  }}
                />
                <div
                  style={{
                    fontFamily: "Satoshi-regular",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                  }}
                >
                  {user?.firstName + " " + user?.lastName}
                </div>
              </div>
              {isOpen ? <ChevronDownSideBarIcon /> : <ChevronUpSideBarIcon />}
            </div>
          </button>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
