import React, { useEffect, useRef, useState } from "react";
import { CameraIcon } from "@heroicons/react/outline";
import {
  formatDate,
  getAttributeColor,
  toPascalCase,
} from "../../Constants/Constants";
import { useScreenSize } from "../../Context/ScreenContext";
import { LockIcon } from "../../Icons/Icons";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";

interface IEventPanelProps {
  events: any[];
  showAll?: boolean;
  onEventClick?: (name: string, id: string, eventOrganizer?: string) => void;
  title?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  height?: string;
  marginTop?: string;
  titleColor?: string;
  groupId?: any;
  paddingB?: number;
  subHeader?: string;
}

const IEventPanel: React.FC<IEventPanelProps> = ({
  events,
  onEventClick,
  title,
  buttonLabel,
  onButtonClick,
  height = events?.length ? "" : "h-[244px]",
  marginTop = "mt-0",
  titleColor,
  subHeader,
}) => {
  const { isMobile } = useScreenSize();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextSet = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrevSet = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const containerRef = useRef<any>(null);
  const [visibleCount, setVisibleCount] = useState(4); // Default to 4

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const groupWidth = isMobile ? 160 : 220; // Adjust based on your group width
        setVisibleCount(Math.floor(containerWidth / groupWidth));
      }
    };

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  return (
    <div
      style={{
        marginTop,
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div
        className={` rounded-lg ${isMobile ? "" : "bg-white"} ${height} ${
          isMobile ? "" : "px-[24px] pb-[2.22vh] "
        } pt-[2.22vh]  `}
      >
        <div className="flex justify-between items-center mb-1">
          <div
            style={{
              color: titleColor,
              fontFamily: "Satoshi-Regular",
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "-1px",
              fontStyle: "normal",
            }}
          >
            {title}
          </div>

          {buttonLabel && (
            <button
              type="button"
              className=" border rounded px-4 py-1 my-auto"
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "13px",
                lineHeight: "16px",
                fontStyle: "normal",
              }}
              onClick={(e) => {
                e.stopPropagation();
                onButtonClick && onButtonClick();
              }}
            >
              {buttonLabel}
            </button>
          )}
        </div>
        <div
          className="mb-[10px]"
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          {subHeader}
        </div>

        {events?.length === 0 && (
          <div
            className={`flex ${
              isMobile ? "h-[80%]" : "h-[50%]"
            } justify-center items-center`}
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "14px",
              lineHeight: "18px",
              color: "#7E858B",
            }}
          >
            No Events
          </div>
        )}

        <div ref={containerRef} className="relative">
          {!isMobile && currentIndex > 0 && (
            <button
              onClick={handlePrevSet}
              aria-label="Previous set"
              className="absolute left-0 z-10"
              style={{
                top: "35%",
                transform: "translateY(-50%) translateX(-50%)", // Move it halfway off the container to the left
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                borderRadius: "50%",
                width: "40px", // Adjust size as needed
                height: "40px", // Adjust size as needed
              }}
            >
              <ArrowLeftIcon style={{ color: "white" }} />
            </button>
          )}

          <div
            className={`${
              !isMobile
                ? "flex gap-[16px]"
                : "mobile-scroll-container flex overflow-x-scroll scroll-snap-x snap-mandatory pt-1 pb-4 space-x-2"
            }`}
            style={{
              transform: `translateX(${-100 * currentIndex}%)`,
              transition: "transform 0.5s ease-out",
              width: "100%",
            }}
          >
            {events?.map((event, index) => (
              <div
                key={index}
                style={{
                  maxWidth: "200px",
                }}
                className={`${
                  isMobile
                    ? "w-[160px] rounded-lg flex flex-col snap-start  shrink-0"
                    : "min-w-[220px]"
                }`}
                onClick={() =>
                  onEventClick &&
                  onEventClick(event.name, event.id, event.organizerId)
                }
              >
                <div
                  className={`${
                    isMobile ? "h-[100px]" : "h-[128px]"
                  } overflow-hidden relative `}
                >
                  {event.photo ? (
                    <img
                      src={event.photo}
                      alt={event.name}
                      className="w-full h-full object-cover"
                      style={{ borderRadius: "8px" }}
                    />
                  ) : (
                    <div className="border flex justify-center items-center w-full h-full">
                      <CameraIcon className="w-20 h-20 text-gray-500" />
                    </div>
                  )}
                  <span className="absolute top-2 right-2 text-white">
                    {event.privacy === "PRIVATE" ? (
                      <>
                        <div
                          style={{
                            fontFamily: "Satoshi-Bold",
                            fontSize: "10px",
                            lineHeight: "12px",
                            letterSpacing: "0.6px",
                            borderRadius: "99px",
                            backgroundColor: "rgba(0, 0, 0, 0.35)",
                          }}
                          className="flex px-[12px] py-[4px]"
                        >
                          <LockIcon />
                          <div className="ml-1">PRIVATE</div>
                        </div>
                      </>
                    ) : event.privacy === "RESTRICTED" ? (
                      <div
                        style={{
                          fontFamily: "Satoshi-Bold",
                          fontSize: "10px",
                          lineHeight: "12px",
                          letterSpacing: "0.6px",
                          borderRadius: "99px",
                          backgroundColor: "rgba(0, 0, 0, 0.35)",
                        }}
                        className="px-[12px] py-[4px]"
                      >
                        RESTRICTED
                      </div>
                    ) : (
                      <div
                        style={{
                          fontFamily: "Satoshi-Bold",
                          fontSize: "10px",
                          lineHeight: "12px",
                          letterSpacing: "0.6px",
                          borderRadius: "99px",
                          backgroundColor: "rgba(0, 0, 0, 0.35)",
                        }}
                        className="px-[12px] py-[4px]"
                      >
                        PUBLIC
                      </div>
                    )}
                  </span>
                </div>

                <div className=" pt-[4px] pb-[4px] flex items-center justify-between">
                  <div
                    className="truncate text-[#323439]"
                    style={{
                      fontFamily: "Satoshi-Bold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "20px",
                      letterSpacing: "-0.5px",
                    }}
                  >
                    {event.name}
                  </div>
                  <div className="flex items-center ml-3">
                    <span
                      className="text-[#7E858B] truncate "
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "10px",
                        lineHeight: "12px",
                        letterSpacing: "0.06px",
                      }}
                    >
                      {event?.maxAttendees === 0
                        ? event.attendees
                        : event?.attendees + " / " + event?.maxAttendees}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      className="ml-1"
                    >
                      <g clip-path="url(#clip0_151_2443)">
                        <path
                          d="M8.5 10.5V9.5C8.5 8.96957 8.28929 8.46086 7.91421 8.08579C7.53914 7.71071 7.03043 7.5 6.5 7.5H2.5C1.96957 7.5 1.46086 7.71071 1.08579 8.08579C0.710714 8.46086 0.5 8.96957 0.5 9.5V10.5M11.5 10.5V9.5C11.4997 9.05686 11.3522 8.62639 11.0807 8.27616C10.8092 7.92593 10.4291 7.67578 10 7.565M8 1.565C8.43021 1.67515 8.81152 1.92535 9.08382 2.27616C9.35612 2.62696 9.50392 3.05841 9.50392 3.5025C9.50392 3.94659 9.35612 4.37804 9.08382 4.72884C8.81152 5.07965 8.43021 5.32985 8 5.44M6.5 3.5C6.5 4.60457 5.60457 5.5 4.5 5.5C3.39543 5.5 2.5 4.60457 2.5 3.5C2.5 2.39543 3.39543 1.5 4.5 1.5C5.60457 1.5 6.5 2.39543 6.5 3.5Z"
                          stroke="#7E858B"
                          stroke-width="1.4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_151_2443">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

                <div className="pb-[4px] flex items-center justify-between">
                  <div
                    className=" truncate "
                    style={{
                      color: getAttributeColor(event.attribute),
                      fontFamily: "Satoshi-Regular",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "16px",
                    }}
                  >
                    {toPascalCase(event.category)}
                  </div>
                </div>

                <div className=" text-[#7E858B] flex items-center justify-between">
                  <div
                    style={{
                      color: titleColor,
                      fontFamily: "Satoshi-Regular",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "16px",
                    }}
                  >
                    {formatDate(event.startTime, isMobile)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!isMobile && currentIndex + visibleCount < events?.length && (
            <button
              onClick={handleNextSet}
              aria-label="Next set"
              className="absolute right-0 z-10"
              style={{
                top: "35%",
                transform: "translateY(-50%) translateX(50%)", // Move it halfway off the container
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                borderRadius: "50%",
                width: "40px", // Adjust size as needed
                height: "40px", // Adjust size as needed
              }}
            >
              <ArrowRightIcon style={{ color: "white" }} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default IEventPanel;
