import React, { useState, useRef, createRef } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useAuthService } from "../../../Services/AuthService/AuthService";
import { useGeolocation } from "../../../Context/GeoLocationContext";
import { useNavigate } from "react-router-dom";

function Notification({ userData }: any) {
  const [digits, setDigits] = useState(["", "", "", ""]);
  const { handleLogin, activeStates } = useAuth();
  const { verifyUser } = useAuthService();
  const { location } = useGeolocation();
  const navigate = useNavigate();

  const digitRefs = useRef<any>([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const handleChange = async (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      if (value && index === 3) {
        // Once the last digit is entered
        const verificationCode = newDigits.join("");

        try {
          const verifyResponse = await verifyUser(verificationCode);
          console.log(userData);
          console.log(activeStates);
          if (!activeStates.includes(userData.state)) {
            navigate("/waitlist");
          } else {
            try {
              await handleLogin({
                authType: "EMAIL",
                authIdentifier: userData.email,
                password: userData.password,
              });
            } catch (loginError) {
              console.error("Login failed:", loginError);
            }
          }
        } catch (verifyError) {
          setDigits(["", "", "", ""]);
          digitRefs.current[0]?.focus();
          return;
        }
      } else if (value && index < 3) {
        // Auto-focus to next input if a digit is entered
        digitRefs.current[index + 1]?.focus();
      }
    }
  };

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-col" style={{ width: "360px" }}>
        <div
          className="mb-[8px]"
          style={{
            fontFamily: "Satoshi-Bold",
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.5px",
          }}
        >
          Email Sent
        </div>
        <div
          className="mb-[24px]"
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#323439",
          }}
        >
          Check your inbox for the code to activate your account, expires in 24
          hours.
        </div>
        <div className="flex justify-between">
          {digits.map((digit: any, index: any) => (
            <input
              key={index}
              ref={(el) => (digitRefs.current[index] = el)} // Assign the ref to the corresponding input
              className="w-14 h-14 border rounded text-center text-lg"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              type="tel"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Notification;
