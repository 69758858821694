import React, { useContext, useState, useEffect } from "react";
import { useUserService } from "../Services/UserService/UserService";

const DashboardContext = React.createContext<any>("");

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(true);

  const { fetchUserProfile } = useUserService();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await fetchUserProfile();
        setUser(userProfile);
      } catch (error) {
        // Handle error, e.g., by setting an error state
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserFields = (fieldsToUpdate: any) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      ...fieldsToUpdate,
    }));
  };

  const joinGroup = (groupId: string) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      joinedGroups: [...prevUser?.joinedGroups, groupId],
    }));
  };

  const leaveGroup = (groupId: string) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      joinedGroups: prevUser.joinedGroups.filter((id: any) => id !== groupId),
    }));
  };

  const joinEvent = (eventId: string) => {
    console.log(eventId);
    setUser((prevUser: any) => ({
      ...prevUser,
      joinedEvents: [...prevUser?.joinedEvents, eventId],
    }));
  };

  const leaveEvent = (eventId: string) => {
    setUser((prevUser: any) => ({
      ...prevUser,
      joinedEvents: prevUser.joinedEvents.filter((id: any) => id !== eventId),
    }));
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <DashboardContext.Provider
      value={{
        user,
        isLoading,
        updateUserFields,
        joinGroup,
        leaveGroup,
        joinEvent,
        leaveEvent,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDash = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
