import React, { useEffect, useState } from "react";
import { useChat } from "../../../Context/ChatContext";
import { useScreenSize } from "../../../Context/ScreenContext";
import { useParams } from "react-router-dom";
import ChatMessages from "./ChatMessages/ChatMessages";
import ChatGroups from "./ChatGroups";

function Chat() {
  const { groups, chatHistories, chatTimestamps } = useChat();
  const { isSmallerThanLargeScreen, isLargeScreen } = useScreenSize();
  const { groupId } = useParams();
  const [sortedGroups, setSortedGroups] = useState<any>();

  // const [sortedGroups, setSortedGroups] = useState([]);

  useEffect(() => {
    if (groups && chatTimestamps) {
      const sorted: any = groups
        .map((group) => ({ ...group }))

        .sort((a, b) => {
          const timeStampA = new Date(
            chatTimestamps[a.id] || new Date(a.creationDate),
          ).getTime();
          const timeStampB = new Date(
            chatTimestamps[b.id] || new Date(b.creationDate),
          ).getTime();

          return timeStampB - timeStampA;
        });
      setSortedGroups(sorted);
    }
  }, [groups, chatTimestamps]);

  return (
    <div>
      {isLargeScreen && (
        <div className="flex">
          <div className="w-2/5">
            <ChatGroups
              groups={sortedGroups}
              chatHistories={chatHistories}
              chatTimestamps={chatTimestamps}
            />
          </div>
          <div className="w-3/5 relative">
            <ChatMessages />
          </div>
        </div>
      )}
      {isSmallerThanLargeScreen && (
        <div className="w-full relative">
          {!groupId && (
            <ChatGroups
              groups={sortedGroups}
              chatHistories={chatHistories}
              chatTimestamps={chatTimestamps}
            />
          )}
          {groupId && <ChatMessages />}
        </div>
      )}
    </div>
  );
}

export default Chat;
