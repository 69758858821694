/* eslint-disable react-hooks/exhaustive-deps */
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../Constants/Constants";
import { HeartIcon } from "../../../../Icons/Icons";
import { useDash } from "../../../../Context/DashboardContext";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { useChatService } from "../../../../Services/ChatService/ChatService";
import { useNavigate } from "react-router-dom";

function Messages({
  index,
  msg,
  selectedMessageIndex,
  setSelectedMessageIndex,
  currentChatHistory,
}: any) {
  const [stroke, setStroke] = useState<any>("");
  const [fill, setFill] = useState<any>("");
  const { user } = useDash();
  const { isMobile } = useScreenSize();
  const { likeMessage } = useChatService();
  const navigate = useNavigate();

  useEffect(() => {
    if (msg.likedBy.length === 0) {
      setStroke("#7E858B");
      setFill("none");
    }

    if (msg.likedBy.length > 0 && msg.likedBy.includes(user?.id)) {
      setStroke("#C41E3A");
      setFill("#C41E3A");
    }

    if (msg.likedBy.length > 0 && !msg.likedBy.includes(user?.id)) {
      setStroke("#7E858B");
      setFill("#7E858B");
    }
  }, [msg]);

  const handleLikeMessage = async (id: any) => {
    try {
      await likeMessage(id);
    } catch (error) {
      console.error("Failed to upload photo:", error);
    }
  };

  const getDisplayClass = () => {
    switch (msg?.type) {
      case "TEXT":
        return "flex justify-between w-full ";
      case "IMAGE":
        return "flex justify-between w-full";
      case "JOIN_GROUP":
        return "flex justify-center items-center w-full";
      case "LEAVE_GROUP":
        return "flex justify-center items-center w-full";
      case "TRANSFER_GROUP":
        return "flex justify-center items-center w-full";
      case "CREATE_EVENT":
        return "flex justify-center items-center w-full";
      case "JOIN_EVENT":
        return "flex justify-center items-center w-full";
      case "LEAVE_EVENT":
        return "flex justify-center items-center w-full";
    }
  };

  return (
    <div className={getDisplayClass()}>
      {(msg.type === "TEXT" && msg.message) ||
      (msg.type === "IMAGE" && msg.imageUrl) ? (
        <>
          <div>
            <div
              key={index}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#7E858B",
              }}
              className="ml-12 mb-1"
            >
              {msg.senderFirstName + " " + msg.senderLastName}
            </div>
            <div className="flex  pr-10" style={{ maxWidth: "100%" }}>
              <div
                onClick={() => navigate(`/profile/${msg.senderId}`)}
                className="mt-[8px]"
                style={{ width: "auto", flexShrink: 0, flexGrow: 0 }}
              >
                <img
                  src={msg.senderPhoto || "default-avatar.png"}
                  alt="Sender"
                  className="h-6 w-6 rounded-full mr-3"
                />
              </div>

              {msg.type === "IMAGE" && (
                <img
                  style={{ borderRadius: "8px" }}
                  src={msg.imageUrl || "default-avatar.png"}
                  alt="Sender"
                  className="h-[220px] w-[220px] mr-4"
                />
              )}
              {msg.type === "TEXT" && msg.message && (
                <div
                  style={{
                    backgroundColor:
                      msg.senderId !== user.id ? "#f5f5f5" : "#5081FF",
                    padding: "8px",
                    color: msg.senderId !== user.id ? "black" : "white",
                    borderRadius: "15px 15px 15px 0",
                    wordBreak: "break-word",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "13px",
                    }}
                    onClick={() => {
                      setSelectedMessageIndex(
                        selectedMessageIndex === index ? null : index,
                      );
                    }}
                  >
                    {msg.message}
                  </Typography>

                  {selectedMessageIndex === index && (
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "10px",
                        paddingTop: "4px",
                      }}
                    >
                      {formatDate(msg.timestamp, isMobile)}
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className=" mt-[29px] flex"
            onClick={() => handleLikeMessage(msg.id)}
          >
            <HeartIcon stroke={stroke} fill={fill} />
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "10px",
                paddingTop: "4px",
                color: fill,
                minWidth: "4px",
                visibility: msg.likedBy.length === 0 ? "hidden" : "visible",
              }}
            >
              {msg.likedBy.length > 0 ? msg.likedBy.length : " "}
            </div>
          </div>
        </>
      ) : null}

      {(msg.type === "JOIN_GROUP" ||
        msg.type === "LEAVE_GROUP" ||
        msg.type === "TRANSFER_GROUP" ||
        msg.type === "CREATE_EVENT" ||
        msg.type === "JOIN_EVENT" ||
        msg.type === "LEAVE_EVENT") && (
        <div
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          {msg.message}
        </div>
      )}
    </div>
  );
}

export default Messages;
