import React from "react";
import { useChat } from "../../../Context/ChatContext";

function ChatGroupsCard({ handleGroupClick, group }: any) {
  const { unreadMessagesCount } = useChat();

  return (
    <div
      style={{ borderRadius: "8px" }}
      className="h-[76px] bg-white mb-[16px] flex items-center px-[12px]"
      onClick={() => handleGroupClick(group)}
    >
      <div className="flex justify-between w-full items-center">
        <div className="flex items-center">
          <div>
            {" "}
            <img
              className="rounded-full"
              src={group.photo}
              alt={group.name}
              style={{
                width: "52px",
                height: "52px",
                objectFit: "cover",
                marginRight: "8px",
              }}
            />
          </div>
          <div>
            {" "}
            <div
              style={{
                fontFamily: "Satoshi-Bold",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
                fontSize: "16px",
              }}
            >
              {group.name}
            </div>
            <div
              className="text-[#7E858B]"
              style={{
                fontFamily: "Satoshi-Regular",
                lineHeight: "16px",
                letterSpacing: "-0.5px",
                fontSize: "12px",
                marginTop: "4px",
              }}
            >
              {Object.keys(group?.members).length === 1
                ? `${Object.keys(group?.members).length} Member`
                : `${Object.keys(group?.members).length} Members`}
            </div>
          </div>
        </div>
        <div>
          {unreadMessagesCount[group.id] > 0 && (
            <div>
              <span
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
                className="bg-regal-blue text-white border rounded px-3 py-[1px]"
              >
                {unreadMessagesCount[group.id]}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatGroupsCard;
