import React, { useEffect, useState } from "react";
import ILabel from "../../../Library/Label/ILabel";

import GroupLeaderBoard from "./LeaderBoardTables/GroupLeaderBoard";
import UserLeaderBoard from "./LeaderBoardTables/UserLeaderBoard";
import IMenuButtonFilter from "../../../Library/MenuButtonFilter/IMenuButtonFilter";
import { useScreenSize } from "../../../Context/ScreenContext";
import { useDash } from "../../../Context/DashboardContext";
import {
  MARGIN,
  calculateAge,
  toPascalCase,
} from "../../../Constants/Constants";
import ISpinner from "../../../Library/Spinner/ISpinner";
import LeaderBoardCard from "./LoadBoardCard/LeadBoardCard";
import { useLeaderboardService } from "../../../Services/LeaderboardService/LeaderboardService";

function Leaderboard() {
  const { getLeaderboard } = useLeaderboardService();
  const { user } = useDash();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const [location, setLocation] = useState("city");
  const [category, setCategory] = useState("user");
  const [attribute, setAttribute] = useState("");
  const [topThree, setTopThree] = useState<any>([]);
  const [otherItems, setOtherItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);

  const filterOptions = [
    {
      label: "Location",
      options: [
        { label: "City", value: "city" },
        { label: "State", value: "state" },
        { label: "Global", value: "global" },
      ],
      onChange: setLocation,
      selectedValue: location,
    },
    {
      label: "Category",
      options: [
        { label: "User", value: "user" },
        { label: "Group", value: "group" },
      ],
      onChange: setCategory,
      selectedValue: category,
    },
    {
      label: "Type",
      options: [
        { label: "Overall", value: "" },
        { label: "Social", value: "SOCIAL" },
        { label: "Intelligence", value: "INTELLIGENCE" },
        { label: "Fitness", value: "FITNESS" },
        { label: "Nightlife", value: "NIGHTLIFE" },
        { label: "Adventure", value: "ADVENTURE" },
        { label: "Culture", value: "CULTURE" },
      ],
      onChange: setAttribute,
      selectedValue: attribute,
    },
  ];

  const getLocation = () => {
    switch (location) {
      case "city":
        return `city=${user.city}`;
      case "state":
        return `state=${user.state}`;
      case "global":
        return "";
    }
  };

  const getUserRank = () => {
    switch (location) {
      case "city":
        return user?.cityRank;
      case "state":
        return user?.stateRank;
      case "global":
        return user?.globalRank;
    }
  };
  const loadMoreItems = async () => {
    if (hasMore && !isLoading) {
      loadNextPage(page);
      setPage(page + 1);
      setIsLoading(false);
    }
  };

  const loadNextPage = async (nextPage: any) => {
    const response = await getLeaderboard(
      `category=${category}&${getLocation()}&attribute=${attribute}&page=${nextPage}&size=15`,
    );
    if (response.content.length === 0) {
      setHasMore(false);
    } else {
      setOtherItems((prevItems: any) => [...prevItems, ...response.content]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Ensure loading state is true at the start
      try {
        const response = await getLeaderboard(
          `category=${category}&${getLocation()}&attribute=${attribute}&page=0&size=15`,
        );
        const newItems = response.content;

        // Assuming the first fetch should reset and display the first page
        const newTopThree = newItems.slice(0, 3);
        const newOtherItems = newItems.slice(3);

        setTopThree(newTopThree);
        setOtherItems(newOtherItems);
        setPage(1); // Ensure the page state is correct after the initial load
        setHasMore(response.content.length === 15); // Check if there's more data to expect
      } catch (error) {
        setHasMore(false); // Update hasMore in case of an error
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, location, attribute]);

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.LEADER_BOARD : MARGIN.LARGE.LEADER_BOARD;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <div className="flex gap-[24px]">
        <div className="flex flex-col w-full ">
          <div
            className={`flex justify-between items-center ${
              !isSmallerThanLargeScreen
                ? " mt-[2.96vh] mb-[2.96vh]"
                : "mt-[1.48vh] mb-4"
            } `}
          >
            <div>
              <ILabel text="Leaderboard"></ILabel>
              <div
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#7E858B",
                }}
              >
                {`${category === "user" ? "User" : "Group"} rankings for ${
                  location === "global"
                    ? "Global"
                    : location === "state"
                    ? user.state
                    : user.city
                }${attribute ? `: ${toPascalCase(attribute)}` : ": Overall"}`}
              </div>
            </div>

            <IMenuButtonFilter filterOptions={filterOptions} />
          </div>

          <div>
            {isSmallerThanLargeScreen && category === "user" && (
              <div className="mb-2">
                <LeaderBoardCard
                  item={user}
                  category="user"
                  rank={getUserRank()}
                  name={user.firstName + " " + user?.lastName}
                  img={user?.photo}
                  firstLabel="LEVEL"
                  firstValue={user?.level}
                  secondLabel="AGE"
                  secondValue={calculateAge(user?.dateOfBirth)}
                  attribute={user?.topAttribute}
                  className="border-2 border-regal-blue"
                />
                <div
                  className="mt-4 mb-4"
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "17px",
                    lineHeight: "22px",
                    color: "#7E858B",
                  }}
                >
                  Leaderboard
                </div>
              </div>
            )}
          </div>

          <div className="xl:flex gap-[16px]">
            {Array.from({ length: 3 }).map((_, index) => {
              const item = topThree?.[index];
              const name =
                category === "group"
                  ? item?.name
                  : `${item?.firstName || ""} ${item?.lastName || ""}`.trim();
              return (
                <div
                  key={item ? item.id : `placeholder-${index}`}
                  className="w-full xl:w-1/3 mb-2 "
                >
                  <LeaderBoardCard
                    category={category}
                    item={item}
                    rank={index + 1}
                    img={item?.photo}
                    name={name}
                    firstLabel={category === "group" ? "MEMBERS" : "LEVEL"}
                    firstValue={
                      category === "group" ? item?.members : item?.level
                    }
                    secondLabel={category === "group" ? "REPUTATION" : "AGE"}
                    secondValue={
                      category === "group" ? item?.reputation : item?.age
                    }
                    attribute={
                      category === "group"
                        ? item?.attribute
                        : item?.topAttribute
                    }
                  />
                </div>
              );
            })}
          </div>

          {category === "group" && (
            <div>
              <GroupLeaderBoard groups={otherItems} loadMore={loadMoreItems} />
            </div>
          )}
          {category === "user" && (
            <div>
              <UserLeaderBoard
                users={otherItems}
                loadMore={loadMoreItems}
                location={location}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
