import React, { useState } from "react";

import { useScreenSize } from "../../../Context/ScreenContext";
import { useSubscriptionService } from "../../../Services/SubscriptionService/SubscriptionService";
import { Logo } from "../../../Icons/Icons";

function DashFooter() {
  const { isMobile } = useScreenSize();
  const [email, setEmail] = useState("");
  const { addSubscriber } = useSubscriptionService();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await addSubscriber(email);
      setIsSubscribed(true);
      setTimeout(() => setIsSubscribed(false), 3000);
      setEmail("");
    } catch (error) {}
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(e as unknown as React.FormEvent);
    }
  };
  return (
    <div className="bg-[#232427]">
      {" "}
      <div
        className={` max-w-[1440px] mx-auto ${
          isMobile ? "text-center px-[25px]" : "px-[144px]"
        } py-[124px] `}
      >
        <div
          className="mb-[12px]"
          style={{
            fontFamily: "Satoshi-Bold",
            color: "white",
            lineHeight: "48px",
            letterSpacing: "-1px",
            fontSize: isMobile ? "32px" : "40px",
          }}
        >
          Subscribe for updates!
        </div>
        <div
          style={{
            fontFamily: "Satoshi-Regular",
            color: "#7E858B",
            lineHeight: "32px",
            letterSpacing: "-0.5px",
            fontSize: isMobile ? "14px" : "18px",
          }}
        >
          Stay informed with the latest features and news from ChatFindr. Enter
          your email to receive regular updates.
        </div>
        <form
          onSubmit={handleSubmit}
          className={`flex gap-2 ${isMobile ? "justify-center" : ""} mt-[24px]`}
        >
          <input
            className="py-[10px] px-[16px] w-full max-w-[320px]"
            placeholder="email@address.com"
            value={email}
            onKeyDown={handleKeyDown}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              borderRadius: "8px",
              fontFamily: "Satoshi-Regular",
              color: "#7E858B",
              lineHeight: "24px",
              fontSize: "16px",
            }}
          />{" "}
          <div>
            {" "}
            <button
              type="submit"
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "24px",
                borderRadius: "8px",
              }}
              className="bg-regal-blue text-white px-[10px] py-[10px]"
            >
              Subscribe
            </button>
          </div>
        </form>
        {isSubscribed && (
          <div
            style={{
              fontFamily: "Satoshi-Regular",
              color: "green",
              lineHeight: "32px",
              letterSpacing: ".5px",
              fontSize: isMobile ? "14px" : "16px",
            }}
          >
            You're now subscribed!
          </div>
        )}
      </div>
      <div
        className={`flex justify-between items-center max-w-[1440px] mx-auto  border-t  ${
          isMobile ? "text-center  px-[25px]" : "px-[144px]"
        } py-[40px] `}
      >
        <button type="button" className="flex gap-2 items-center bg-[#232427]">
          <Logo />
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-1px",
              color: "white",
            }}
          >
            ChatFindr
          </div>
        </button>
        <div className="flex gap-[16px]">
          <div>
            <a
              href="https://www.facebook.com/chatfindr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/facebook.png" alt="Facebook" />
            </a>
          </div>

          <div>
            <a
              href="https://www.instagram.com/chatfindr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/instagram.png" alt="Instagram" />
            </a>
          </div>
          <div>
            <a
              href="https://www.tiktok.com/@chatfindr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/tiktok.png" alt="TikTok" />
            </a>
          </div>
          <div>
            <a
              href="https://x.com/chatfindr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/X.png" alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashFooter;
