import React, { useEffect, useState } from "react";
import IInput from "../../../Library/Input/IInput";
import {
  validateDay,
  validateFirstName,
  validateGender,
  validateLastName,
  validateMonth,
  validateYear,
} from "./GeneralInformationValidators";
import {
  validateCity,
  validateImageFile,
  validateState,
} from "../Step3/DetailFormValidators";
import IDropdown from "../../../Library/Dropdown/IDropdown";
import IComboBox from "../../../Library/ComboBox/ComboBox";
import {
  statesAndCities,
  uniqueStatesWithLabelValue,
} from "../../../States/StatesAndCities";
import { useGeolocation } from "../../../Context/GeoLocationContext";
import { useUserService } from "../../../Services/UserService/UserService";
function GeneralInformationForm({
  onNextStep,
  userData,
  updateUser,
  handleBack,
}: any) {
  // const handleContinue = (): void => {
  //   const canProceed = validateGeneralInformation();

  //   if (!canProceed) {
  //     return;
  //   }

  //   onNextStep();
  // };
  const { createUser } = useUserService();
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [, setMessage] = useState<any>("");
  const [, setIsError] = useState<boolean>();
  const [image] = useState<any>();
  const [cities, setCities] = useState<Array<{ label: string; value: string }>>(
    [],
  );

  const { location } = useGeolocation();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        handleContinue();
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [userData]);

  const [citiesLoaded, setCitiesLoaded] = useState(false);

  // Fetch cities based on userData.state or state change
  useEffect(() => {
    if (userData.state || state) {
      const citiesInState = statesAndCities
        .filter((item) => item.state === (userData.state || state))
        .map((item) => item.city); // Get an array of city names

      const uniqueCities = Array.from(new Set(citiesInState)) // Create a set to remove duplicates, then convert back to array
        .map((city) => ({ label: city, value: city })); // Map to the desired object shape

      setCities(uniqueCities);
      setCity("");
      setCitiesLoaded(true);
    }
  }, [userData.state, state]);

  useEffect(() => {
    setCity("");
  }, [state, userData.state]);

  // Set state from location.state
  useEffect(() => {
    if (location.state) {
      setState(location.state);
      setCitiesLoaded(false); // Reset citiesLoaded when state changes
    }
  }, [location.state]);

  // Set city from location.city after state and cities are loaded
  useEffect(() => {
    if (citiesLoaded && location.city) {
      setCity(location.city);
    }
  }, [citiesLoaded, location.city]);

  const [detailErrors, setDetailErrors] = useState({
    cityError: "",
    stateError: "",
    photoError: "",
  });

  const validateDetails = () => {
    const cityError = city ? validateCity(city) : validateCity(userData.city);
    const stateError = state
      ? validateState(state)
      : validateCity(userData.state);
    const photoError = validateImageFile(image);

    setDetailErrors({
      cityError,
      stateError,
      photoError,
    });

    return !cityError && !stateError && !photoError;
  };

  const handleContinue = async (): Promise<void> => {
    const canProceedDetails = validateDetails();
    const canProceedInfo = validateGeneralInformation();

    if (!canProceedDetails && !canProceedInfo) {
      return;
    }

    const user = {
      authDto: {
        authType: "EMAIL",
        authIdentifier: userData.email,
        password: userData.password,
      },
      role: "USER",
      firstName: userData.firstName,
      lastName: userData.lastName,
      gender: userData.gender.toUpperCase(),
      city: city ? city : userData.city,
      state: state ? state : userData.state,
      dateOfBirth: userData.year + "-" + userData.month + "-" + userData.day,
    };

    console.log(user);
    console.log(city + " city ");
    console.log(state + " state ");

    try {
      await createUser(user);
      onNextStep();
    } catch (error) {
      setMessage("Something went wrong");
      setIsError(true);
    }
  };

  const dayOptions = Array.from({ length: 31 }, (_, index) => ({
    label: (index + 1).toString().padStart(2, "0").toString(),
    value: (index + 1).toString().padStart(2, "0").toString(),
  }));

  const monthOptions: any = [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
  ];

  const startYear = 1950;
  const currentYear = new Date().getFullYear(); // Assuming it's 2024 now

  const yearOptions = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => ({
      label: (startYear + index).toString(),
      value: (startYear + index).toString(),
    }),
  );

  const genderOptions: any = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
    { label: "Prefer not to say", value: "NONE" },
  ];
  const [generalInformationErrors, setGeneralInformationErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    dayError: "",
    monthError: "",
    yearError: "",
    genderError: "",
  });

  const validateGeneralInformation = () => {
    const firstNameError = validateFirstName(userData.firstName);
    const lastNameError = validateLastName(userData.lastName);
    const dayError = validateDay(userData.day);
    const monthError = validateMonth(userData.month);
    const yearError = validateYear(userData.year);
    const genderError = validateGender(userData.gender);

    setGeneralInformationErrors({
      firstNameError,
      lastNameError,
      dayError,
      monthError,
      yearError,
      genderError,
    });

    return (
      !firstNameError &&
      !lastNameError &&
      !dayError &&
      !monthError &&
      !yearError &&
      !genderError
    );
  };

  return (
    <div>
      <div className="grid-rows-2">
        <div className="h-screen flex items-center justify-center">
          <div className="grid grid-rows" style={{ width: "360px" }}>
            <div
              className="mb-3"
              style={{
                fontFamily: "Satoshi-Bold",
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "-0.5px",
              }}
            >
              Account Information
            </div>
            <div>
              <IInput
                name="firstName"
                placeholder="Alex"
                label="First Name"
                value={userData.firstName || ""}
                onChange={(e) =>
                  updateUser({ ...userData, firstName: e.target.value })
                }
                errorMessage={generalInformationErrors.firstNameError}
              />
            </div>
            <div className="mt-4">
              <IInput
                name="lastName"
                placeholder="Costa"
                label="Last Name"
                value={userData.lastName || ""}
                onChange={(e) =>
                  updateUser({ ...userData, lastName: e.target.value })
                }
                errorMessage={generalInformationErrors.lastNameError}
              />
            </div>

            <div className="mt-4 relative">
              <div className="flex">
                <div className="w-full mr-2 ">
                  <IDropdown
                    labelText="Birth Date"
                    options={monthOptions}
                    placeholder="Month"
                    value={userData.month}
                    onChange={(value) =>
                      updateUser({ ...userData, month: value })
                    }
                    errorMessage={
                      generalInformationErrors.dayError ||
                      generalInformationErrors.monthError ||
                      generalInformationErrors.yearError
                    }
                  />
                </div>

                <div className="w-full mr-2 mt-6">
                  <IDropdown
                    options={dayOptions}
                    placeholder="Day"
                    value={userData.day}
                    onChange={(value) =>
                      updateUser({ ...userData, day: value })
                    }
                    labelText=""
                  />
                </div>

                <div className="w-full mt-6">
                  <IDropdown
                    labelText=""
                    options={yearOptions}
                    placeholder="Year"
                    value={userData.year}
                    onChange={(value) =>
                      updateUser({ ...userData, year: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
              <div>
                <IComboBox
                  labelText="Location"
                  placeholder="State"
                  value={state}
                  onChange={(newValue) => {
                    setState(newValue);
                    updateUser({ ...userData, state: newValue });
                  }}
                  options={uniqueStatesWithLabelValue}
                  errorMessage={
                    detailErrors.stateError || detailErrors.cityError
                  }
                />
              </div>
              <div className="mt-6">
                <IComboBox
                  labelText=""
                  placeholder="City"
                  value={city}
                  onChange={(newValue) => {
                    setCity(newValue);
                    updateUser({ ...userData, city: newValue });
                  }}
                  options={cities}
                />
              </div>
            </div>
            <div>
              <div className="w-6/12 mt-4">
                <IDropdown
                  labelText="Gender"
                  options={genderOptions}
                  placeholder="Select"
                  value={userData.gender}
                  onChange={(value) =>
                    updateUser({ ...userData, gender: value })
                  }
                  errorMessage={generalInformationErrors.genderError}
                />
              </div>
            </div>

            <div>
              <button
                onClick={handleContinue}
                className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
                type="button"
              >
                Continue
              </button>
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="button"
                onClick={handleBack}
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  fontStyle: "normal",
                  lineHeight: "16px",
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformationForm;
