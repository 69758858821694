/* eslint-disable no-useless-escape */
export const validateFirstName = (firstName: string): string => {
  return firstName.trim() === "" ? "Required" : "";
};

export const validateLastName = (lastName: string): string => {
  return lastName.trim() === "" ? "Required" : "";
};

export const validateDay = (day: string): string => {
  return day.trim() === "" ? "Required" : "";
};

export const validateMonth = (month: string): string => {
  return month.trim() === "" ? "Required" : "";
};

export const validateYear = (year: string): string => {
  return year.trim() === "" ? "Required" : "";
};

export const validateGender = (gender: string): string => {
  return gender.trim() === "" ? "Required" : "";
};
