import React, { useEffect, useState } from "react";

import ILabel from "../../../../Library/Label/ILabel";
import IUserTable from "../../../../Library/IUserTable/IUserTable";
import { useNavigate, useParams } from "react-router-dom";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { MARGIN } from "../../../../Constants/Constants";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { useEventService } from "../../../../Services/EventService/EventService";

function EventAttendeesList() {
  const navigate = useNavigate();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { eventId } = useParams();
  const [attendees, setAttendees] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { getEventAttendees } = useEventService();

  const fetchMembers = async () => {
    try {
      const data = await getEventAttendees(eventId);

      setAttendees(data);
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      fetchMembers();
    } catch (error) {}
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.MEMBERS : MARGIN.LARGE.MEMBERS;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Attendees" />
      </div>

      <div className="pb-4">
        <IUserTable
          users={attendees}
          onRowClick={(userId) => {
            navigate(`/profile/${userId}`);
          }}
        />
      </div>
    </div>
  );
}

export default EventAttendeesList;
