import React from "react";
import { useNavigate } from "react-router-dom";
import { useScreenSize } from "../../../Context/ScreenContext";
import { Logo } from "../../../Icons/Icons";

function DashBodyHeader() {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  return (
    <div className="h-[65px] max-w-[1440px] mx-auto border-b flex items-center">
      <div
        className={`flex justify-between text-center w-full ${
          isMobile ? "px-[25px]" : "px-[144px]"
        }`}
      >
        <button type="button" className="flex gap-2 items-center">
          <Logo />
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-1px",
              color: "#323439",
            }}
          >
            ChatFindr
          </div>
        </button>
        <div className="flex items-center gap-[16px]">
          <div>
            <button
              type="button"
              onClick={() => {
                navigate("/signup");
              }}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                fontStyle: "normal",
                lineHeight: "20px",
                borderRadius: "8px",
              }}
              className={`bg-[#323439] text-white ${
                isMobile ? "px-[10px] py-[10px]" : "px-[16px] py-[10px]"
              }`}
            >
              Sign Up
            </button>
          </div>
          <div>
            {" "}
            <button
              onClick={() => {
                navigate("/login");
              }}
              type="button"
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                fontStyle: "normal",
                lineHeight: "20px",
                borderRadius: "8px",
              }}
              className={`border px-[16px] py-[10px] ${
                isMobile ? "px-[10px] py-[10px]" : "px-[16px] py-[10px]"
              }`}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBodyHeader;
