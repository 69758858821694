// chatService.ts
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useChatService = () => {
  const axiosInstance = useAxios();

  const getChatHistory = async (groupId: any, page: any, joinedDate: any) => {
    const response = await axiosInstance.get(`/chat/${groupId}/history`, {
      params: { page, size: 15, joinedDate },
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const postMessage = async (groupId: any, message: any) => {
    const response = await axiosInstance.post(
      `/chat/${groupId}/message`,
      { message },
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const postPhoto = async (id: any, image: any) => {
    const response = await axiosInstance.post(`/chat/${id}/image`, image, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const likeMessage = async (id: any) => {
    const response = await axiosInstance.put(
      `/chat/${id}/like`,
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const readMessages = async (id: any) => {
    const response = await axiosInstance.put(
      `/chat/${id}/read`,
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  return {
    getChatHistory,
    postMessage,
    postPhoto,
    likeMessage,
    readMessages,
  };
};
