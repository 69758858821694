import React from "react";
import { useNavigate } from "react-router-dom";
import { DiscoverGroupsOn } from "../../../Constants/Constants";
import { useScreenSize } from "../../../Context/ScreenContext";

function PasswordNotification() {
  const navigate = useNavigate();
  const backToLogin = () => {
    navigate("/login");
  };
  const { isSmallerThanLargeScreen } = useScreenSize();
  return (
    <div className="w-screen h-screen relative overflow-hidden flex justify-center items-center">
      <div className="absolute inset-0 w-1/2 h-full bg-white"></div>
      {!isSmallerThanLargeScreen && (
        <div className="absolute inset-0 left-1/2 w-1/2 h-full bg-gradient-to-tl from-slate-ish via-regal-blue to-white"></div>
      )}

      {/* Content Container */}
      <div className="w-full max-w-[1440px] h-full flex z-10">
        <div
          className={`h-full flex items-center justify-center relative ${
            isSmallerThanLargeScreen ? "w-full" : "w-1/2"
          }`}
        >
          <div className="max-w-[720px] w-full flex items-center justify-center">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="flex flex-col" style={{ width: "350px" }}>
                <div className="mb-3 text-2xl font-medium">Email was sent</div>
                <div className="font-thin">
                  Link to reset your current password was sent to your email
                  address
                </div>
                <div>
                  <button
                    onClick={backToLogin}
                    className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
                  >
                    Go back to login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 pl-[32px] pb-[32px] "
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          ©Copyright ChatFindr 2024
        </div>
        {!isSmallerThanLargeScreen && (
          <div className="w-1/2 h-full flex items-center justify-center">
            <div className="max-w-[720px] w-full flex items-center justify-center">
              <DiscoverGroupsOn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PasswordNotification;
