import React from "react";

import { useScreenSize } from "../../../Context/ScreenContext";
import { Link } from "react-scroll";

function DashHeader() {
  const { isMobile } = useScreenSize();
  return (
    <div className="bg-regal-blue">
      {" "}
      <div
        className={`max-w-[1440px] mx-auto h-[48px]  flex items-center justify-between py-[10px] ${
          isMobile ? "px-[10px]" : "px-[36px]"
        }`}
      >
        <div className="flex justify-center gap-[12px] flex-grow ">
          {!isMobile && (
            <Link
              to="footer"
              smooth={true}
              duration={500}
              className="bg-white rounded-full px-[10px] py-[8px] text-regal-blue cursor-pointer"
              style={{
                fontFamily: "Satoshi-Bold",
                fontSize: "10px",
                fontStyle: "normal",
                lineHeight: "12px",
                letterSpacing: "0.6px",
              }}
            >
              SUBSCRIBE FOR UPDATES
            </Link>
          )}

          <button
            className="border rounded-full px-[10px] py-[8px] text-white"
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "10px",
              fontStyle: "normal",
              lineHeight: "12px",
              letterSpacing: "0.6px",
            }}
          >
            MOBILE APPLICATION COMING SOON
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashHeader;
