import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export const getAgeAnalytics = ({
  analytics,
  setFirstSectionCount,
  setSecondSectionCount,
  setThirdSectionCount,
  eventDashboard,
}: any) => {
  const minAge = eventDashboard.event.requirements.minimumAge;
  const maxAge = eventDashboard.event.requirements.maximumAge;
  const totalRange = maxAge - minAge;
  const sectionLength = Math.floor(totalRange / 3);

  let firstSectionEnd = minAge + sectionLength;
  let secondSectionEnd = firstSectionEnd + sectionLength;

  // Labels for the sections
  const firstSectionLabel = `${minAge} - ${firstSectionEnd}`;
  const secondSectionLabel = `${firstSectionEnd + 1} - ${secondSectionEnd}`;
  const thirdSectionLabel = `${secondSectionEnd + 1} - ${maxAge}`;

  // Initialize counters for each age section
  let firstSectionCount = 0;
  let secondSectionCount = 0;
  let thirdSectionCount = 0;

  const keys = Object.keys(analytics);

  keys.forEach((age) => {
    const ageNumber = parseInt(age);

    if (ageNumber <= firstSectionEnd) {
      firstSectionCount += analytics[age];
    } else if (ageNumber > firstSectionEnd && ageNumber <= secondSectionEnd) {
      secondSectionCount += analytics[age];
    } else if (ageNumber > secondSectionEnd) {
      thirdSectionCount += analytics[age];
    }
  });

  setFirstSectionCount({
    count: firstSectionCount,
    label: firstSectionLabel,
  });
  setSecondSectionCount({
    count: secondSectionCount,
    label: secondSectionLabel,
  });
  setThirdSectionCount({
    count: thirdSectionCount,
    label: thirdSectionLabel,
  });
};

export const getGenderAnalytics = ({
  analytics,
  setMaleAnalytics,
  setFemaleAnalytics,
  setOtherAnalytics,
}: any) => {
  setMaleAnalytics(analytics["MALE"]);
  setFemaleAnalytics(analytics["FEMALE"]);
  setOtherAnalytics(analytics["OTHER"]);
};

export const GenderPieChart = ({
  otherAnalytics,
  femaleAnalytics,
  maleAnalytics,
}: any) => {
  console.log(otherAnalytics);
  console.log(femaleAnalytics);
  console.log(maleAnalytics);
  return (
    <PieChart
      series={[
        {
          data: [
            {
              id: 0,
              value: otherAnalytics,
              label: "Other",
              color: "rgba(80, 129, 255, 0.5)",
            },
            {
              id: 1,
              value: femaleAnalytics,
              label: "Female",
              color: "rgba(80, 129, 255, 0.7)",
            },
            {
              id: 2,
              value: maleAnalytics,
              label: "Male",
              color: "rgba(80, 129, 255, 0.9)",
            },
          ],
          highlightScope: { faded: "global", highlighted: "item" },
          faded: {
            innerRadius: 30,
            additionalRadius: -30,
            color: "gray",
          },
        },
      ]}
    />
  );
};

export const AgePieChart = ({
  firstSectionCount,
  secondSectionCount,
  thirdSectionCount,
}: any) => {
  console.log(firstSectionCount);
  console.log(secondSectionCount);
  console.log(thirdSectionCount);
  return (
    <PieChart
      series={[
        {
          data: [
            {
              id: 0,
              value: firstSectionCount.count,
              label: firstSectionCount.label,
              color: "rgba(80, 129, 255, 0.3)",
            }, // Change color for series A
            {
              id: 1,
              value: secondSectionCount.count,
              label: secondSectionCount.label,
              color: "rgba(80, 129, 255, 0.5)",
            }, // Change color for series B
            {
              id: 2,
              value: thirdSectionCount.count,
              label: thirdSectionCount.label,
              color: "rgba(80, 129, 255, 0.7)",
            },
          ],
          highlightScope: { faded: "global", highlighted: "item" },
          faded: {
            innerRadius: 30,
            additionalRadius: -30,
            color: "gray",
          },
        },
      ]}
    />
  );
};
