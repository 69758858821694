import React from "react";
import "./ICheckbox.css"; // Import your custom CSS styles

interface ICheckboxProps {
  label?: string;
}

function ICheckbox({ label }: ICheckboxProps) {
  return (
    <div className="flex items-center">
      <input type="checkbox" id="rememberMe" className="mr-2" />
      <div
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        {label}
      </div>
    </div>
  );
}

export default ICheckbox;
