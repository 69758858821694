export const validateImageFile = (
  image: File | string | null | undefined,
): string => {
  // Checks if image is not provided or is an empty string (for URL case)
  return !image || (typeof image === "string" && image.trim() === "")
    ? "Required"
    : "";
};

export const validateCity = (city: string): string => {
  return city === "" ? "Required" : "";
};

export const validateState = (state: any): string => {
  return state === "" ? "Required" : "";
};
