import * as React from "react";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import "./IDateTimePicker.css";
import IToolTip from "../ToolTip/IToolTip";

export default function IDateTimePicker({
  label,
  value,
  onChange,
  errorMessage,
}: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="flex mb-1">
        {label && (
          <label
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {label}
          </label>
        )}
        {/* <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div> */}
      </div>

      <MobileDateTimePicker
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          const isoDate = newValue ? newValue.toISOString() : null;
          onChange(isoDate); // Pass the ISO string up to the parent component
        }}
        disablePast={true}
        className="my-date-picker mt-1 bg-gray-50  border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      <div
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "12px",
          lineHeight: "20px",
          fontStyle: "normal",
          color: "red",
        }}
      >
        {errorMessage && errorMessage}
      </div>
    </LocalizationProvider>
  );
}
