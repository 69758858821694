// notificationService.js
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useNotificationService = () => {
  const axiosInstance = useAxios();

  const getNotifications = async () => {
    const response = await axiosInstance.get("/notifications");
    return response.data;
  };

  const readNotification = async (id: any) => {
    const response = await axiosInstance.put(`/notifications/${id}/read`);
    return response.data;
  };

  const readAllNotifications = async () => {
    const response = await axiosInstance.put("/notifications/read-all");
    return response.data;
  };

  const removeNotification = async (id: any) => {
    const response = await axiosInstance.delete(`/notifications/${id}`);
    return response.data;
  };

  return {
    getNotifications,
    readNotification,
    readAllNotifications,
    removeNotification,
  };
};
