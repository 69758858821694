import React, { ChangeEvent, useState, FormEvent } from "react";
import IInput from "../../../../Library/Input/IInput";
import IButton from "../../../../Library/Button/IButton";
import Toast from "../../../../Library/Toast/Toast";
import ConfirmationDialog from "../../../../Library/ConfirmationDialog/ConfirmationDialog";
import { useAuth } from "../../../../Context/AuthContext";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { TOAST_MESSAGES } from "../../../../Constants/Constants";
function Security() {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<any>();
  const { deleteProfile } = useAuth();
  const { isSmallerThanLargeScreen } = useScreenSize();

  const handlePasswordChange = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    if (
      !currentPassword.trim() ||
      !newPassword.trim() ||
      !confirmPassword.trim()
    ) {
      setMessage("No changes detected.");
      setIsError(true);
      return; // Exit the function early if any field is empty
    }

    if (newPassword === confirmPassword) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/password`,
          {
            credentials: "include",
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              currentPassword: currentPassword,
              newPassword: newPassword,
            }),
          },
        );

        if (response.ok) {
          setMessage(TOAST_MESSAGES.PASSWORD.SUCCESS);
          setIsError(false);
        } else {
          setMessage(TOAST_MESSAGES.SUPPORT.FAILURE);
          setIsError(true);
        }
      } catch (error) {
        setMessage("An error occurred. Please try again later.");
        setIsError(true);
      }
    } else {
      setMessage("New Password and Confirm Password do not match.");
      setIsError(true);
    }
  };

  const handleDeleteAccount = async () => {
    await deleteProfile();
  };

  return (
    <div>
      <ConfirmationDialog
        show={showDialog}
        onConfirm={handleDeleteAccount}
        onClose={() => setShowDialog(false)}
        message={"Are you sure?"}
        subMessage={
          "By Agreeing to this, you are permenantly deleting your account."
        }
        confirm={"Delete my account"}
      />
      <form onSubmit={handlePasswordChange}>
        <div className="w-full mt-6">
          <Toast message={message} isError={isError} setMessage={setMessage} />
          <div
            className={`${isSmallerThanLargeScreen ? "w-full" : "w-[460px]"}`}
          >
            <div className="pb-6">
              <IInput
                name="currentPassword"
                label="Current Password"
                placeholder="********"
                type="password"
                value={currentPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCurrentPassword(e.target.value)
                }
              />
            </div>
            <form></form>
            <div className="pb-6">
              <IInput
                name="newPassword"
                label="New Password"
                placeholder="********"
                type="password"
                value={newPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewPassword(e.target.value)
                }
              />
            </div>
            <div className="pb-6">
              <IInput
                name="confirmPassword"
                label="Confirm Password"
                placeholder="********"
                type="password"
                value={confirmPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </div>
          </div>

          <div className=" flex pb-6">
            <IButton
              text="Update Password"
              type="submit"
              className="px-6 mr-2"
              bgColor="bg-regal-blue"
              textColor="text-white"
            />
            <IButton
              text="Delete Account"
              onClick={() => setShowDialog(true)}
              type="button"
              className="px-6 border"
              textColor="text-white"
              bgColor="bg-[#DC143C]"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Security;
