import React, { useEffect, useState } from "react";
import ShowAllGroups from "../Groups/GroupsShowAll/ShowAllGroups";
import { useParams } from "react-router-dom";
import { useSearchContext } from "../../../Context/SearchContext";
import { useDash } from "../../../Context/DashboardContext";
import { useGroupService } from "../../../Services/GroupService/GroupService";

function DiscoverFilteredResults() {
  const { searchTerm } = useParams();
  const { searchParams } = useSearchContext();
  const { user } = useDash();
  const [filteredResults, setFilteredResults] = useState<any>();
  const [page] = useState(1);
  const { getGroupDiscoveryShowAll } = useGroupService();

  useEffect(() => {
    let isMounted = true;
    let searchUrl =
      searchTerm === "search"
        ? `search?city=${user?.city}&nameContains=${searchParams.searchTerm}&privacy=${searchParams.selectedPrivacy}&attribute=${searchParams.selectedAttribute}&page=1`
        : `${searchTerm}?city=${user.city}&page=1`;

    const fetchData = async () => {
      try {
        const filteredData = await getGroupDiscoveryShowAll(searchUrl);

        if (isMounted) {
          setFilteredResults(filteredData || []);
        }
      } catch (error) {
        if (isMounted) {
          setFilteredResults([]);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [
    searchTerm,
    searchParams.searchTerm,
    searchParams.selectedPrivacy,
    searchParams.selectedAttribute,
    user?.city,
  ]);

  const fetchMore = async (page: any) => {
    const searchUrl =
      searchTerm === "search"
        ? `search?nameContains=${searchParams.searchTerm}&privacy=${searchParams.selectedPrivacy}&attribute=${searchParams.selectedAttribute}&page=${page}`
        : `${searchTerm}?page=${page}`;
    try {
      const data = await getGroupDiscoveryShowAll(searchUrl);
      return data;
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="pb-4 h-screen ">
      {filteredResults?.length === 0 ? (
        <div
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#7E858B",
          }}
          className="h-[60%] text-center flex items-center justify-center p-4"
        >
          No groups found. <br /> Adjust your filters to find groups.
        </div>
      ) : (
        <ShowAllGroups
          initialGroups={filteredResults}
          fetchGroupsFunction={fetchMore}
          initialPage={page}
          pageSize={12}
        />
      )}
    </div>
  );
}

export default DiscoverFilteredResults;
