import React, { useEffect, useState } from "react";
import ISearch from "../../../Library/Search/ISearch";
import ISpinner from "../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../Context/ScreenContext";
import ILabel from "../../../Library/Label/ILabel";
import { MARGIN } from "../../../Constants/Constants";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSearchContext } from "../../../Context/SearchContext";
import IGroupPanel from "../../../Library/GroupPanel/IGroupPanel";
import { useGroupService } from "../../../Services/GroupService/GroupService";

function Discover() {
  const { searchParams, setSearchParams } = useSearchContext();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const [isLoading, setIsLoading] = useState(true);
  const [discoveryData, setDiscoveryData] = useState<any>();
  const { getGroupDiscovery } = useGroupService();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/discover") {
      setSearchParams({
        searchTerm: "",
        selectedPrivacy: "",
        selectedAttribute: "",
      });
    }
    // You may need to adjust the dependencies if you're performing actions that depend on them
  }, [location.pathname, setSearchParams]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getGroupDiscovery();
        setDiscoveryData(data);
      } catch (error) {}
      setIsLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (
    newSearchTerm: any,
    newSelectedPrivacy: any,
    newSelectedAttribute: any,
  ) => {
    setSearchParams({
      searchTerm: newSearchTerm,
      selectedPrivacy: newSelectedPrivacy,
      selectedAttribute: newSelectedAttribute,
    });
    if (location.pathname !== "/discover/search") {
      navigate("/discover/search");
    }
  };

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.DISCOVER : MARGIN.LARGE.DISCOVER;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <div
        className={`${
          !isSmallerThanLargeScreen
            ? "flex justify-between mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh]"
        } `}
      >
        <ILabel text="Discover" />

        <div
          className={`${
            isSmallerThanLargeScreen ? "mb-[1.48vh] mt-[2.96vh]" : "w-[320px]"
          }`}
        >
          <ISearch
            handleSearch={handleSearch}
            searchTerm={searchParams.searchTerm}
            setSearchTerm={(value: any) =>
              setSearchParams((prev: any) => ({ ...prev, searchTerm: value }))
            }
            selectedAttribute={searchParams.selectedAttribute}
            setSelectedAttribute={(value: any) =>
              setSearchParams((prev: any) => ({
                ...prev,
                selectedAttribute: value,
              }))
            }
            selectedPrivacy={searchParams.selectedPrivacy}
            setSelectedPrivacy={(value: any) =>
              setSearchParams((prev: any) => ({
                ...prev,
                selectedPrivacy: value,
              }))
            }
          />
        </div>
      </div>

      {location.pathname === "/discover" && (
        <div>
          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="Trending"
              groups={discoveryData?.trendingGroups || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups trending in your area"
            />
          </div>

          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="Recommended"
              groups={discoveryData?.recommendedGroups || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups curated specifically for you"
            />
          </div>

          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="Connections"
              groups={discoveryData?.groupsByConnections || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups by your connections"
            />
          </div>

          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="New"
              groups={discoveryData?.newGroups || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups recently formed near you"
            />
          </div>

          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="Top Interest"
              groups={discoveryData?.groupsByTopAttribute || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups based on your top interest"
            />
          </div>

          <div className="mb-[1.85vh]">
            <IGroupPanel
              title="Top Organizer"
              groups={discoveryData?.groupsByTopOrganizers || []}
              onGroupClick={(groupName, groupId) => {
                navigate(`/groups/${groupId}`);
              }}
              subHeader="Groups by top organizers in your area"
            />
          </div>
        </div>
      )}

      <Outlet />
    </div>
  );
}

export default Discover;
