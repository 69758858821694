/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { useDash } from "./DashboardContext";
import { useNotificationService } from "../Services/NotificationsService/NotificationsService";
export const NotificationContext = createContext<any | null>(null);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const { user, updateUserFields } = useDash();
  const [messages, setMessages] = useState<any[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const {
    getNotifications,
    readNotification,
    readAllNotifications,
    removeNotification,
  } = useNotificationService();

  function startOfWeek(date: Date, { weekStartsOn = 0 } = {}) {
    const diff = date.getDate() - date.getDay() + weekStartsOn;
    return new Date(date.getFullYear(), date.getMonth(), diff);
  }

  function endOfWeek(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay() + 6,
    );
  }

  function isInThisWeek(date: Date) {
    const now = new Date();
    const firstDayThisWeek = startOfWeek(new Date(now), { weekStartsOn: 0 });
    const lastDayThisWeek = endOfWeek(new Date(firstDayThisWeek));

    firstDayThisWeek.setHours(0, 0, 0, 0);
    lastDayThisWeek.setHours(23, 59, 59, 999);

    return date >= firstDayThisWeek && date <= lastDayThisWeek;
  }

  function isInLastWeek(date: any) {
    const now = new Date();
    // Set the hours to the beginning of the day for an accurate comparison.
    now.setHours(0, 0, 0, 0);

    // First, find the start of this week.
    const firstDayThisWeek = startOfWeek(new Date(now), { weekStartsOn: 0 });

    // Then, find the start of last week by subtracting 7 days from the start of this week.
    const firstDayLastWeek = new Date(
      new Date(firstDayThisWeek).setDate(firstDayThisWeek.getDate() - 7),
    );

    // Find the last day of last week by adding 6 days to the first day of last week.
    const lastDayLastWeek = new Date(
      new Date(firstDayLastWeek).setDate(firstDayLastWeek.getDate() + 6),
    );

    // Set the time to the start of the first day and the end of the last day for comparison
    firstDayLastWeek.setHours(0, 0, 0, 0);
    lastDayLastWeek.setHours(23, 59, 59, 999);

    // Return true if the date is within last week's range
    return date >= firstDayLastWeek && date <= lastDayLastWeek;
  }

  function categorizeMessages(messages: any) {
    const categorized: any = {
      thisWeek: [],
      lastWeek: [],
      older: [],
    };

    messages.forEach((message: any) => {
      const messageDate = new Date(message.timestamp);

      if (isInThisWeek(messageDate)) {
        categorized.thisWeek.unshift(message);
      } else if (isInLastWeek(messageDate)) {
        categorized.lastWeek.unshift(message);
      } else {
        categorized.older.unshift(message);
      }
    });

    return categorized;
  }

  const fetchNotifications = async () => {
    try {
      const notifications = await getNotifications();
      const categorized = categorizeMessages(notifications);
      setMessages(categorized);
      updateUnreadCount(notifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const updateUnreadCount = (msgs: any) => {
    const count = msgs.filter((message: any) => !message.read).length;
    setUnreadCount(count);
  };

  const deleteNotification = useCallback(
    async (notificationId: any, category: any) => {
      try {
        await removeNotification(notificationId);

        setMessages((prevMessages) => ({
          ...prevMessages,
          [category]: prevMessages[category].map((msg: any) =>
            msg.id === notificationId ? { ...msg, opacity: 0 } : msg,
          ),
        }));

        // Delay the actual removal to allow for the animation
        setTimeout(() => {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [category]: prevMessages[category].filter(
              (msg: any) => msg.id !== notificationId,
            ),
          }));
        }, 500); // Delay should match the duration of the fade-out effect
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    },
    [],
  );

  const markNotificationAsRead = useCallback(async (id: any, category: any) => {
    try {
      await readNotification(id);

      setMessages((prevCategorizedMessages) => {
        const updatedCategoryMessages = prevCategorizedMessages[category].map(
          (message: any) =>
            message.id === id ? { ...message, read: true } : message,
        );

        setUnreadCount((currentUnreadCount) => currentUnreadCount - 1);

        return {
          ...prevCategorizedMessages,
          [category]: updatedCategoryMessages,
        };
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  }, []);

  const markAllAsRead = useCallback(async () => {
    try {
      await readAllNotifications();

      setMessages((prevMessages) => {
        const updatedMessages = Object.keys(prevMessages).reduce(
          (acc: any, category: any) => {
            acc[category] = prevMessages[category].map((message: any) => ({
              ...message,
              read: true,
            }));
            return acc;
          },
          {},
        );

        return updatedMessages;
      });

      setUnreadCount(0); // Since all notifications are now read, we set the unread count to 0
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchNotifications();
    };

    fetchData();
    if (user) {
      const socket = new SockJS(
        `${
          process.env.REACT_APP_API_URL
        }/ws/notifications?token=${localStorage.getItem("token")}`,
      );
      const stompClient = Stomp.over(socket);

      stompClient.connect({}, () => {
        stompClient.subscribe(
          `/topic/notifications/${user?.id}`,
          (notification) => {
            const newMessage = JSON.parse(notification.body);
            const messageDate = new Date(newMessage.timestamp);

            if (newMessage.notificationType === "CONNECTION_REQUEST_ACCEPTED") {
              const updatedPendingConnectionRequests =
                user.pendingConnectionRequests.filter(
                  (id: any) => id !== newMessage.senderId,
                );
              const updatedConnections = [
                ...user.connections,
                newMessage.senderId,
              ];

              updateUserFields({
                pendingConnectionRequests: updatedPendingConnectionRequests,
                connections: updatedConnections,
              });
            }

            if (newMessage.notificationType === "CONNECTION_REQUEST") {
              const updatedPendingConnectionRequests = [
                ...user.pendingConnectionRequests,
                newMessage.senderId,
              ];

              updateUserFields({
                pendingConnectionRequests: updatedPendingConnectionRequests,
              });
            }

            if (newMessage.notificationType === "CONNECTION_REQUEST_DECLINED") {
              const updatedPendingConnectionRequests =
                user.pendingConnectionRequests.filter(
                  (id: any) => id !== newMessage.senderId,
                );

              updateUserFields({
                pendingConnectionRequests: updatedPendingConnectionRequests,
              });
            }

            if (newMessage.notificationType === "CONNECTION_REMOVED") {
              const updatedConnections = user.connections.filter(
                (id: any) => id !== newMessage.recipientId,
              );

              updateUserFields({
                connections: updatedConnections,
              });
            }

            if (newMessage.notificationType === "GROUP_REQUEST_ACCEPTED") {
              const updatedPendingGroupRequests =
                user.pendingGroupRequests.filter(
                  (id: any) => id !== newMessage.groupId,
                );
              const updatedGroups = [...user.joinedGroups, newMessage.groupId];

              updateUserFields({
                pendingGroupRequests: updatedPendingGroupRequests,
                joinedGroups: updatedGroups,
              });
            }

            if (newMessage.notificationType === "GROUP_REQUEST_DECLINED") {
              const updatedPendingGroupRequests =
                user.pendingGroupRequests.filter(
                  (id: any) => id !== newMessage.groupId,
                );

              updateUserFields({
                pendingGroupRequests: updatedPendingGroupRequests,
              });
            }

            setMessages((prevCategorizedMessages) => {
              const updatedCategorizedMessages: any = {
                ...prevCategorizedMessages,
              };

              if (isInThisWeek(messageDate)) {
                if (
                  !updatedCategorizedMessages.thisWeek.some(
                    (msg: any) => msg.id === newMessage.id,
                  )
                ) {
                  updatedCategorizedMessages.thisWeek.unshift(newMessage);
                }
              } else if (isInLastWeek(messageDate)) {
                if (
                  !updatedCategorizedMessages.lastWeek.some(
                    (msg: any) => msg.id === newMessage.id,
                  )
                ) {
                  updatedCategorizedMessages.lastWeek.unshift(newMessage);
                }
              } else {
                if (
                  !updatedCategorizedMessages.older.some(
                    (msg: any) => msg.id === newMessage.id,
                  )
                ) {
                  updatedCategorizedMessages.older.unshift(newMessage);
                }
              }

              const allMessages = [
                ...updatedCategorizedMessages.thisWeek,
                ...updatedCategorizedMessages.lastWeek,
                ...updatedCategorizedMessages.older,
              ];
              updateUnreadCount(allMessages);

              return updatedCategorizedMessages;
            });
          },
        );
      });

      return () => {
        if (stompClient.connected) {
          stompClient.disconnect();
        }
      };
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        messages,
        markNotificationAsRead,
        markAllAsRead,
        unreadCount,
        deleteNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default WebSocketProvider;
