import React, { useContext } from "react";
import { CircleIcon } from "../../../Icons/Icons";
import moment from "moment";
import { NotificationContext } from "../../../Context/NotificationContext";

import { useDash } from "../../../Context/DashboardContext";
import { useNavigate } from "react-router-dom";
import { useGroupService } from "../../../Services/GroupService/GroupService";
import { useConnectionService } from "../../../Services/ConnectionsService/ConnectionsService";
import { useEventService } from "../../../Services/EventService/EventService";

function NotificationCard({ messages, category }: any) {
  const webSocketContext = useContext(NotificationContext);
  const { markNotificationAsRead, deleteNotification } =
    webSocketContext as any;
  const { user, updateUserFields } = useDash();
  const navigate = useNavigate();
  const { declineGroupRequest, acceptGroupRequest } = useGroupService();
  const { declineConnectionRequest, acceptConnectionRequest } =
    useConnectionService();
  const { declineEventInvite, acceptEventInvite } = useEventService();

  const handleAccept = async (
    notificationType: any,
    userId: any,
    notificationId: any,
    groupId: any,
    eventId: any,
  ) => {
    switch (notificationType) {
      case "GROUP_REQUEST":
        try {
          await acceptGroupRequest(groupId, userId);

          deleteNotification(notificationId, category);
        } catch (error) {}

        break;
      case "EVENT_INVITE":
        try {
          await acceptEventInvite(eventId);

          deleteNotification(notificationId, category);
        } catch (error) {}
        break;
      case "CONNECTION_REQUEST":
        try {
          await acceptConnectionRequest(userId);

          const updatedPendingConnectionRequests =
            user.pendingConnectionRequests.filter((id: any) => id !== userId);
          const updatedConnections = [...user.connections, userId];

          updateUserFields({
            pendingConnectionRequests: updatedPendingConnectionRequests,
            connections: updatedConnections,
          });
          deleteNotification(notificationId, category);
        } catch (error) {}
        break;
    }
  };

  const handleDecline = async (
    notificationType: any,
    userId: any,
    notificationId: any,
    groupId: any,
    eventId: any,
  ) => {
    switch (notificationType) {
      case "GROUP_REQUEST":
        try {
          await declineGroupRequest(groupId, userId);

          deleteNotification(notificationId, category);
        } catch (error) {}
        break;

      case "EVENT_INVITE":
        try {
          await declineEventInvite(eventId);

          deleteNotification(notificationId, category);
        } catch (error) {}
        break;
      case "CONNECTION_REQUEST":
        try {
          await declineConnectionRequest(userId);

          deleteNotification(notificationId, category);
          const updatedPendingConnections =
            user.pendingConnectionRequests.filter((id: any) => id !== userId);

          updateUserFields({
            pendingConnectionRequests: updatedPendingConnections,
          });
        } catch (error) {}
        break;
    }
  };

  const handlePictureClick = (msg: any) => {
    switch (msg.notificationType) {
      case "GROUP_REQUEST_ACCEPTED":
        navigate(`/groups/${msg.groupId}`);
        break;
      case "GROUP_REQUEST_DECLINED":
        navigate(`/groups/${msg.groupId}`);
        break;
      case "EVENT_INVITE":
        navigate(`/events/${msg.eventId}`);
        break;

      default:
        navigate(`/profile/${msg.senderId}`);
        break;
    }
  };

  return (
    <div className="mt-[24px] ">
      {messages?.map((msg: any, index: any) => (
        <div
          key={msg.id}
          style={{
            transition: "opacity 0.5s ease",
            opacity: msg.opacity !== undefined ? msg.opacity : 1,
          }}
        >
          {!msg.hidden && (
            <div>
              <div>
                {
                  <div
                    className={`pt-[24px] pb-[24px] `}
                    style={{
                      borderBottom:
                        index !== messages.length - 1
                          ? "1px solid #DADEE5"
                          : "",
                    }}
                    onClick={() => markNotificationAsRead(msg.id, category)}
                  >
                    <div className="flex items-center ml-[10px]">
                      <div
                        className="mr-[24px]"
                        style={{
                          visibility: msg.read ? "hidden" : "visible",
                        }}
                      >
                        <CircleIcon />
                      </div>
                      <img
                        src={msg.photo || "default-avatar.png"}
                        alt="User"
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                        className="rounded-full"
                        onClick={() => handlePictureClick(msg)}
                      />

                      <div className="flex justify-between w-full">
                        <div>
                          <div
                            className="font-light ml-[16px]"
                            dangerouslySetInnerHTML={{ __html: msg.message }}
                          ></div>
                          <div className="text-sm text-[#7E858B] font-light ml-[16px]">
                            {moment.utc(msg.timestamp).local().fromNow()}
                          </div>
                        </div>

                        {/* <div>
                    <button
                      onClick={() => {
                        deleteNotification(msg.id, category);
                      }}
                    >
                      <XIcon height="15" width="15" stroke="#7E858B" />
                    </button>
                  </div> */}
                      </div>
                    </div>
                    <div className="ml-[98px] mt-[12px] ">
                      {(msg?.notificationType === "CONNECTION_REQUEST" ||
                        msg?.notificationType === "GROUP_REQUEST" ||
                        msg.notificationType === "EVENT_INVITE") && (
                        <div>
                          <button
                            className="text-xs border rounded px-4 py-1 my-auto mr-2"
                            onClick={() =>
                              handleAccept(
                                msg?.notificationType,
                                msg?.senderId,
                                msg?.id,
                                msg?.groupId,
                                msg?.eventId,
                              )
                            }
                          >
                            Accept
                          </button>
                          <button
                            className="text-xs border rounded px-4 py-1 my-auto"
                            onClick={() => {
                              handleDecline(
                                msg.notificationType,
                                msg.senderId,
                                msg.id,
                                msg.groupId,
                                msg.eventId,
                              );
                            }}
                          >
                            Decline
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default NotificationCard;
