import React, { useState, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import IToolTip from "../ToolTip/IToolTip";
import { ChevronDownIcon, ChevronUpIcon } from "../../Icons/Icons";

interface IMultiSelectProps {
  options: Array<{ value: any; label: any }>;
  placeholder?: string;
  onChange?: (value: any[]) => void;
  labelText: string;
  value: any[];
  errorMessage?: string;
  maxAttendees: any;
}

function IMultiSelect({
  options,
  placeholder,
  onChange,
  labelText,
  value = [],
  errorMessage,
  maxAttendees,
}: IMultiSelectProps) {
  const [selectedValues, setSelectedValues] = useState<any[]>(
    Array.isArray(value)
      ? value?.filter((val) => val !== undefined && val !== null)
      : [],
  );

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedValues(
        value.filter((val) => val !== undefined && val !== null),
      );
    }
  }, [value]);

  const handleValueChange = (selectedValue: any) => {
    if (typeof selectedValue === "string") {
      let newSelectedValues;
      if (selectedValues.includes(selectedValue)) {
        newSelectedValues = selectedValues.filter(
          (val) => val !== selectedValue,
        );
      } else {
        if (
          maxAttendees === null ||
          maxAttendees === 0 ||
          selectedValues.length < maxAttendees - 1
        ) {
          newSelectedValues = [...selectedValues, selectedValue];
        } else {
          // Optionally show a message to the user indicating they cannot select more
          return;
        }
      }
      setSelectedValues(newSelectedValues);
      if (onChange) {
        onChange(newSelectedValues);
      }
    }
  };

  return (
    <div className="relative">
      <div style={{ display: "flex" }}>
        {labelText && (
          <label
            className="mb-1"
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {labelText}
          </label>
        )}
        <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div>
      </div>

      <Listbox value={selectedValues} onChange={handleValueChange} multiple>
        {({ open }) => (
          <>
            <Listbox.Button
              className={`hover:border-blue-500 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                selectedValues.length > 0 ? "text-black" : "text-gray-400"
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
              }}
            >
              {selectedValues.length > 0 ? (
                <span className="text-gray-900">
                  {selectedValues
                    .map(
                      (val) =>
                        options.find((option) => option.value === val)?.label,
                    )
                    .filter(Boolean)
                    .join(", ")}
                </span>
              ) : (
                <span
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "2px",
                    fontStyle: "normal",
                  }}
                  className="text-gray-500"
                >
                  {placeholder}
                </span>
              )}
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Listbox.Button>

            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none text-xs">
              {options?.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className="py-2 pl-3 pr-10 cursor-pointer hover:bg-gray-100 flex items-center"
                  onClick={() => handleValueChange(option.value)}
                >
                  <label className="flex items-center w-full cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedValues.includes(option.value)}
                      //onChange={() => handleValueChange(option.value)}
                      className="mr-2"
                    />
                    <span
                      className={`block truncate ${
                        selectedValues.includes(option.value)
                          ? "font-medium"
                          : "font-normal"
                      }`}
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      {option.label}
                    </span>
                  </label>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default IMultiSelect;
