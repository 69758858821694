import React, { useEffect, useRef } from "react";
import IToolTip from "../ToolTip/IToolTip";

interface ITextAreaProps {
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string;
}

const ITextArea: React.FC<ITextAreaProps> = ({
  name,
  placeholder,
  value,
  onChange,
  errorMessage,
}) => {
  const textAreaRef = useRef<any>(null);

  useEffect(() => {
    const handleBlur = () => {
      // Trigger layout reflow or adjust scroll when text area loses focus
      window.scrollTo(0, 0);
    };

    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.addEventListener("blur", handleBlur);
    }

    return () => {
      if (textArea) {
        textArea.removeEventListener("blur", handleBlur);
      }
    };
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="flex">
        <div
          className="mb-1 "
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "20px",
            fontStyle: "normal",
          }}
        >
          Description
        </div>
        {/* <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div> */}
      </div>
      <div style={{ position: "relative" }}>
        <textarea
          ref={textAreaRef}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "20px",
          }}
          className="hover:border-blue-500 h-[80px] bg-gray-50  border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
      </div>
      <div
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "12px",
          lineHeight: "20px",
          fontStyle: "normal",
          color: "red",
        }}
      >
        {errorMessage && errorMessage}
      </div>
    </div>
  );
};

export default ITextArea;
