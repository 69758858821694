import React, { useEffect, useRef, useState } from "react";
import { toPascalCase } from "../../Constants/Constants";

function ISearch({
  handleSearch,
  searchTerm,
  setSearchTerm,
  selectedAttribute,
  setSelectedAttribute,
  selectedPrivacy,
  setSelectedPrivacy,
}: any) {
  const [showFilters, setShowFilters] = useState(false);
  const containerRef = useRef<any>(null);
  const attributes = [
    "SOCIAL",
    "INTELLIGENCE",
    "FITNESS",
    "NIGHTLIFE",
    "CULTURE",
    "ADVENTURE",
  ];
  const privacies = ["PUBLIC", "PRIVATE", "RESTRICTED"];

  const handleFilterClick = () => {
    // Explicit control based on current state
    if (showFilters) {
      // If already shown, close it
      setShowFilters(false);
    } else {
      // If not shown, show it and setup listener in the next cycle
      setShowFilters(true);
    }
  };

  const handleAttributeClick = (attribute: any) => {
    const newAttribute = attribute.toUpperCase();

    if (selectedAttribute === newAttribute) {
      setSelectedAttribute("");
    } else {
      setSelectedAttribute(newAttribute);
    }
  };

  const handlePrivacyClick = (privacy: any) => {
    setSelectedPrivacy(selectedPrivacy === privacy ? "" : privacy);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleSearch(
      searchTerm,
      selectedPrivacy.toUpperCase(),
      selectedAttribute.toUpperCase(),
    );
    setShowFilters(false); // Ensuring the filter panel closes only when the search is actually performed
  };

  // Add or remove the outside click listener
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowFilters(false); // Only set to false here
      }
    };

    if (showFilters) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilters]);

  return (
    <form ref={containerRef} onSubmit={handleSubmit} className="flex">
      <div className="relative flex-grow ">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[16px]  pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#9299A6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full pl-[40px] pr-10 text-xs text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          autoComplete="off"
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "24px",
            fontStyle: "normal",
          }}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 flex items-center pr-[16px]"
          onClick={handleFilterClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
              stroke="#9299A6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        {showFilters && (
          <div className="absolute right-0 mt-2 px-4 pt-4 pb-2 bg-white border border-gray-300 rounded-lg  z-50 w-[265px]">
            <div className="flex justify-between mb-2">
              <div>
                {" "}
                <div className="text-xs font-medium text-gray-900 mb-3">
                  PRIVACY
                </div>
                <div className="flex gap-3 mb-2">
                  {privacies.map((privacy) => (
                    <button
                      key={privacy}
                      className={`py-1 px-2 rounded-lg text-xs font-medium focus:outline-none transition-colors ${
                        selectedPrivacy === privacy
                          ? "bg-blue-500 text-white"
                          : "border text-gray-700 hover:bg-gray-200"
                      }`}
                      onClick={() => handlePrivacyClick(privacy)}
                    >
                      {toPascalCase(privacy)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-xs font-medium text-gray-900 mb-3">TYPE</div>
            <div className="flex flex-wrap gap-2 mb-3">
              {attributes?.map((attribute) => (
                <button
                  key={attribute}
                  className={`py-1 px-3 min-w-max rounded-lg text-xs font-medium focus:outline-none transition-colors whitespace-nowrap ${
                    selectedAttribute === attribute
                      ? "bg-blue-500 text-white"
                      : "border text-gray-700 hover:bg-gray-200"
                  }`}
                  onClick={() => handleAttributeClick(attribute)}
                >
                  {toPascalCase(attribute)}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default ISearch;
