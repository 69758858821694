export const appHighlights = [
  {
    title: "Local Groups",
    image: "https://res.cloudinary.com/chatfindr/image/upload/v1722910227/system/landing/groups.png",
    detail:
      "Discover and join groups based on age, gender, and personal interests. Make new connections and increase your social circle.",
  },
  {
    title: "Event Discovery",
    image: "https://res.cloudinary.com/chatfindr/image/upload/v1722889399/system/landing/events.png",
    detail:
      "Gain access to group social calendars. Discover new events, get invited by members, or create events of your own.",
  },
  {
    title: "Exclusive Chat",
    image:
      "https://res.cloudinary.com/chatfindr/image/upload/v1722889399/system/landing/chat.png",
    detail:
      "Chat with friends in private groups, friends of friends in restricted groups, or meet new people in public groups. ",
  },
  {
    title: "Personal Interests",
    image: "https://res.cloudinary.com/chatfindr/image/upload/v1722910210/system/landing/attributes.png",
    detail:
      "Search for groups that match your interests and get rewarded for chatting, going to events, and making new connections.",
  },
  {
    title: "Social Rankings",
    image:
      "https://res.cloudinary.com/chatfindr/image/upload/v1722889399/system/landing/leaderboard.png",
    detail:
      "Find the most active people in your city or the groups with the highest reputations. Filter by location and interests.",
  },
];
