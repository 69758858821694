import React, { useEffect, useRef } from "react";
import IToolTip from "../ToolTip/IToolTip";

interface IInputProps {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  value?: string;
  numberOnly?: boolean; // New prop to enable number only input
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  errorMessage?: string;
  disabled?: boolean;
}

function IInput({
  name,
  placeholder,
  label,
  type = "text",
  value,
  numberOnly,
  onChange,
  className,
  errorMessage,
  disabled,
}: IInputProps) {
  // Function to prevent non-numeric input
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (numberOnly) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        // Add any additional keys that should be allowed
      ];
      if (
        !allowedKeys.includes(event.key) &&
        !event.ctrlKey &&
        !event.metaKey
      ) {
        event.preventDefault();
      }
    }
  };
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleFocus = () => {
      // Optional: Add logic here for when input gains focus if needed
    };

    const handleBlur = () => {
      // Logic to adjust the page layout or perform actions when input loses focus
      // For example, scrolling slightly to trigger viewport adjustment
      window.scrollTo({ top: window.scrollY + 1 });
      window.scrollTo({ top: window.scrollY - 1 });
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className="flex">
        {label && (
          <label
            htmlFor={name}
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {label}
          </label>
        )}
        {/* <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div> */}
      </div>

      <div style={{ position: "relative" }}>
        <input
          disabled={disabled}
          ref={inputRef}
          id={name}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "2px",
            fontStyle: "normal",
          }}
          className={`${className} mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:border-blue-500`}
        />
        <span
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            lineHeight: "20px",
            fontStyle: "normal",
            color: "red",
          }}
        >
          {errorMessage}
        </span>
      </div>
    </div>
  );
}

export default IInput;
