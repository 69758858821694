import React, { ChangeEvent } from "react";
import IInput from "../../../../Library/Input/IInput";
import IDateTimePicker from "../../../../Library/DateTimePicker/DateTimePicker";

function EventLocationTimeForm({
  address,
  setAddress,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  addressError,
  startDateError,
  endDateError,
  event,
}: any) {
  return (
    <div>
      <div className="pb-4">
        <IInput
          label="Address"
          name="name"
          value={address}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress(e.target.value)
          }
          errorMessage={addressError}
        ></IInput>
      </div>

      <div className="pb-4">
        {" "}
        <IDateTimePicker
          label="Start Date"
          value={startTime}
          onChange={(newDate: any) => setStartTime(newDate)}
          errorMessage={startDateError}
        />
      </div>

      <div className="pb-4">
        <IDateTimePicker
          label="End Date"
          value={endTime}
          onChange={(newDate: any) => setEndTime(newDate)}
          errorMessage={endDateError}
        />
      </div>
    </div>
  );
}

export default EventLocationTimeForm;
