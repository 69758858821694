import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

// Create the context
const GeolocationContext = createContext<any>(null);

// Create a provider component
export const GeolocationProvider = ({ children }: any) => {
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    city: null,
    state: null,
  });
  const [error, setError] = useState<any>(null);

  const fetchLocationDetails = async (latitude: number, longitude: number) => {
    try {
      const response = await axios.get(
        `https://us1.locationiq.com/v1/reverse.php?key=${process.env.REACT_APP_GEO_LOCATION_KEY}&lat=${latitude}&lon=${longitude}&format=json`,
      );
      const address = response.data.address;
      setLocation({
        latitude,
        longitude,
        city: address.city || address.town || address.village,
        state: address.state,
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude, city: null, state: null });
          fetchLocationDetails(latitude, longitude);
        },
        (error) => {
          setError(error.message);
        },
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    requestLocation();
  }, []);

  return (
    <GeolocationContext.Provider value={{ location, error, requestLocation }}>
      {children}
    </GeolocationContext.Provider>
  );
};

// Create a custom hook to use the GeolocationContext
export const useGeolocation = () => {
  return useContext(GeolocationContext);
};
