/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SearchContext = createContext<any>(null);

export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState({
    searchTerm: "",
    selectedPrivacy: "",
    selectedAttribute: "",
  });

  useEffect(() => {
    if (
      searchParams.searchTerm === "" &&
      searchParams.selectedPrivacy === "" &&
      searchParams.selectedAttribute === "" &&
      location.pathname === "/discover/search"
    ) {
      navigate("/discover");
    }
  }, [searchParams]);

  return (
    <SearchContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchContext.Provider>
  );
};
