import React, { ChangeEvent, FormEvent, useState } from "react";
import IInput from "../../../Library/Input/IInput";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../../Services/AuthService/AuthService";

interface ResetPasswordFormProps {
  handleForgotPasswordClick: () => void;
}

function ResetPasswordForm({
  handleForgotPasswordClick,
}: ResetPasswordFormProps) {
  const handleBack = (): void => {
    handleForgotPasswordClick();
  };

  const navigate = useNavigate();

  const [email, setEmail] = useState<any>("");
  const { resetPassword } = useAuthService();

  const handleSubmitForm = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    try {
      await resetPassword(email);

      navigate("/reset-password-request");
    } catch (error) {}
  };

  return (
    <div className="grid-rows-2 ">
      <div className="grid grid-rows" style={{ width: "350px" }}>
        <form onSubmit={handleSubmitForm}>
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "24px",
              lineHeight: "32px",
              letterSpacing: "-0.5px",
            }}
          >
            Reset Password
          </div>
          <div
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#7E858B",
            }}
            className="mt-[8px]"
          >
            Enter your email address below. We'll send you instructions on
            how to reset your password.
          </div>
          <div className="mt-[32px]">
            <IInput
              name="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </div>

          <button
            type="submit"
            className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
          >
            Continue
          </button>
          <div className="flex justify-center mt-4">
            <button
              type="button"
              onClick={handleBack}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                fontStyle: "normal",
                lineHeight: "16px",
              }}
            >
              Go back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
