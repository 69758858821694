/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  useState,
  useRef,
  FormEvent,
  useEffect,
} from "react";
import IInput from "../../../../Library/Input/IInput";
import IButton from "../../../../Library/Button/IButton";
import { useDash } from "../../../../Context/DashboardContext";
import Toast from "../../../../Library/Toast/Toast";
import {
  statesAndCities,
  uniqueStatesWithLabelValue,
} from "../../../../States/StatesAndCities";
import IComboBox from "../../../../Library/ComboBox/ComboBox";
import {
  handleValidateEmail,
  validateFirstName,
  validateLastName,
} from "./EditProfileValidators";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { TOAST_MESSAGES } from "../../../../Constants/Constants";
import { Slider } from "@mui/material";
import { useUserService } from "../../../../Services/UserService/UserService";
import { useAuthService } from "../../../../Services/AuthService/AuthService";

function EditProfile() {
  const { user, updateUserFields } = useDash();
  const { validateEmail } = useAuthService();
  const { updateProfileInfo, updateProfilePicture } = useUserService();
  const [image, setImage] = useState<string>(user?.photo);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<boolean>();
  const fileInputRef: any = useRef<HTMLInputElement>(null);
  const [, setIsFileValid] = useState<boolean>(false);
  const [discoveryDistance, setDiscoveryDistance] = useState(
    user?.discoveryDistance || 0,
  );
  const [cities, setCities] = useState<Array<{ label: string; value: string }>>(
    [],
  );
  const { isSmallerThanLargeScreen } = useScreenSize();

  const [editProfileErrors, setEditProfileErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
  });

  const validateEditProfile = () => {
    const firstNameError = validateFirstName(firstName);
    const lastNameError = validateLastName(lastName);
    const emailError = handleValidateEmail(email);

    setEditProfileErrors({ firstNameError, lastNameError, emailError });

    return !firstNameError && !lastNameError && !emailError;
  };

  useEffect(() => {
    const citiesInState = statesAndCities
      .filter((item) => item.state === state)
      .map((item) => item.city);

    const uniqueCities = Array.from(new Set(citiesInState)).map((city) => ({
      label: city,
      value: city,
    }));

    setCities(uniqueCities);

    // Set city on state change, prioritizing user?.city if it exists in the new state's cities
    if (uniqueCities.some((city) => city.value === user?.city)) {
      setCity(user?.city);
    } else if (uniqueCities.length > 0) {
      setCity(uniqueCities[0].value);
    }
  }, [state, statesAndCities, user?.city]); // Reacting to state and user?.city changes

  useEffect(() => {
    console.log(user);
    if (user) {
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setEmail(user?.email);
      setState(user?.state);
      setCity(user?.city);
    }
  }, []);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 1048576; // 10MB in bytes
    const allowedFormats = ["jpg", "jpeg", "png", "webp"];
    setMessage(""); // Clear previous messages
    setError(false); // Reset error state

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      // Check if the file size exceeds the maximum limit or if the format is not allowed
      if (
        fileSize > maxFileSize ||
        (fileExtension && !allowedFormats.includes(fileExtension))
      ) {
        setMessage(
          `File size should not exceed 10MB and must be in ${allowedFormats.join(
            ", ",
          )} format.`,
        );
        setError(true);
        setIsFileValid(false); // Set file as invalid
        // Reset the file input so the user can try uploading the same file again
        event.target.value = ""; // Reset the input value
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target?.result as string); // Set image for preview
          setMessage(""); // Clear message
          setError(false); // Clear error state
          setIsFileValid(true); // Mark file as valid
        };
        handleSavePhoto();
        reader.readAsDataURL(file);
      }
    } else {
      // Handle case where file selection is cancelled
      setIsFileValid(false); // Remain or set to invalid
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleSavePhoto = async () => {
    if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
      const formData = new FormData();
      formData.append("file", fileInputRef.current.files[0]); // Assuming 'photo' is the field expected by the backend

      try {
        const response = await updateProfilePicture(formData);

        if (response) {
          updateUserFields({ photo: response });
          setImage(response);
          setMessage(TOAST_MESSAGES.ACCOUNT_PHOTO.SUCCESS);
          setError(false);
        } else {
          console.error("Error updating profile picture:", error);
          setMessage(TOAST_MESSAGES.ACCOUNT_PHOTO.FAILURE);
          setError(true);
        }
      } catch (error) {}
    }
  };

  const handleEditProfile = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (validateEditProfile()) {
      const fieldsToUpdate = {
        firstName,
        lastName,
        city,
        state,
        email,
        discoveryDistance,
      };

      const updatedFields: { [key: string]: string | number } = {};

      // Compare each field value to the initial value
      for (const [key, value] of Object.entries(fieldsToUpdate)) {
        if (typeof value === "number") {
          if (value !== user[key]) {
            updatedFields[key] = value;
          }
        } else if (value?.trim() !== "" && user[key] !== value) {
          updatedFields[key] = value;
        }
      }

      // Email validation if it has been changed
      let emailValidationError = false;
      if (updatedFields.email) {
        try {
          const bodyData = {
            authType: "EMAIL",
            authIdentifier: updatedFields.email,
          };
          const response = await validateEmail(bodyData);
          if (!response) {
            setEditProfileErrors((prevErrors) => ({
              ...prevErrors,
              emailError: "Email is already taken",
            }));
            emailValidationError = true;
          }
        } catch (error) {
          console.error("Error during email validation:", error);
          emailValidationError = true; // Assume validation error in case of exception
        }
      }

      // Only proceed if there are changes and no email validation error
      if (Object.keys(updatedFields).length > 0 && !emailValidationError) {
        try {
          await updateProfileInfo(updatedFields);
          updateUserFields(updatedFields);
          setMessage(TOAST_MESSAGES.ACCOUNT_UPDATE.SUCCESS);
          setError(false);
        } catch (updateError) {
          setMessage(TOAST_MESSAGES.ACCOUNT_UPDATE.FAILURE);
          setError(true);
        }
      } else if (emailValidationError) {
        // If there's an email error, we've already set the message, just ensure we don't proceed
        setError(true);
      } else {
        setMessage("No changes detected.");
        setError(true);
      }
    }
  };

  return (
    <div>
      <Toast isError={error} message={message} setMessage={setMessage} />
      <div className={`flex items-center mb-8 mt-6`}>
        <div className="relative w-20 h-20">
          {image ? (
            <img
              src={image}
              alt="Profile"
              className="rounded-full w-full h-full object-cover"
            />
          ) : (
            <div className="rounded-full border text-[#5081FF] w-full h-full flex items-center justify-center font-thin text-3xl bg-[#C7D5FB]">
              +
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleImageChange}
            accept="image/*"
          />
        </div>
        <div
          className="ml-4 mt-2"
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "14px",
            lineHeight: "20px",
            fontStyle: "normal",
            color: "#7E858B",
          }}
        >
          Picture should be in JPEG or PNG format <br /> Minimum resolution is
          200x200 pixels
          <div className="mt-2">
            <button
              onClick={handleUploadClick}
              className="ml-auto text-black text-sm border rounded py-1 px-4 mr-3"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleEditProfile}>
        <div className={`${isSmallerThanLargeScreen ? "w-full" : "w-[460px]"}`}>
          <div className="w-full pb-6">
            <IInput
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              errorMessage={editProfileErrors.firstNameError}
            />
          </div>
          <div className="w-full pb-6">
            <IInput
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
              errorMessage={editProfileErrors.lastNameError}
            />
          </div>
          <div className="w-full  pb-6">
            <IInput
              name="email"
              label="Email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              errorMessage={editProfileErrors.emailError}
            />
          </div>
          <div className="w-full pb-6">
            <div className="flex gap-2">
              <div className="w-full">
                <IComboBox
                  labelText="Location"
                  placeholder={user?.state}
                  value={state}
                  onChange={(newValue) => setState(newValue)}
                  options={uniqueStatesWithLabelValue}
                />
              </div>
              <div className="w-full pt-6">
                <IComboBox
                  labelText=""
                  placeholder={user?.city}
                  value={city}
                  onChange={(newValue) => setCity(newValue)}
                  options={cities}
                />
              </div>
            </div>
            <div className="w-full pt-6">
              <div className="flex justify-between">
                <div
                  className="mb-1"
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontStyle: "normal",
                  }}
                >
                  Discovery Distance
                </div>
                <div
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontStyle: "normal",
                  }}
                >
                  {discoveryDistance} mi
                </div>
              </div>

              <Slider
                value={discoveryDistance}
                onChange={(event, newValue) => setDiscoveryDistance(newValue)}
                aria-label="Default"
                valueLabelDisplay="auto"
                max={100}
                sx={{
                  marginLeft: "8px",
                  width: !isSmallerThanLargeScreen ? "452px" : "345px", // Adjusts both left and right margins
                  color: "#5081FF", // Primary color
                  "& .MuiSlider-thumb": {
                    color: "#5081FF", // Thumb color
                  },
                  "& .MuiSlider-track": {
                    color: "#5081FF", // Track color
                  },
                  "& .MuiSlider-rail": {
                    color: "#CCCCCC", // Rail color (optional)
                  },
                }}
              />
            </div>
          </div>
          <div className="pb-4">
            <IButton
              text="Update Account"
              type="submit"
              className="px-6"
              bgColor="bg-regal-blue"
              textColor="text-white"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
