import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import IUserTable from "../../../../Library/IUserTable/IUserTable";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN } from "../../../../Constants/Constants";
import { useGroupService } from "../../../../Services/GroupService/GroupService";

function GroupMembersList() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState<any>();

  const { getGroupMembers } = useGroupService();
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await getGroupMembers(groupId);
        setMembers(data);
      } catch (error) {}
      setIsLoading(false);
    };

    fetchMembers();
  }, []);

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.MEMBERS : MARGIN.LARGE.MEMBERS;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Members" />
      </div>

      <IUserTable
        users={members}
        onRowClick={(userId) => {
          navigate(`/profile/${userId}`);
        }}
      />
    </div>
  );
}

export default GroupMembersList;
