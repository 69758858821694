import React from "react";

interface IButtonProps {
  text: string;
  onClick?: () => void;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  className?: string;
  icon?: React.ReactNode; // For adding icons or additional elements
  disabled?: boolean;
  type?: any;
}

function IButton({
  text,
  onClick,
  bgColor = "bg-white", // Default background color
  textColor = "text-black", // Default text color
  borderColor = "border", // Default border color
  className = "",
  icon = null, // Default to no icon
  disabled,
  type,
}: IButtonProps) {
  return (
    <button
      className={`rounded-lg flex  ${textColor} ${bgColor} border ${borderColor} px-4 py-2 font-light ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={{
        fontFamily: "Satoshi-Regular",
        fontSize: "16px",
        lineHeight: "20px",
        fontStyle: "normal",
      }}
    >
      <span className="">{text}</span>
      {icon && <span className="ml-1">{icon}</span>}
    </button>
  );
}

export default IButton;
