import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import SignUp from "./Components/SignUp/SignUp";
import Login from "./Components/Login/Login";
import Success from "./Components/SignUp/Step5/Success";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ScreenSizeProvider } from "./Context/ScreenContext";
import ProtectedRoutes from "./ProtectedRoutes"; // Make sure this path is correct
import { AuthProvider } from "./Context/AuthContext";
import PublicRoute from "./PublicRoute";
import FontFaces from "./Fonts/Fonts";
import PasswordNotification from "./Components/Login/Notification/PasswordNotification";
import ResetPasswordConfirmation from "./Components/Login/ResetPasswordConfirmation/ResetPasswordConfirmation";
import ValidateEmail from "./Components/Login/ValidateEmail/ValidateEmail";
import { GeolocationProvider } from "./Context/GeoLocationContext";
import { AxiosProvider } from "./Context/AxiosContext";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div>
      <HelmetProvider>
        <Router>
          <FontFaces />

          <AxiosProvider>
            <GeolocationProvider>
              <AuthProvider>
                <ScreenSizeProvider>
                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route
                      path="/signup"
                      element={
                        <PublicRoute>
                          <SignUp />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <PublicRoute>
                          <Login />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path="/validate-email"
                      element={
                        <PublicRoute>
                          <ValidateEmail />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path="/reset-password-request"
                      element={
                        <PublicRoute>
                          <PasswordNotification />
                        </PublicRoute>
                      }
                    />

                    <Route
                      path="/reset-password/:token"
                      element={
                        <PublicRoute>
                          <ResetPasswordConfirmation />
                        </PublicRoute>
                      }
                    />

                    <Route path="/*" element={<ProtectedRoutes />} />
                    <Route path="waitlist" element={<Success />} />
                  </Routes>
                </ScreenSizeProvider>
              </AuthProvider>
            </GeolocationProvider>
          </AxiosProvider>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
