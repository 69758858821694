// eventService.ts
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useEventService = () => {
  const axiosInstance = useAxios();

  const getEventDiscovery = async (city: any, attribute: any) => {
    const response = await axiosInstance.get(`/discovery/event`, {
      params: { city, attribute },
    });
    return response.data;
  };

  const getEventHome = async () => {
    const response = await axiosInstance.get("/event/home");
    return response.data;
  };

  const getEvent = async (id: any) => {
    const response = await axiosInstance.get(`/event/${id}/page`);
    return response.data;
  };

  const getEventDashboard = async (id: any) => {
    const response = await axiosInstance.get(`/event/${id}/dashboard`);
    return response.data;
  };

  const getEventAttendees = async (id: any) => {
    const response = await axiosInstance.get(`/event/${id}/attendees`);
    return response.data;
  };

  const updateEventPicture = async (id: any, formData: FormData) => {
    const response = await axiosInstance.put(`/event/${id}/photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  const createEvent = async (event: any) => {
    const response = await axiosInstance.post("/event", event, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const editEvent = async (id: any, event: any) => {
    const response = await axiosInstance.put(`/event/${id}`, event, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const getRelatedEvents = async (id: any) => {
    const response = await axiosInstance.get(`/event/${id}/related-events`);
    return response.data;
  };

  const leaveCurrentEvent = async (id: any) => {
    const response = await axiosInstance.delete(`/event/${id}/attendees`);
    return response.data;
  };

  const joinCurrentEvent = async (id: any) => {
    const response = await axiosInstance.put(`/event/${id}/attendees`);
    return response.data;
  };

  const getEventPicture = async (id: any) => {
    const response = await axiosInstance.get(`/event/${id}/photo`, {
      responseType: "text",
    });
    return response.data;
  };

  const getEventDiscoveryShowAll = async (url: any) => {
    const response = await axiosInstance.get(`/discovery/event/${url}`);
    return response.data;
  };

  const getEventDiscoverySearch = async (url: any) => {
    const response = await axiosInstance.get(`/discovery/event/${url}`);
    return response.data;
  };

  const deleteEvent = async (eventId: any) => {
    const response = await axiosInstance.delete(`/event/${eventId}`);
    return response.data;
  };

  const acceptEventInvite = async (eventId: any) => {
    const response = await axiosInstance.put(`/event/${eventId}/invites`);
    return response.data;
  };

  const declineEventInvite = async (eventId: any) => {
    const response = await axiosInstance.delete(`/event/${eventId}/invites`);
    return response.data;
  };

  return {
    acceptEventInvite,
    declineEventInvite,
    getEventDiscovery,
    getEventHome,
    getEvent,
    getEventDashboard,
    getEventAttendees,
    updateEventPicture,
    createEvent,
    editEvent,
    getRelatedEvents,
    leaveCurrentEvent,
    joinCurrentEvent,
    getEventPicture,
    getEventDiscoveryShowAll,
    getEventDiscoverySearch,
    deleteEvent,
  };
};
