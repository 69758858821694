import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import EventDetails from "../EventDetails/EventDetails";
import IButton from "../../../../Library/Button/IButton";
import IMenuButton from "../../../../Library/MenuButton/IMenuButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { MARGIN, TOAST_MESSAGES } from "../../../../Constants/Constants";
import { useScreenSize } from "../../../../Context/ScreenContext";
import ConfirmationDialog from "../../../../Library/ConfirmationDialog/ConfirmationDialog";
import { VerticalDots } from "../../../../Icons/Icons";
import IEventPanel from "../../../../Library/EventPanel/IEventPanel";
import Toast from "../../../../Library/Toast/Toast";
import { useDash } from "../../../../Context/DashboardContext";
import {
  AgePieChart,
  GenderPieChart,
  getAgeAnalytics,
  getGenderAnalytics,
} from "./EventDashboardGraphAnalytics";
import { useEventService } from "../../../../Services/EventService/EventService";

function EventDashboard() {
  const { isMobile, isSmallerThanLargeScreen, isExtraLargeScreen } =
    useScreenSize();
  const { getEventDashboard, deleteEvent } = useEventService();
  const { user } = useDash();
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [eventDashboard, setEventDashboard] = useState<any>();
  const [organizer, setOrganizer] = useState<any>();
  const [group, setGroup] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [attendeesList, setAttendeesList] = useState<any>();
  const [confirm, setConfirm] = useState<any>();
  const [cancel] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<any>();
  const [message, setMessage] = useState();
  const [isError, setIsError] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [firstSectionCount, setFirstSectionCount] = useState<any>(0);
  const [secondSectionCount, setSecondSectionCount] = useState<any>(0);
  const [thirdSectionCount, setThirdSectionCount] = useState<any>(0);

  const [maleAnalytics, setMaleAnalytics] = useState<any>(0);
  const [femaleAnalytics, setFemaleAnalytics] = useState<any>(0);
  const [otherAnalytics, setOtherAnalytics] = useState<any>(0);

  useEffect(() => {
    if (location.state && location.state.toast) {
      setMessage(location.state.toast);
      setIsError(false);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (eventDashboard) {
      getAgeAnalytics({
        analytics: eventDashboard?.event?.analytics?.attendeeAges,
        setFirstSectionCount,
        setSecondSectionCount,
        setThirdSectionCount,
        eventDashboard,
      });
      getGenderAnalytics({
        analytics: eventDashboard?.event?.analytics?.attendeeGenders,
        setMaleAnalytics,
        setFemaleAnalytics,
        setOtherAnalytics,
      });
    }
  }, [eventDashboard]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventDashboard = await getEventDashboard(eventId);
        if (
          eventDashboard &&
          eventDashboard?.event?.organizer?.id === user.id
        ) {
          setEventDashboard(eventDashboard);
          setOrganizer(eventDashboard?.event.organizer);
          setGroup(eventDashboard?.event.host);
          setAttendeesList(eventDashboard?.event?.attendees);
        } else {
          navigate(`/events/${eventId}`);
        }
      } catch (error) {}
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmationDialog = async (option: string) => {
    switch (option) {
      case "Delete":
        setConfirm("Delete");
        setDialogMessage("Are you sure? This will delete all event related data.");
        setShowConfirmationDialog(true);
        break;
    }
  };

  const handleConfirm = async () => {
    switch (confirm) {
      case "Delete":
        try {
          await deleteEvent(eventId);

          setShowConfirmationDialog(false);
          navigate(`/events`, {
            state: { toast: TOAST_MESSAGES.DELETE_EVENT.SUCCESS },
          });
        } catch (error) {}
        break;
    }
  };

  const getMargin = () => {
    return isMobile
      ? MARGIN.MOBILE.EVENTS.EVENT_DASH
      : MARGIN.LARGE.EVENTS.EVENT_DASH;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      {message && (
        <Toast message={message} setMessage={setMessage} isError={isError} />
      )}
      <ConfirmationDialog
        show={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={handleConfirm}
        cancel={cancel}
        confirm={confirm}
        message={"Delete"}
        subMessage={dialogMessage}
      />
      <div
        className={`flex justify-between ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <div className="truncate mr-[10px]">
          <ILabel text={eventDashboard?.event?.name} />
        </div>

        <div className="flex">
          <IButton
            text={"Edit"}
            onClick={() =>
              navigate(`/events/${eventId}/dashboard/edit`, {
                state: { event: eventDashboard?.event },
              })
            }
            bgColor="bg-regal-blue"
            textColor="text-white"
            className="px-6 mr-2"
          />
          <IMenuButton
            options={[
              {
                label: "Delete",
                action: () => handleConfirmationDialog("Delete"),
              },
            ]}
            icon={<VerticalDots />}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 w-full pb-4 ">
        <div
          className="w-full bg-white rounded p-4 md:p-6 "
          style={{ height: isExtraLargeScreen ? "250px" : "200px" }}
        >
          <AgePieChart
            firstSectionCount={firstSectionCount}
            secondSectionCount={secondSectionCount}
            thirdSectionCount={thirdSectionCount}
          />
        </div>

        <div
          className="w-full bg-white rounded p-4 md:p-6 "
          style={{ height: isExtraLargeScreen ? "250px" : "200px" }}
        >
          <GenderPieChart
            otherAnalytics={otherAnalytics}
            maleAnalytics={maleAnalytics}
            femaleAnalytics={femaleAnalytics}
          />
        </div>
      </div>

      <div className="pb-4">
        <EventDetails
          event={eventDashboard?.event}
          organizer={organizer}
          group={group}
          attendeesList={attendeesList}
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Related Events"
          events={eventDashboard.relatedEvents}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader={`View related events for ${eventDashboard?.event?.name}`}
        />
      </div>
    </div>
  );
}

export default EventDashboard;
