/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDash } from "../../../../Context/DashboardContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ArrowRightSubmitIcon,
  InformationIcon,
  UploadPictureIcon,
  XIcon,
} from "../../../../Icons/Icons";

import { useChat } from "../../../../Context/ChatContext";
import { useScreenSize } from "../../../../Context/ScreenContext";
import "./ChatMessages.css";
import { Box, List, ListItem } from "@mui/material";

import Messages from "./Messages";
import ChatMembers from "../ChatMembers";
import { useChatService } from "../../../../Services/ChatService/ChatService";
function ChatMessages() {
  const navigate = useNavigate();
  const { postMessage, postPhoto } = useChatService();
  const { user } = useDash();
  const {
    chatHistories,
    lastUpdateReason,
    setChatHistories,
    setLastUpdateReason,
  } = useChat();
  const { groupId } = useParams();
  const location = useLocation();
  const { readMessages, getChatHistory } = useChatService();
  const group = location.state;
  //const [, setShowMessageDetails] = useState<any>(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [hasMore, setHasMore] = useState(true);

  const [currentChatHistory, setCurrentChatHistory] = useState<any>();
  const boxRef = useRef<any>(null);
  const [, setPhotoMessage] = useState("");
  const [, setIsError] = useState(false);
  const [, setIsFileValid] = useState(true);
  const [, setImage] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const prevScrollHeightRef = useRef(0);
  const prevScrollTopRef = useRef(0);
  //const isLoading = useRef(false);

  const [newMessage, setNewMessage] = useState("");
  const [chatMembersVisible, setChatMembersVisible] = useState(
    location.pathname.includes("members"),
  );
  const messagesEndRef = useRef<any>(null);

  useEffect(() => {
    const currentChatHistory = groupId ? chatHistories[groupId] : [];

    setCurrentChatHistory(currentChatHistory);
  }, [groupId, chatHistories]);

  useEffect(() => {
    if (!isTyping && lastUpdateReason === "message") {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [groupId, currentChatHistory, isTyping, lastUpdateReason]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        await readMessages(groupId);
      } catch (error) {}
    };

    fetchMessages();
  }, [group?.id]);

  const handleScroll = async () => {
    if (boxRef.current.scrollTop === 0 && hasMoreMessages) {
      const joinedDate = new Date(group.members[user.id].joinedDate)
        .toISOString()
        .split(".")[0]; // Format to 'YYYY-MM-DDTHH:mm:ss'

      try {
        const response = await getChatHistory(group.id, page, joinedDate);
        if (response.content.length < 15) {
          setHasMoreMessages(false); // No more messages to load
        }

        // Record the current scroll height before adding new messages
        const prevScrollHeight = boxRef.current.scrollHeight;

        setChatHistories((prevHistories: any) => {
          const updatedHistory = [
            ...response.content.reverse(),
            ...(prevHistories[group.id] || []),
          ];
          return { ...prevHistories, [group.id]: updatedHistory };
        });

        // Adjust the scroll position after the chat history is updated
        setTimeout(() => {
          const newScrollHeight = boxRef.current.scrollHeight;
          const scrollDifference = newScrollHeight - prevScrollHeight;
          boxRef.current.scrollTop = scrollDifference;
        }, 1); // Delay slightly longer to ensure the DOM is updated
        setLastUpdateReason("new messages");
        setPage((prevPage: any) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    }
  };

  const handleSendMessage = async (event: React.FormEvent) => {
    setNewMessage("");
    event.preventDefault();
    if (newMessage.trim() === "") return;
    try {
      await postMessage(groupId, newMessage);

      const lastMessage = currentChatHistory[currentChatHistory.length - 1];

      if (lastMessage.senderId === user.id) {
        handleInputFocus();
      }
      setIsTyping(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 10485760;
    const allowedFormats = ["jpg", "jpeg", "png", "gif", "webp"];
    setPhotoMessage("");
    setIsError(false);

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (
        fileSize > maxFileSize ||
        (fileExtension && !allowedFormats.includes(fileExtension))
      ) {
        setPhotoMessage(
          `File size should not exceed 10MB and must be in ${allowedFormats.join(
            ", ",
          )} format.`,
        );
        setIsError(true);
        setIsFileValid(false);
        event.target.value = ""; // Reset the input value if invalid
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target?.result as string);
          setPhotoMessage("");
          setIsError(false);
          setIsFileValid(true);
        };
        handleSavePhoto();
        reader.readAsDataURL(file);
      }
    } else {
      setIsFileValid(false);
    }
  };

  const handleSavePhoto = async () => {
    if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
      const formData = new FormData();
      formData.append("file", fileInputRef.current.files[0]);

      try {
        const response = await postPhoto(groupId, formData);

        const imageUrl = await response.text();
        try {
          await postMessage(groupId, imageUrl);
        } catch (error) {
          console.error("Error sending message:", error);
        }
      } catch (error) {
        console.error("Failed to upload photo:", error);
      }
    }
  };

  const handleInputFocus = () => {
    window.scrollTo(0, 0);
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const getScreenHeight = () => {
    if (isMobile && !chatMembersVisible) {
      return `calc(100vh - 125px)`;
    }

    if (!isMobile && !chatMembersVisible) {
      return `calc(100vh - 171px)`;
    }

    if (isMobile && chatMembersVisible) {
      return `calc(100vh - 125px + 75px)`;
    }

    if (!isMobile && chatMembersVisible) {
      return `calc(100vh - 171px + 75px)`;
    }
  };

  const handleChatMembers = () => {
    setChatMembersVisible(!chatMembersVisible);
    !chatMembersVisible
      ? navigate(`/chat/${group?.id}/members`, { state: group })
      : navigate(`/chat/${group?.id}`, { state: group });
  };

  const { isMobile } = useScreenSize();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {groupId && (
        <div>
          {" "}
          <Box sx={{ height: isMobile ? "50px" : "96px" }}>
            <header
              className={`chat-header ${
                isMobile
                  ? "fixed h-[50px] px-[16px]"
                  : "h-[96px] relative px-[32px]"
              }`}
            >
              <div className="flex justify-center">
                {isMobile && (
                  <button type="button" onClick={() => navigate("/chat")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15 5L5 15M5 5L15 15"
                        stroke="#323439"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
                <button
                  className="group-name-button ml-[16px]"
                  onClick={() => navigate(`/groups/${group?.id}`)}
                  style={{
                    fontFamily: isMobile ? "Satoshi-Regular" : "Satoshi-Bold",
                    fontSize: isMobile ? "16px" : "20px",
                    lineHeight: isMobile ? "20px" : "24px",
                    letterSpacing: isMobile ? "-0.5px" : "-1px",
                  }}
                >
                  {group?.name}
                </button>
              </div>

              <button onClick={() => handleChatMembers()}>
                {chatMembersVisible ? (
                  <XIcon stroke="#323439" height="20" width="20" />
                ) : (
                  <InformationIcon />
                )}
              </button>
            </header>
          </Box>
          <Box
            ref={boxRef}
            onScroll={handleScroll}
            sx={{
              height: getScreenHeight(),
              overflowY: "auto",
              backgroundColor: "white",
              paddingTop: isMobile
                ? `calc(env(safe-area-inset-top) + 175px)`
                : "",
              "::-webkit-scrollbar": {
                display: "none", // Hides scrollbar for WebKit browsers
              },
              scrollbarWidth: "none", // Hides scrollbar for Firefox
              msOverflowStyle: "none", // Hides scrollbar for IE and Edge
            }}
          >
            {!chatMembersVisible && (
              <List>
                {currentChatHistory?.map((msg: any, index: any) => {
                  const isUserMessage = msg.senderId === user.id;

                  return (
                    <div key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          paddingLeft: isMobile ? "16px" : "32px",
                          paddingRight: isMobile ? "16px" : "32px",
                          flexDirection: "column", // Place elements vertically
                          alignItems: isUserMessage ? "flex-end" : "flex-start", // Aligns content to the correct side
                        }}
                      >
                        <Messages
                          msg={msg}
                          setSelectedMessageIndex={setSelectedMessageIndex}
                          selectedMessageIndex={selectedMessageIndex}
                          index={index}
                          currentChatHistory={currentChatHistory}
                        />
                      </ListItem>
                    </div>
                  );
                })}

                <div ref={messagesEndRef} />
              </List>
            )}

            {chatMembersVisible && <ChatMembers />}
          </Box>
          <Box
            sx={{
              position: isMobile ? "fixed" : "relative",
              left: 0,
              right: 0,
              bottom: 0,
              height: "75px",
              backgroundColor: "white",
              borderTop: "1px solid #DADEE5;",
              paddingBottom: isMobile ? `calc(env(safe-area-inset-bottom))` : 0, // Increase padding bottom to ensure visibility
              zIndex: 0, // Make sure it is on top
              display: chatMembersVisible ? "none" : "flex", // Ensures the footer is a flex container
              alignItems: "center", // Vertically center all children
              justifyContent: "center", // Horizontally center all children
            }}
          >
            <div className="flex items-center justify-center rounded-full px-4 w-full">
              <form
                className="relative flex items-center justify-center w-full"
                onSubmit={handleSendMessage}
                style={{
                  width: "100%", // Ensure form takes full width of the container
                  position: "relative", // Allows absolute positioning inside
                }}
              >
                <input
                  id="input"
                  type="text"
                  className="flex-grow outline-none px-12 rounded-full border border-gray-300 text-gray-900 text-xs focus:ring-blue-500 focus:border-blue-500 w-full"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "2px",
                    fontStyle: "normal",
                  }}
                  onBlur={() => {
                    handleInputFocus();
                    setIsTyping(false); // Reset isTyping when input loses focus
                  }}
                  onFocus={() => setIsTyping(true)}
                />
                <input
                  id="photo"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  accept=".jpg, .jpeg, .png, .gif, .webp"
                />
                <div
                  className="absolute left-6"
                  onClick={() => fileInputRef?.current?.click()}
                >
                  <UploadPictureIcon />
                </div>
                <button
                  type="submit"
                  className="absolute right-4 inset-y-0 my-auto"
                >
                  <ArrowRightSubmitIcon />
                </button>
              </form>
            </div>
          </Box>
        </div>
      )}

      {!groupId && (
        <div className="flex bg-white h-full flex-col justify-center items-center">
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "24px",
              lineHeight: "32px",
              letterSpacing: "-0.5px",
            }}
          >
            Pick up where you left off
          </div>
          <div
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#323439",
            }}
          >
            Select a conversation and chat away.
          </div>
        </div>
      )}
    </Box>
  );
}

export default ChatMessages;
