import React from "react";

function ITab({ tabValue, handleTabChange, tabs }: any) {
  return (
    <div
      className="tab-container"
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        scrollbarWidth: "none", // For Firefox
        scrollbarColor: "#7E858B", // For Firefox, scrollbar thumb and track
      }}
    >
      {tabs.map((tab: any, index: any) => (
        <button
          key={index}
          onClick={() => handleTabChange(index)} // Pass the index of the clicked tab
          style={{
            cursor: "pointer",
            paddingBottom: "10px",
            paddingRight: "20px",
            border: "none",
            borderBottom: tabValue === index ? "1px solid #5081FF" : "none",
            color: tabValue === index ? "#5081FF" : "#7E858B",
            outline: "none",
            marginRight: "10px",
            textAlign: "left",
            fontFamily: "Satoshi-Regular",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  );
}

export default ITab;
