import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import { useNavigate } from "react-router-dom";

import GroupPhotoForm from "../GroupForms/GroupPhotoForm";
import { useDash } from "../../../../Context/DashboardContext";
import {
  validateAttribute,
  validateDescription,
  validateGenderRequirements,
  validateLevel,
  validateMaxAge,
  validateMinAge,
  validateName,
  validatePrivacy,
} from "../GroupValidators/GroupValidators";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN, toPascalCase } from "../../../../Constants/Constants";
import { useChat } from "../../../../Context/ChatContext";
import Toast from "../../../../Library/Toast/Toast";
import IButton from "../../../../Library/Button/IButton";
import IInput from "../../../../Library/Input/IInput";
import ITextArea from "../../../../Library/TextArea/ITextArea";
import IDropdown from "../../../../Library/Dropdown/IDropdown";
import { useGroupService } from "../../../../Services/GroupService/GroupService";

function GroupCreate() {
  let navigate = useNavigate();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { createGroup, updateGroupPicture } = useGroupService();
  const { user } = useDash();
  const [imageFiles] = useState<File[]>([]);
  const [name, setName] = useState<string>("");
  const [minAge, setMinAge] = useState<string>("");
  const [maxAge, setMaxAge] = useState<string>("");
  const [genderRequirements, setGenderRequirements] = useState("");
  const [description, setDescription] = useState("");
  const [attribute, setAttribute] = useState<any>("");
  const [privacy, setPrivacy] = useState<any>("");
  const [level, setLevel] = useState<any>("");
  const { addGroupToWebSocket } = useChat();
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>();
  const [, setIsFileValid] = useState<any>();
  const [image, setImage] = useState<string>("");
  const fileInputRef: any = useRef<HTMLInputElement>(null);
  const [ageOptions, setAgeOptions] = useState<any>();

  const [detailErrors, setDetailErrors] = useState({
    nameError: "",
    descriptionError: "",
    attributeError: "",
  });

  const [requirementErrors, setRequirementErrors] = useState({
    minAgeError: "",
    maxAgeError: "",
    genderRequirementsError: "",
    privacyError: "",
    levelError: "",
  });

  const [photoError, setPhotoError] = useState("");

  useEffect(() => {
    const generateAgeData = () => {
      const ageData = [];
      for (let i = 16; i <= 65; i++) {
        ageData.push({
          label: i === 65 ? "65 +" : i,
          value: i.toString(),
        });
      }
      setAgeOptions(ageData);
    };
    generateAgeData();
  }, [user.dateOfBirth]);

  const validateDetailsStep = () => {
    const nameError = validateName(name);
    const descriptionError = validateDescription(description);
    const attributeError = validateAttribute(attribute);

    setDetailErrors({ nameError, descriptionError, attributeError });

    return !nameError && !descriptionError && !attributeError;
  };

  const validateRequirementsStep = () => {
    const minAgeError = validateMinAge(minAge);
    const maxAgeError = validateMaxAge(maxAge);
    const genderRequirementsError =
      validateGenderRequirements(genderRequirements);
    const privacyError = validatePrivacy(privacy);
    const levelError = validateLevel(level);

    setRequirementErrors({
      minAgeError,
      maxAgeError,
      genderRequirementsError,
      privacyError,
      levelError,
    });

    return (
      !minAgeError &&
      !maxAgeError &&
      !genderRequirementsError &&
      !privacyError &&
      !levelError
    );
  };

  // const validateImage = () => {
  //   const imageError = validateImageFile(image);
  //   setPhotoError(imageError);
  //   return !imageError;
  // };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 1048576; // 10MB in bytes
    const allowedFormats = ["jpg", "jpeg", "png", "webp"];
    setMessage(""); // Clear previous messages
    setIsError(false); // Reset error state

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (
        fileSize > maxFileSize ||
        (fileExtension && !allowedFormats.includes(fileExtension))
      ) {
        setMessage(`Invalid photo size or type`);
        setIsError(true);
        setIsFileValid(false);
        event.target.value = "";
      } else {
        setPhotoError("");
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target?.result as string); // Set image for preview
          setMessage(""); // Clear message
          setIsError(false); // Clear error state
          setIsFileValid(true); // Mark file as valid
        };
        reader.readAsDataURL(file);
      }
    } else {
      // Handle case where file selection is cancelled
      setIsFileValid(false); // Remain or set to invalid
    }
  };

  const handleCreateGroup = async (e: any) => {
    e.preventDefault();
    const isDetailValid = validateDetailsStep();
    const isRequirementsValid = validateRequirementsStep();
    //const isImageValid = validateImage();
    if (isDetailValid && isRequirementsValid) {
      const group = {
        name: name,
        description: description,
        city: user.city,
        state: user.state,
        requirements: {
          privacy: privacy,
          attribute: attribute,
          gender: genderRequirements,
          level: level,
          minimumAge: parseInt(minAge),
          maximumAge: parseInt(maxAge),
        },
      };

      try {
        const result = await createGroup(group);

        const formData = new FormData();
        formData.append("file", fileInputRef.current.files[0]);

        try {
          const response = await updateGroupPicture(result.id, formData);
          const imageUrl = await response.text();
          setImage(imageUrl);
          setMessage("Photo updated successfully!");
          setIsError(false);
        } catch (error) {
          setMessage("Failed to update photo.");
          setIsError(true);
        }
        navigate(`/groups/${result.id}/dashboard`, {
          state: { toast: "Group Created" },
        });

        addGroupToWebSocket(result);
      } catch (error) {
        setMessage("Failed to create group.");
        setIsError(true);
      }
    }
  };

  const getLevelOptions = () => {
    let levelOptions = [];
    const userLevel = user?.attributes[attribute]?.level;

    for (let i = 1; i <= userLevel; i++) {
      levelOptions.push({ label: String(i), value: i });
    }

    return levelOptions;
  };

  const getMargin = () => {
    return isMobile
      ? MARGIN.MOBILE.GROUPS.GROUP_CREATE
      : MARGIN.LARGE.GROUPS.GROUP_CREATE;
  };

  return (
    <div className={`${getMargin()} pb-8`}>
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Create Group"></ILabel>
      </div>

      <form onSubmit={handleCreateGroup}>
        <div style={{ maxWidth: "460px" }}>
          <div className="w-full pb-4">
            <IInput
              label="Name"
              name="name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              errorMessage={detailErrors.nameError}
            ></IInput>
          </div>

          <div className="pb-4">
            <IDropdown
              options={[
                { label: "Public - Open to everyone", value: "PUBLIC" },
                { label: "Private - Request to access", value: "PRIVATE" },
                { label: "Restricted - Connections only", value: "RESTRICTED" },
              ]}
              labelText="Privacy"
              value={privacy}
              onChange={setPrivacy}
              errorMessage={requirementErrors.privacyError}
            />
          </div>

          <div className=" pb-4">
            <IDropdown
              options={[
                { label: "Fitness", value: "FITNESS" },
                { label: "Social", value: "SOCIAL" },
                { label: "Culture", value: "CULTURE" },
                { label: "Intelligence", value: "INTELLIGENCE" },
                { label: "Adventure", value: "ADVENTURE" },
                { label: "NightLife", value: "NIGHTLIFE" },
              ]}
              labelText="Type"
              value={attribute}
              onChange={setAttribute}
              errorMessage={detailErrors.attributeError}
            />
          </div>

          <div className="pb-4">
            <div className="w-full">
              <IDropdown
                options={getLevelOptions()}
                labelText="Level"
                value={level}
                onChange={(value) => setLevel(value)}
                errorMessage={requirementErrors.levelError}
              />
            </div>
          </div>

          <div className="pb-4">
            <IDropdown
              onChange={setGenderRequirements}
              labelText="Gender"
              options={[
                {
                  label: toPascalCase(user.gender) + " only",
                  value: user.gender,
                },
                { label: "No requirements", value: "NONE" },
              ]}
              value={genderRequirements}
              errorMessage={requirementErrors.genderRequirementsError}
            />
          </div>

          <div className="flex gap-2">
            <div className="pb-4 w-full">
              <IDropdown
                labelText="Min age"
                placeholder={""}
                value={minAge}
                onChange={setMinAge}
                errorMessage={requirementErrors.minAgeError}
                options={ageOptions}
              ></IDropdown>
            </div>
            <div className="pb-4 w-full">
              <IDropdown
                labelText="Max age"
                placeholder={""}
                value={maxAge}
                onChange={setMaxAge}
                errorMessage={requirementErrors.maxAgeError}
                options={ageOptions}
              ></IDropdown>
            </div>
          </div>
          <div className="pb-4">
            <ITextArea
              name="description"
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              errorMessage={detailErrors.descriptionError}
            />
          </div>

          <div className="mt-4">
            <GroupPhotoForm
              imageFiles={imageFiles}
              handleImageChange={handleImageChange}
              fileInputRef={fileInputRef}
              image={image}
              photoError={photoError}
            />
          </div>
          <div className="pt-4">
            {" "}
            <IButton
              text={"Create"}
              type="submit"
              bgColor={"bg-regal-blue"}
              textColor={"text-white"}
              className="px-6 mr-2 mt-4"
            />{" "}
          </div>
        </div>
      </form>
    </div>
  );
}

export default GroupCreate;
