/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  calculateAge,
  getAttributeColor,
  toPascalCase,
} from "../../../../Constants/Constants";
import { useUserService } from "../../../../Services/UserService/UserService";
import { useScreenSize } from "../../../../Context/ScreenContext";
import LeaderBoardCard from "../LoadBoardCard/LeadBoardCard";

function UserLeaderBoard({ users = [], loadMore, location }: any) {
  const navigate = useNavigate();
  const totalRows = 7; // Ensure there are always 10 rows
  const rowsToFill = Math.max(totalRows - users.length, 0); // Determine the number of blank rows needed
  const [user, setUser] = useState<any>();
  const { isSmallerThanLargeScreen } = useScreenSize();
  const { fetchUserProfile } = useUserService();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await fetchUserProfile();
        setUser(userProfile);
      } catch (error) {
      } finally {
      }
    };

    fetchData();
  }, []);

  // const handleScroll = (event: any) => {
  //   const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
  //   if (scrollHeight - scrollTop === clientHeight) {
  //     loadMore();
  //   }
  // };

  const getUserRank = () => {
    switch (location) {
      case "city":
        return user?.cityRank;
      case "state":
        return user?.stateRank;
      case "global":
        return user?.globalRank;
    }
  };

  const scrollableDivRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (isSmallerThanLargeScreen) {
        const scrolledToBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight;
        if (scrolledToBottom) {
          loadMore();
        }
      }
      // For larger screens, listen to div scroll
      else {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
          loadMore();
        }
      }
    };

    // Attach the scroll event listener based on screen size
    if (isSmallerThanLargeScreen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      scrollableDivRef.current?.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts or if the screen size changes
    return () => {
      if (isSmallerThanLargeScreen) {
        window.removeEventListener("scroll", handleScroll);
      } else {
        scrollableDivRef.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isSmallerThanLargeScreen, loadMore]); // Dependencies

  return (
    <div>
      {!isSmallerThanLargeScreen && (
        <div>
          <div
            className="overflow-x-auto h-[379px]  mt-4"
            ref={scrollableDivRef}
          >
            <table className="w-full ">
              <thead
                className="bg-white font-thin text-xs sticky top-0 z-10"
                style={{
                  color: "#7E858B",
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  fontStyle: "normal",
                  letterSpacing: "0.06px",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th className="text-left py-2 px-6 w-[100px]">RANK</th>
                  <th className="text-left py-2 px-4 w-[300px]">USER</th>
                  <th className="text-left py-2 px-4 w-[150px]">
                    TOP INTEREST
                  </th>
                  <th className="text-left py-2 px-4 w-[150px]">LEVEL</th>
                  <th className="text-left py-2 px-4 w-[150px]">AGE</th>
                </tr>
              </thead>
              <tbody className="bg-white text-sm">
                {users.map((currentUser: any, index: any) => (
                  <tr
                    key={currentUser.id}
                    className="border-b"
                    onClick={() => navigate(`/profile/${currentUser.id}`)}
                    // Fixed height for rows with content
                  >
                    <td className="py-2 px-6 h-[50px]">{index + 4}.</td>
                    <td className="py-2 px-4">
                      <div className="flex items-center space-x-2">
                        <img
                          src={currentUser.photo}
                          alt={`${currentUser.firstName} ${currentUser.lastName}`}
                          className="w-8 h-8 object-cover rounded-full"
                        />
                        <span>{`${currentUser.firstName} ${currentUser.lastName}`}</span>
                      </div>
                    </td>
                    <td className="py-2 px-4">
                      <span
                        className="py-1 px-3 rounded-full"
                        style={{
                          color: getAttributeColor(currentUser.topAttribute),
                          backgroundColor: getAttributeColor(
                            currentUser.topAttribute,
                            0.2,
                          ),
                          fontFamily: "Satoshi-Regular",
                          fontSize: "11px",
                          lineHeight: "14px",
                          fontStyle: "normal",
                        }}
                      >
                        {toPascalCase(currentUser.topAttribute)}
                      </span>
                    </td>
                    <td className="py-2 px-4">{currentUser.level}</td>
                    <td className="py-2 px-4">{currentUser.age}</td>
                  </tr>
                ))}
                {Array.from({ length: rowsToFill }).map((_, index) => (
                  <tr
                    key={`placeholder-${index}`}
                    className="border-b"
                    style={{ height: "50px" }}
                  >
                    <td className="py-2 px-6">{users.length + index + 4}.</td>
                    <td className="py-2 px-4">&nbsp;</td>
                    <td className="py-2 px-4">&nbsp;</td>
                    <td className="py-2 px-4">&nbsp;</td>
                    <td className="py-2 px-4">&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <table
            className="w-full mt-6 "
            style={{ border: "2px solid #5081FF" }}
          >
            <thead
              className="bg-white font-thin text-xs sticky top-0 z-10 "
              style={{
                color: "#7E858B",
                fontFamily: "Satoshi-Bold",
                fontSize: "10px",
                lineHeight: "12px",
                fontStyle: "normal",
                letterSpacing: "0.06px",
              }}
            >
              <tr>
                <th className="text-left px-6 w-[100px]"></th>
                <th className="text-left px-4 w-[300px]"></th>
                <th className="text-left px-4 w-[150px]"></th>
                <th className="text-left px-4 w-[150px]"></th>
                <th className="text-left px-4 w-[150px]"></th>
              </tr>
            </thead>
            <tbody className="bg-white text-sm ">
              <tr
                key={user?.id}
                onClick={() => navigate(`/profile/${user?.id}`)}
              >
                <td className="py-2 px-6 h-[50px] ">{getUserRank()}.</td>
                <td className="py-2 px-4">
                  <div className="flex items-center space-x-2">
                    <img
                      src={user?.photo}
                      alt={``}
                      className="w-8 h-8 object-cover rounded-full"
                    />
                    <span>{`${user?.firstName} ${user?.lastName}`}</span>
                  </div>
                </td>
                <td className="py-2 px-4">
                  <span
                    className="py-1 px-3 rounded-full"
                    style={{
                      color: getAttributeColor(user?.topAttribute),
                      backgroundColor: getAttributeColor(
                        user?.topAttribute,
                        0.2,
                      ),
                      fontFamily: "Satoshi-Regular",
                      fontSize: "11px",
                      lineHeight: "14px",
                      fontStyle: "normal",
                    }}
                  >
                    {toPascalCase(user?.topAttribute)}
                  </span>
                </td>
                <td className="py-2 px-4">{user?.level}</td>
                <td className="py-2 px-4">{calculateAge(user?.dateOfBirth)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {isSmallerThanLargeScreen && (
        <div>
          {users?.map((currentUser: any, index: any) => (
            <div className="pb-2">
              <LeaderBoardCard
                item={currentUser}
                category="user"
                rank={index + 4}
                img={currentUser?.photo}
                name={currentUser?.firstName + " " + currentUser.lastName}
                firstLabel="LEVEL"
                firstValue={user?.level}
                secondLabel="AGE"
                secondValue={calculateAge(user?.dateOfBirth)}
                attribute={user?.topAttribute}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserLeaderBoard;
