import React from "react";
import IToolTip from "../../../../Library/ToolTip/IToolTip";

function EventPhotoForm({
  handleImageChange,
  fileInputRef,
  image,
  photoError,
}: any) {
  return (
    <div>
      <div className="flex w-full ">
        <div className="flex items-center justify-center border rounded w-[200px] h-[128px] mr-4 md:mb-4">
          <label className="flex flex-col items-center justify-center mx-4 cursor-pointer">
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
            <span className="text-regal-blue text-4xl font-light">+</span>
            <div>Add Image</div>
          </label>
        </div>

        {photoError && <IToolTip message={photoError} />}

        {image && (
          <div className="flex items-center justify-center rounded w-[200px] h-[128px] mr-4 mb-4">
            <img
              src={image}
              alt=""
              className="w-full h-full object-cover rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EventPhotoForm;
