export type CategoryKey = keyof typeof EventCreateMapping;

export const EventCreateMapping = {
  COOKOUTS: "SOCIAL",
  POOL_PARTIES: "SOCIAL",
  RESTAURANTS_AND_DINING: "SOCIAL",
  FAMILY: "SOCIAL",
  HANGOUTS: "SOCIAL",
  COWORKING: "SOCIAL",
  LOCAL_MEETUPS: "SOCIAL",
  ACTIVITIES: "SOCIAL",
  GAMES: "SOCIAL",
  HOBBIES: "SOCIAL",
  NETWORKING: "SOCIAL",
  COMMUNITY_ENGAGEMENT: "SOCIAL",
  VOLUNTEERING: "SOCIAL",
  OTHER_SOCIAL: "SOCIAL",

  EDUCATIONAL: "INTELLIGENCE",
  MATH_AND_SCIENCE: "INTELLIGENCE",
  TECHNOLOGY: "INTELLIGENCE",
  CONFERENCES: "INTELLIGENCE",
  WORKSHOPS: "INTELLIGENCE",
  LECTURES: "INTELLIGENCE",
  SEMINARS: "INTELLIGENCE",
  BUSINESS: "INTELLIGENCE",
  LITERATURE: "INTELLIGENCE",
  PHILOSOPHY: "INTELLIGENCE",
  CRITICAL_THINKING: "INTELLIGENCE",
  PUBLIC_SPEAKING: "INTELLIGENCE",
  OTHER_INTELLIGENCE: "INTELLIGENCE",

  SPORTS: "FITNESS",
  RUNNING: "FITNESS",
  PICKELBALL: "FITNESS",
  NUTRITION: "FITNESS",
  HEALTH_AND_WELLNESS: "FITNESS",
  YOGA: "FITNESS",
  MEDITATION: "FITNESS",
  PILATES: "FITNESS",
  AEROBICS: "FITNESS",
  HIIT_WORKOUTS: "FITNESS",
  CROSSFIT: "FITNESS",
  MARTIAL_ARTS: "FITNESS",
  OTHER_FITNESS: "FITNESS",

  CLUBBING: "NIGHTLIFE",
  BARS: "NIGHTLIFE",
  LIVE_MUSIC: "NIGHTLIFE",
  LOUNGES: "NIGHTLIFE",
  TRIVIA: "NIGHTLIFE",
  CONCERTS: "NIGHTLIFE",
  CASINO: "NIGHTLIFE",
  HOUSE_PARTIES: "NIGHTLIFE",
  RAVING: "NIGHTLIFE",
  THEME_NIGHTS: "NIGHTLIFE",
  AFTER_PARTIES: "NIGHTLIFE",
  KARAOKE: "NIGHTLIFE",
  OTHER_NIGHTLIFE: "NIGHTLIFE",

  CINEMA: "CULTURE",
  THEATRE: "CULTURE",
  FESTIVALS: "CULTURE",
  PAINTING: "CULTURE",
  MUSEUMS: "CULTURE",
  ARTS_AND_CRAFTS: "CULTURE",
  WINE: "CULTURE",
  SPIRITUALITY: "CULTURE",
  FASHION: "CULTURE",
  SIGHT_SEEING: "CULTURE",
  POETRY: "CULTURE",
  COMEDY: "CULTURE",
  CULINARY: "CULTURE",
  HOLISTIC: "CULTURE",
  TOURS: "CULTURE",
  OTHER_CULTURE: "CULTURE",

  NATURE: "ADVENTURE",
  KAYAKING: "ADVENTURE",
  HIKING: "ADVENTURE",
  TRAVEL: "ADVENTURE",
  OUTDOORS: "ADVENTURE",
  CAMPING: "ADVENTURE",
  VACATION: "ADVENTURE",
  SKYDIVING: "ADVENTURE",
  BUNGEE_JUMPING: "ADVENTURE",
  ROCK_CLIMBING: "ADVENTURE",
  CHALLENGES: "ADVENTURE",
  COMPETITIONS: "ADVENTURE",
  JET_SKIS: "ADVENTURE",
  BOATING: "ADVENTURE",
  BEACHES: "ADVENTURE",
  BIKING: "ADVENTURE",
  OTHER_ADVENTURE: "ADVENTURE",
};
