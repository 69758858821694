import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { useDash } from "./DashboardContext";

const StripeContext = createContext<any | null>(null);

interface StripeProviderProps {
  children: ReactNode;
}

export const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const { user } = useDash();
  // Similar to notifications, we might track the state of messages related to Stripe.

  useEffect(() => {
    // Assuming you have a similar endpoint for Stripe messages as for notifications.
    const socket = new SockJS(
      `${process.env.REACT_APP_API_URL}/ws/stripe?token=${localStorage.getItem(
        "token",
      )}`,
    );
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
      // Subscription to a hypothetical Stripe-related topic
      stompClient.subscribe(
        `/topic/stripe/${user?.subscription?.customerId}`,
        (message) => {
          console.log(message);
          const newMessage = JSON.parse(message.body);
          setMessages((prevMessages) => [...prevMessages, newMessage]);

          // Here you could also categorize messages or handle them as needed.
        },
      );
    });

    // Cleanup on unmount
    return () => {
      if (stompClient.connected) {
        stompClient.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Define the context value, potentially including methods to manipulate messages
  const contextValue = {
    messages,
    // Add any functions to interact with the messages here
  };

  return (
    <StripeContext.Provider value={contextValue}>
      {children}
    </StripeContext.Provider>
  );
};

// Custom hook to use the context
export const useStripe = () => {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error("useStripe must be used within a StripeProvider");
  }
  return context;
};
