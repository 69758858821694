import React from "react";
import { Menu } from "@headlessui/react";

interface IMenuButtonProps {
  options: { label: string; action: () => void }[];
  icon?: any; // Array of option objects
  disabled?: boolean;
}

const IMenuButton: React.FC<IMenuButtonProps> = ({
  options,
  icon,
  disabled,
}) => {
  const handleMenuButtonClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    // any other logic you might need
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        onClick={handleMenuButtonClick}
        className="flex items-center space-x-1 px-2 py-2 text-slate-400 border bg-white rounded"
      >
        {icon}
      </Menu.Button>

      <Menu.Items className="z-50  absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md  focus:outline-none">
        {options.map((option, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                onClick={option.action}
              >
                <span
                  style={{
                    fontFamily: "Satoshi-Regular",
                    lineHeight: "16px",
                    letterSpacing: "-0.5px",
                    fontSize: "14px",
                    marginTop: "4px",
                  }}
                >
                  {option.label}
                </span>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default IMenuButton;
