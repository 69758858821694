import React from "react";
import { useScreenSize } from "../../../../Context/ScreenContext";

function TermsOfService() {
  const { isSmallerThanLargeScreen } = useScreenSize();
  const detailStyle: any = {
    color: "var(--Text---primary, #323439)",
    fontFamily: "Satoshi-Regular",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px" /* 150% */,
  };

  const titleStyle: any = {
    color: "var(--Text---primary, #323439)",
    fontFamily: "Satoshi-Regular",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px" /* 150% */,
  };
  return (
    <div
      className={` mt-6 ${isSmallerThanLargeScreen ? "w-full" : "w-[500px]"}`}
    >
      <div>
        <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
          <li className="mb-[10px]">
            <div style={titleStyle}>1. Introduction</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                Welcome to ChatFindr! By accessing our app and using our
                services, you agree to be bound by these Terms of Service and
                our Privacy Policy. Please read them carefully.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>2. Eligibility</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                You must be at least 16 years old to use ChatFindr. By using our
                app, you represent and warrant that you meet this age
                requirement.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>3. User Accounts</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                You are responsible for maintaining the confidentiality of your
                account information and for all activities that occur under your
                account. You agree to immediately notify us of any unauthorized
                use of your account.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 4. User Conduct </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <div className="mb-[5px]">
                  You agree not to use the app to:{" "}
                </div>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "30px",
                  }}
                >
                  <li className="mb-[5px]">Conduct illegal activities.</li>
                  <li className="mb-[5px]">Harass or harm other users.</li>
                  <li>Violate intellectual property rights.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 5. Group Chats and Events</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <li className="mb-[5px]">
                  Group chats and events are created by users. We are not
                  responsible for the content or activities that occur in these
                  groups.
                </li>{" "}
                <li>
                  {" "}
                  Organizers must ensure that their events comply with local
                  laws and regulations.
                </li>
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 6. Content</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                You own the rights to the content you create. However, by
                posting content on ChatFindr, you grant us a non-exclusive,
                royalty-free license to use, reproduce, and display your content
                in connection with the service.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}> 7. Termination</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                We may terminate or suspend your account immediately, without
                prior notice, for any breach of these Terms.
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>
              8. Disclaimers and Limitation of Liability
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                <li className="mb-[5px]">
                  {" "}
                  ChatFindr is provided "as is." We do not guarantee the
                  accuracy or completeness of any information or the reliability
                  of any service.
                </li>
                <li>
                  We are not liable for any direct, indirect, incidental, or
                  consequential damages resulting from your use of ChatFindr.
                </li>
              </li>
            </ul>
          </li>
          <li className="mb-[10px]">
            <div style={titleStyle}>9. Governing Law</div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                marginTop: "10px",
              }}
            >
              <li style={detailStyle}>
                These Terms shall be governed by the laws of Florida, without
                regard to its conflict of law provisions.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TermsOfService;
