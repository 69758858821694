import React from "react";
import { useScreenSize } from "../../../Context/ScreenContext";
import { appHighlights } from "../Constants/LandingConstants";
import { useNavigate } from "react-router-dom";

function DashBodyHighlights() {
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const navigate = useNavigate();

  const titleStyle = {
    fontFamily: "Satoshi-Bold",
    fontSize: isMobile ? "32px" : "40px",
    fontStyle: "normal",
    lineHeight: "48px", // Adjusted to be greater than font-size
    letterSpacing: "-1px",
  };

  const detailStyle = {
    fontFamily: "Satoshi-Regular",
    fontSize: isMobile ? "14px" : "18px",
    fontStyle: "normal",
    lineHeight: "32px",
    letterSpacing: "-0.5px",
    color: "#7E858B",
  };

  const buttonStyle = {
    fontFamily: "Satoshi-Regular",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    borderRadius: "8px",
  };

  return (
    <div
      className={`mt-[111px]  ${
        isMobile ? "px-[25px] " : "px-[144px] pb-[160px]"
      }`}
    >
      {appHighlights.map((highlight, index) => {
        const isTextFirst = index % 2 === 0;
        return (
          <div
            key={index}
            className={`${
              isSmallerThanLargeScreen
                ? "text-center flex flex-col "
                : "flex gap-[120px]"
            } justify-center items-center  ${index !== 0 ? "mt-[40px]" : ""}`}
          >
            {isSmallerThanLargeScreen ? (
              <>
                <div className={`px-[29px] flex-1 mb-[40px]`}>
                  <div style={titleStyle} className="mt-[100px]">
                    {highlight.title}
                  </div>
                  <div className="mt-[12px]" style={detailStyle}>
                    {highlight.detail}
                  </div>
                </div>
                <div className="px-[29px] flex-1">
                  <div className={`${isMobile ? "" : "h-[400px]"} w-full`}>
                    <img
                      src={highlight.image}
                      alt="Description"
                      style={{ objectFit: "cover" }}
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {isTextFirst ? (
                  <>
                    <div
                      className={`px-[29px] flex-1 ${
                        isSmallerThanLargeScreen ? "mb-[40px]" : ""
                      }`}
                    >
                      <div style={titleStyle}>{highlight.title}</div>
                      <div className="mt-[12px]" style={detailStyle}>
                        {highlight.detail}
                      </div>
                    </div>
                    <div className="px-[29px] flex-1">
                      <div className=" h-[400px] w-full">
                        <img
                          src={highlight.image}
                          alt="Description"
                          style={{ objectFit: "cover" }}
                          className="w-full h-auto"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="px-[29px] flex-1">
                      <div className=" h-[400px] w-full">
                        <img
                          src={highlight.image}
                          alt="Description"
                          style={{ objectFit: "cover" }}
                          className="w-full h-auto"
                        />
                      </div>
                    </div>
                    <div
                      className={`px-[29px] flex-1 ${
                        isSmallerThanLargeScreen ? "mb-[40px]" : ""
                      }`}
                    >
                      <div style={titleStyle}>{highlight.title}</div>
                      <div className="mt-[12px]" style={detailStyle}>
                        {highlight.detail}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default DashBodyHighlights;
