import React from "react";
import IDropdown from "../Dropdown/IDropdown";

function ConfirmationDialog({
  show,
  onClose,
  onConfirm,
  message,
  cancel,
  confirm,
  transferableMembers,
  transferMember,
  setTransferMember,
  subMessage,
}: any) {
  if (!show) {
    return null;
  }

  const backdropStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalStyle: React.CSSProperties = {
    position: "relative",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: "150px",
    width: "300px",
    maxWidth: "90%", // Ensures modal does not stretch too much on larger screens
  };

  const closeButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
  };

  const subMessageStyle: React.CSSProperties = {
    fontFamily: "Satoshi-Regular",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    textAlign: "center", // Centers the text horizontally
    width: "100%", // Ensures the div takes up the full width of its parent
    marginTop: "4px", // Adds a little space above the subMessage
    padding: "0 20px", // Adds padding on the sides for better text wrapping
  };
  return (
    <div style={backdropStyle}>
      <div style={modalStyle}>
        <button style={closeButtonStyle} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#323439"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div
          style={{
            fontFamily: "Satoshi-Bold",
            fontSize: "24px",
            lineHeight: "20px",
            fontStyle: "normal",
          }}
          className="mt-4 mb-2"
        >
          {message}
        </div>
        <div style={subMessageStyle}>{subMessage}</div>
        <div>
          {confirm === "Transfer" && (
            <div className="mt-2 w-[200px]">
              <IDropdown
                options={transferableMembers.map((member: any) => ({
                  label: `${member.firstName} ${member.lastName}`,
                  value: member.id,
                }))}
                labelText=""
                value={transferMember}
                onChange={setTransferMember}
              />
            </div>
          )}
        </div>{" "}
        <div className="pt-4">
          <button
            className={`rounded-lg text-white bg-regal-blue border  px-2 sm:px-3 md:px-4 lg:px-5 py-1 sm:py-1.5 md:py-1 lg:py-1 font-light `}
            onClick={onConfirm}
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "12px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {confirm}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationDialog;
