import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN } from "../../../../Constants/Constants";
import IButton from "../../../../Library/Button/IButton";
import { useNavigate } from "react-router-dom";
import IGroupPanel from "../../../../Library/GroupPanel/IGroupPanel";
import { useGroupService } from "../../../../Services/GroupService/GroupService";

function GroupHome() {
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const [isLoading, setIsLoading] = useState(true);
  const [groupHome, setGroupHome] = useState<any>();

  const { getGroupHome } = useGroupService();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getGroupHome();

        setGroupHome(data); // Group by city before setting the state
      } catch (error) {
        // Handle the error appropriately
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getMargin = () => {
    return isMobile
      ? MARGIN.MOBILE.GROUPS.GROUP_HOME
      : MARGIN.LARGE.GROUPS.GROUP_HOME;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={`${getMargin()}`}>
      <div
        className={`flex justify-between ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <div>
          <ILabel text="Groups" />
        </div>
        <div>
          <IButton
            text="Create Group"
            onClick={() => navigate(`/groups/create`)}
            bgColor="bg-regal-blue"
            className="text-white "
          />
        </div>
      </div>

      <div className="mb-[1.85vh]">
        <IGroupPanel
          title={`Joined Groups`}
          groups={groupHome?.joinedGroups}
          onGroupClick={(groupName, groupId) => {
            navigate(`/groups/${groupId}`);
          }}
          subHeader="View your joined groups"
        />
      </div>

      <div className="mb-[1.85vh]">
        <IGroupPanel
          title="Created Groups"
          groups={groupHome?.createdGroups}
          onGroupClick={(groupName, groupId) => {
            navigate(`/groups/${groupId}/dashboard`);
          }}
          subHeader="View your created groups"
        />
      </div>
    </div>
  );
}

export default GroupHome;
