import React from "react";
import { useScreenSize } from "../../../Context/ScreenContext";
import { ACCORDIAN_OPTIONS } from "../../../Constants/Constants";
import { CHECK_SECTION_ITEMS } from "../../../Constants/Constants";

import IAccordian from "../../../Library/Accordian/IAccordian";
import { useNavigate } from "react-router-dom";
import DashBodyHeader from "./DashBodyHeader";
import DashBodyOverView from "./DashBodyOverView";
import DashBodyHighlights from "./DashBodyHighlights";

function DashBody() {
  const navigate = useNavigate();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();

  const contentItems = [
    {
      id: 1,
      header: "Local Chats",
      text: "Find new groups based on your location. Make new connections and chat with like-minded people nearby.",
    },
    {
      id: 2,
      header: "Personalized Experience",
      text: "Select groups based on personal interests for a tailored social experience that matches your interests.",
    },
    {
      id: 3,
      header: "Reputation System",
      text: "The reputation system is based on members being active in the chat and attending group events.",
    },
  ];

  return (
    <div className="w-full">
      <div className="max-w-[1440px]  mx-auto">
        <div className=" pb-[50px]">
          <DashBodyOverView />
          <DashBodyHighlights />
        </div>
      </div>
      <div className="bg-[#5081FF] w-full">
        <div
          className={`text-center ${isMobile ? "py-[75px]" : "py-[136px]"} ${
            isSmallerThanLargeScreen ? "" : "flex"
          } justify-between items-center ${
            isMobile ? "px-[25px] mt-[100px]" : "px-[144px]"
          } max-w-[1440px] mx-auto`}
        >
          {contentItems.map((item) => {
            return (
              <div
                key={item.id}
                className={`flex flex-col items-center mx-[40px] ${
                  isSmallerThanLargeScreen ? "mt-[30px]" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_474_4782)">
                    <path
                      d="M18.3334 9.2333V9.99997C18.3324 11.797 17.7505 13.5455 16.6745 14.9848C15.5986 16.4241 14.0862 17.477 12.3629 17.9866C10.6396 18.4961 8.7978 18.4349 7.11214 17.8121C5.42648 17.1894 3.98729 16.0384 3.00922 14.5309C2.03114 13.0233 1.56657 11.24 1.68481 9.4469C1.80305 7.65377 2.49775 5.94691 3.66531 4.58086C4.83288 3.21482 6.41074 2.26279 8.16357 1.86676C9.91641 1.47073 11.7503 1.65192 13.3918 2.3833M18.3334 3.3333L10.0001 11.675L7.50009 9.17497"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_474_4782">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  className="mt-[20px]"
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "20px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    letterSpacing: "-1px",
                    color: "white",
                  }}
                >
                  {item.header}
                </div>
                <div
                  className="mt-[8px]"
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    color: "#E1E6EB",
                  }}
                >
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="max-w-[1440px]  mx-auto">
        <div
          className={`text-center mt-[120px]  mb-[60px] w-full ${
            isSmallerThanLargeScreen ? "px-[25px]" : "px-[336px]"
          }`}
        >
          <div
            className="mb-[12px]"
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: isMobile ? "32px" : "40px",
              lineHeight: "48px",
              letterSpacing: "-1px",
            }}
          >
            Frequently asked questions
          </div>
          <div
            className="mb-[40px]"
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: isMobile ? "16px" : "18px",
              lineHeight: "32px",
              letterSpacing: "-0.5px",
              color: "#7E858B",
            }}
          >
            Everything you need to know about ChatFindr.
          </div>
          <IAccordian items={ACCORDIAN_OPTIONS} />
        </div>
      </div>
      <div className="max-w-[1440px]  mx-auto">
        <div
          className={`bg-[#F9FAFB] justify-center text-center mb-[120px] py-[36px] ${
            isMobile ? "mx-[25px]" : "mx-[144px]"
          }`}
        >
          <div
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "20px",
              lineHeight: "30px",
            }}
            className="mb-[8px]"
          >
            Still have questions?
          </div>
          <div
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: isMobile ? "14px" : "18px",
              lineHeight: "28px",
            }}
            className="mb-[8px]  px-[14px] text-[#7E858B]"
          >
            Can't find the answer you're looking for? Contact us at
            support@chatfindr.com
          </div>
          <button
            onClick={() => {
              window.location.href = "mailto:support@chatfindr.com";
            }}
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              fontStyle: "normal",
              lineHeight: "20px",
              borderRadius: "8px",
            }}
            className="mt-[24px] bg-regal-blue text-white px-[10px] py-[10px]"
          >
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashBody;
