import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { getAttributeColor } from "../../../../Constants/Constants";

import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useDash } from "../../../../Context/DashboardContext";
import { CameraIcon } from "@heroicons/react/outline";
import { LockIcon, MembersIcon } from "../../../../Icons/Icons";
import { useScreenSize } from "../../../../Context/ScreenContext";

function ShowAllGroups({
  initialGroups,
  fetchGroupsFunction,
  initialPage = 1,
  pageSize = 12,
}: any) {
  const navigate = useNavigate();
  const [groups, setGroups] = useState(initialGroups || []);
  const [page, setPage] = useState(initialPage + 1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { isLargeScreen } = useScreenSize();
  const { user } = useDash();

  const fetchMoreGroups = useCallback(async () => {
    try {
      const newGroups = await fetchGroupsFunction(page, pageSize);
      if (newGroups && newGroups.length > 0) {
        setGroups((prev: any) => [...prev, ...newGroups]);
        setPage((prev: any) => prev + 1);
        if (newGroups.length < pageSize) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setHasMore(false);
    }
  }, [page, pageSize, fetchGroupsFunction]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight &&
      hasMore
    ) {
      fetchMoreGroups();
    }
  }, [fetchMoreGroups, hasMore]);

  const handleNavigate = (groupName: any, groupId: any, organizerId: any) => {
    user?.id === organizerId
      ? navigate(`/groups/${groupId}/dashboard`)
      : navigate(`/groups/${groupId}`);
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (initialGroups) {
      setGroups(initialGroups);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setPage(initialPage + 1);
    setHasMore(true);
  }, [initialGroups, initialPage]);

  const privacyStyles = {
    fontFamily: "Satoshi-Bold",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.6px",
    borderRadius: "99px",
    backgroundColor: "black",
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div>
      {groups?.map((group: any) => (
        <div
          onClick={() =>
            handleNavigate(group?.name, group?.id, group?.organizerId)
          }
        >
          {isLargeScreen && (
            <div className="flex w-[728px] relative mb-[32px] ">
              <div className=" mr-[16px] flex-shrink-0">
                {group.photo ? (
                  <img
                    src={group.photo}
                    alt={group.name}
                    className="w-[288px] h-[192px] object-cover"
                    style={{ borderRadius: "8px" }}
                  />
                ) : (
                  <div className=" w-[288px] h-[192px] border flex justify-center items-center w-full h-full">
                    <CameraIcon className="w-20 h-20 text-gray-500" />
                  </div>
                )}
              </div>
              {/* Photo Above */}
              <div className="pt-[8px] pb-[8px] flex flex-col  h-fit justify-between flex-grow">
                <div className="flex justify-between ">
                  <div className="flex gap-[8px]">
                    <span className="text-white">
                      {group.privacy === "PRIVATE" ? (
                        <>
                          <div
                            style={privacyStyles}
                            className="flex px-[12px] py-[4px]"
                          >
                            <LockIcon />
                            <div className="ml-1">PRIVATE</div>
                          </div>
                        </>
                      ) : group.privacy === "RESTRICTED" ? (
                        <div
                          style={privacyStyles}
                          className="px-[12px] py-[4px]"
                        >
                          RESTRICTED
                        </div>
                      ) : (
                        <div
                          style={privacyStyles}
                          className="px-[12px] py-[4px]"
                        >
                          PUBLIC
                        </div>
                      )}
                    </span>
                    <span
                      className="py-[4px] px-[12px] rounded-full text-white"
                      style={{
                        backgroundColor: getAttributeColor(
                          group?.attribute,
                          0.2,
                        ),
                        color: getAttributeColor(group?.attribute),
                        fontFamily: "Satoshi-Bold",
                        fontSize: "10px",
                        lineHeight: "12px",
                        letterSpacing: "0.6px",
                      }}
                    >
                      {group?.attribute}
                    </span>
                  </div>
                  <div className="flex items-center border rounded-full px-[12px] py-[4px] ml-3 bg-black">
                    <span
                      className="text-white truncate mr-1"
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "10px",
                        lineHeight: "12px",
                        letterSpacing: "0.06px",
                      }}
                    >
                      {group.memberCount}
                    </span>
                    <MembersIcon />
                  </div>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    fontFamily: "Satoshi-Regular",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#323439",
                  }}
                  className="mt-[8px]"
                >
                  {group?.city}, {group?.state}
                </div>
                <div
                  className="mt-[8px] mr-[10px] truncate "
                  style={{
                    fontFamily: "Satoshi-Bold",
                    fontSize: "18px",
                    lineHeight: "22px",
                    letterSpacing: "-1px",
                    overflow: "hidden", // Ensures the overflow content is not visible
                    whiteSpace: "nowrap", // Keeps the text on a single line
                    textOverflow: "ellipsis", // Adds ellipsis when text overflows
                    maxWidth: "100%", // Set a maxWidth to constrain the text to a single line within this width
                  }}
                >
                  {group?.name}
                </div>
                {/* Name above */}

                <div className="mt-[8px] mr-[10px] flex-grow">
                  <div
                    style={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      fontFamily: "Satoshi-Regular",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#7E858B",
                    }}
                    className="max-w-[500px]" // Ensure this class is correctly setting the width
                  >
                    {group?.description}
                  </div>
                </div>
                <div
                  className="absolute bottom-[8px]"
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Reputation: {group.reputation}
                </div>
              </div>
            </div>
          )}

          {!isLargeScreen && (
            <div className="h-[384px] relative mb-[48px]">
              {" "}
              <div className="mt-[24px]">
                {group.photo ? (
                  <img
                    src={group.photo}
                    alt={group.name}
                    className="w-full h-[192px] object-cover"
                    style={{ borderRadius: "8px" }}
                  />
                ) : (
                  <div className="w-full h-[192px] border flex justify-center items-center w-full h-full">
                    <CameraIcon className="w-20 h-20 text-gray-500" />
                  </div>
                )}
              </div>
              <div className="flex justify-between mt-[8px]">
                <div className="flex gap-[8px]">
                  <span className="text-white">
                    {group.privacy === "PRIVATE" ? (
                      <>
                        <div
                          style={privacyStyles}
                          className="flex px-[12px] py-[4px]"
                        >
                          <LockIcon />
                          <div className="ml-1">PRIVATE</div>
                        </div>
                      </>
                    ) : group.privacy === "RESTRICTED" ? (
                      <div style={privacyStyles} className="px-[12px] py-[4px]">
                        RESTRICTED
                      </div>
                    ) : (
                      <div style={privacyStyles} className="px-[12px] py-[4px]">
                        PUBLIC
                      </div>
                    )}
                  </span>
                  <span
                    className="py-[4px] px-[12px] rounded-full text-white"
                    style={{
                      backgroundColor: getAttributeColor(group?.attribute, 0.2),
                      color: getAttributeColor(group?.attribute),
                      fontFamily: "Satoshi-Bold",
                      fontSize: "10px",
                      lineHeight: "12px",
                      letterSpacing: "0.6px",
                    }}
                  >
                    {group?.attribute}
                  </span>
                </div>

                <div className="flex items-center border rounded-full px-[12px] py-[4px] ml-3 bg-black">
                  <span
                    className="text-white truncate mr-1"
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "10px",
                      lineHeight: "12px",
                      letterSpacing: "0.06px",
                    }}
                  >
                    {group.memberCount}
                  </span>
                  <MembersIcon />
                </div>
              </div>
              <div
                className="mt-[12px]"
                style={{
                  overflow: "hidden",
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#323439",
                }}
              >
                {group?.city}, {group?.state}
              </div>
              <div
                className="mb-[8px] mt-[8px] mr-[10px] truncate "
                style={{
                  fontFamily: "Satoshi-Bold",
                  fontSize: "18px",
                  lineHeight: "22px",
                  letterSpacing: "-1px",
                  overflow: "hidden", // Ensures the overflow content is not visible
                  whiteSpace: "nowrap", // Keeps the text on a single line
                  textOverflow: "ellipsis", // Adds ellipsis when text overflows
                  maxWidth: "100%", // Set a maxWidth to constrain the text to a single line within this width
                }}
              >
                {group?.name}
              </div>
              <div
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#7E858B",
                }} // Ensure this class is correctly setting the width
              >
                {group?.description}
              </div>
              <div
                className="mt-[8px] absolute bottom-0"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                Reputation: {group.reputation}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ShowAllGroups;
