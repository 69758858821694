import React from "react";
import IPanel from "../../../../Library/Panel/IPanel";
import {
  getAttributeColor,
  toPascalCase,
} from "../../../../Constants/Constants";
import { useNavigate } from "react-router-dom";

function LeaderBoardCard({
  rank,
  img,
  name,
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  attribute,
  className,
  category,
  item,
  isUser,
}: any) {
  const navigate = useNavigate();
  return (
    <div>
      <IPanel
        height={`h-[180px] ${className}`}
        onPanelClick={() => {
          category === "user"
            ? navigate(`/profile/${item.id}`)
            : navigate(`/groups/${item.id}`);
        }}
      >
        <div>
          <span
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "20px",
              lineHeight: "24px",
              fontStyle: "normal",
              letterSpacing: "-1px",
            }}
          >
            {rank}.
          </span>
          {name && (
            <div>
              <div className="flex items-center space-x-4">
                <img
                  src={img}
                  alt=""
                  className="w-16 h-16 object-cover rounded-full" // Removed xl:mr-0 and mr-4 to use space-x-4 for consistency
                />
                <div className="flex-1 min-w-0">
                  <span
                    className="text-[#323439] block overflow-hidden text-ellipsis whitespace-nowrap"
                    style={{
                      fontFamily: "Satoshi-Bold",
                      fontSize: "20px",
                      lineHeight: "24px",
                      fontStyle: "normal",
                      letterSpacing: "-1px",
                    }}
                  >
                    {name}
                  </span>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center space-x-2">
                <div className="flex-1 min-w-0">
                  {" "}
                  {/* Ensure this div takes up available space but respects flex siblings */}
                  <div
                    className="text-[#7E858B] mb-1 block overflow-hidden text-ellipsis whitespace-nowrap"
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "10px",
                      lineHeight: "12px",
                      fontStyle: "normal",
                      letterSpacing: "0.6px",
                    }}
                  >
                    {firstLabel}
                  </div>
                  <div
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontStyle: "normal",
                    }}
                  >
                    {firstValue}
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  {" "}
                  {/* Similar constraint for the second set of labels */}
                  <div
                    className="text-[#7E858B] mb-1 overflow-hidden text-ellipsis whitespace-nowrap"
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "10px",
                      lineHeight: "12px",
                      fontStyle: "normal",
                      letterSpacing: "0.6px",
                    }}
                  >
                    {secondLabel}
                  </div>
                  <div
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontStyle: "normal",
                    }}
                  >
                    {secondValue}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "11px",
                      lineHeight: "14px",
                      fontStyle: "normal",
                    }}
                  >
                    <span
                      className="py-1 px-3 rounded-full"
                      style={{
                        color: getAttributeColor(attribute),
                        backgroundColor: getAttributeColor(attribute, 0.2),
                      }}
                    >
                      {toPascalCase(attribute)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </IPanel>
    </div>
  );
}

export default LeaderBoardCard;
