import React, { useEffect, useRef, useState } from "react";

import { HorizontalDots } from "../../../Icons/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useGroupService } from "../../../Services/GroupService/GroupService";
function ChatMembers() {
  const [members, setMembers] = useState<any>();
  const navigate = useNavigate();
  const [, setIsLoading] = useState(true);
  const membersEndRef = useRef<any>(null);
  const { groupId } = useParams();
  const { getGroupMembers } = useGroupService();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await getGroupMembers(groupId);
        setMembers(data);
      } catch (error) {}
      setIsLoading(false);
    };

    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    // Scrolls to the bottom every time messages change
    membersEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [members]);

  /*if (isLoading) {
    return <ISpinner />;
  }*/
  return (
    <div className="px-8 pt-4">
      {members?.map((member: any) => (
        <div
          className="flex justify-between mb-6"
          onClick={() => navigate(`/profile/${member?.id}`)}
        >
          <div className="flex items-center">
            <img
              src={member.photo || "default-avatar.png"}
              alt={member.name}
              className="h-[36px] w-[36px] rounded-full mr-2"
            />
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {member.firstName} {member.lastName}
            </div>
          </div>
          <div>
            <button>
              <HorizontalDots />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChatMembers;
