import React, { useEffect, useState } from "react";
import EmailPasswordForm from "./Step1/EmailPasswordForm";
import GeneralInformationForm from "./Step2/GeneralInformationForm";

import Notification from "./Step4/Notification";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../Services/AuthService/AuthService";
import { Logo } from "../../Icons/Icons";
import { DiscoverGroupsOn } from "../../Constants/Constants";
import { useScreenSize } from "../../Context/ScreenContext";
import { Helmet } from "react-helmet-async";

function SignUp() {
  const [step, setStep] = useState<number>(1);
  const { isAuthenticated } = useAuthService();
  const { isSmallerThanLargeScreen, isMobile } = useScreenSize();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    month: "",
    day: "",
    year: "1990",
    gender: "",
    state: "",
    city: "",
    image: "",
  });

  const handleNextStep = (): void => {
    setStep(step + 1);
  };

  const handleBack = (): void => {
    setStep(step - 1);
  };

  const updateUser = (newData: Partial<any>): void => {
    setUserData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await isAuthenticated();
        if (response) {
          navigate("/discover");
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    // Disable scrolling when the component mounts
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    // Enable scrolling back when the component unmounts
    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      className="w-screen h-screen relative overflow-hidden flex justify-center items-center"
      style={{
        paddingTop: "env(safe-area-inset-top)",
        paddingRight: "env(safe-area-inset-right)",
        paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
      }}
    >
      <Helmet>
        <title>Sign Up</title>
        <meta
          name="description"
          content="Sign up for an account on ChatFindr and discover groups in your city."
        />
      </Helmet>
      <div className="absolute inset-0 w-1/2 h-full bg-white"></div>
      {!isSmallerThanLargeScreen && (
        <div className="absolute inset-0 left-1/2 w-1/2 h-full bg-gradient-to-tl from-slate-ish via-regal-blue to-white"></div>
      )}

      {/* Content Container */}
      <div className="w-full max-w-[1440px] h-full flex z-10">
        <button
          type="button"
          className={`absolute top-0 flex items-center ${
            isMobile ? "ml-[10px]" : "pl-[32px]"
          } pt-[32px] `}
          onClick={() => navigate("/")}
        >
          <Logo />
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-1px",
              color: "#323439",
            }}
          >
            ChatFindr
          </div>
        </button>
        <div
          className={`h-full flex items-center justify-center ${
            isSmallerThanLargeScreen ? "w-full" : "w-1/2"
          }`}
        >
          <form className="max-w-[720px] w-full flex items-center justify-center">
            {step === 1 && (
              <EmailPasswordForm
                onNextStep={handleNextStep}
                userData={userData}
                updateUser={updateUser}
              />
            )}
            {step === 2 && (
              <GeneralInformationForm
                onNextStep={handleNextStep}
                userData={userData}
                updateUser={updateUser}
                handleBack={handleBack}
              />
            )}
            {step === 3 && <Notification userData={userData} />}
          </form>
        </div>
        <div
          className={`absolute bottom-0  pb-[32px] ${
            isMobile ? "ml-[16px]" : "pl-[32px]"
          } `}
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          ©Copyright ChatFindr 2024
        </div>
        {!isSmallerThanLargeScreen && (
          <div className="w-1/2 h-full flex items-center justify-center">
            <div className="max-w-[720px] w-full flex items-center justify-center">
              <DiscoverGroupsOn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;
