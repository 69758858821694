import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import IToolTip from "../ToolTip/IToolTip";
import { ChevronDownIcon, ChevronUpIcon } from "../../Icons/Icons";

interface IComboBoxProps {
  options: Array<{ value: any; label: any }>;
  placeholder?: string;
  onChange?: (value: any) => void;
  labelText: string;
  value: any;
  errorMessage?: string;
}

const IComboBox: React.FC<IComboBoxProps> = ({
  options,
  placeholder,
  onChange,
  labelText,
  value,
  errorMessage,
}) => {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase()),
        );

  const handleChange = (selectedOption: { value: any; label: any }) => {
    // Assuming onChange expects just the value and not the entire option object
    if (onChange) {
      onChange(selectedOption.value);
    }
  };

  return (
    <div className="relative">
      <div style={{ display: "flex" }}>
        {labelText && (
          <label
            className="mb-1"
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {labelText}
          </label>
        )}
        {/* <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div> */}
      </div>

      <Combobox
        value={options?.find((option) => option.value === value)}
        onChange={handleChange}
      >
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Combobox.Input
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "16px",
                  lineHeight: "2px",
                  fontStyle: "normal",
                }}
                className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pr-10 text-left focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                displayValue={(item: { label: any }) =>
                  item ? item.label : ""
                }
                onChange={(event) => setQuery(event.target.value)}
                placeholder={placeholder}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Combobox.Button>
            </div>
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions?.map((option) => (
                  <Combobox.Option
                    key={option.value}
                    value={option}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.label}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            {/* Icon or checkmark to show selection */}
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </>
        )}
      </Combobox>
      <div
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "12px",
          lineHeight: "20px",
          fontStyle: "normal",
          color: "red",
        }}
      >
        {errorMessage && errorMessage}
      </div>
    </div>
  );
};

export default IComboBox;
