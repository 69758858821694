// supportService.js
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useSupportService = () => {
  const axiosInstance = useAxios();

  const sendSupportMessage = async (
    userId: any,
    requestType: any,
    subject: any,
    description: any,
  ) => {
    const response = await axiosInstance.post("/support", {
      userId: userId,
      requestType: requestType,
      subject: subject,
      description: description,
    });
    return response.data;
  };

  return { sendSupportMessage };
};
