import React, { ChangeEvent, useState } from "react";
import ITextArea from "../../../../Library/TextArea/ITextArea";
import IButton from "../../../../Library/Button/IButton";
import IInput from "../../../../Library/Input/IInput";
import IDropdown from "../../../../Library/Dropdown/IDropdown";
import Toast from "../../../../Library/Toast/Toast";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { useDash } from "../../../../Context/DashboardContext";
import { TOAST_MESSAGES } from "../../../../Constants/Constants";
import { useSupportService } from "../../../../Services/SupportService/SupportService";

function Support() {
  const { sendSupportMessage } = useSupportService();
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>();
  const [trouble, setTrouble] = useState<any>();
  const [subject, setSubject] = useState<any>();
  const [description, setDescription] = useState<any>();
  const { user } = useDash();
  const { isSmallerThanLargeScreen } = useScreenSize();

  const handleSend = async (e: any) => {
    e.preventDefault();

    try {
      await sendSupportMessage(user?.id, trouble, subject, description);

      setSubject("");
      setDescription("");
      setTrouble("");
      setMessage(TOAST_MESSAGES.SUPPORT.SUCCESS);
      setIsError(false);
    } catch (error) {
      setMessage(TOAST_MESSAGES.SUPPORT.FAILURE);
      setIsError(true);
    }
  };

  return (
    <div
      className={` mt-6 ${isSmallerThanLargeScreen ? "w-full" : "w-[460px]"}`}
    >
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <form onSubmit={handleSend}>
        <div className="mb-[24px]">
          <IDropdown
            value={trouble}
            onChange={setTrouble}
            labelText="What are you having trouble with?"
            options={[
              { value: "ACCOUNT", label: "Account" },
              { value: "SUBSCRIPTION", label: "Subscription" },
              { value: "GROUPS", label: "Groups" },
              { value: "EVENTS", label: "Events" },
              { value: "LEADERBOARD", label: "Leaderboard" },
            ]}
            placeholder={""}
          />
        </div>

        <div className="mb-[24px]">
          <IInput
            name="Subject"
            label="Subject"
            placeholder={""}
            value={subject}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSubject(e.target.value)
            }
          />
        </div>
        <div className="mb-[24px] w-full">
          <ITextArea
            name="description"
            placeholder={""}
            value={description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(e.target.value)
            }
          />
        </div>

        <div className="mb-[24px] w-full">
          <IButton
            type="submit"
            text="Submit Request"
            onClick={() => {}}
            className="px-6"
            bgColor="bg-regal-blue"
            textColor="text-white"
          />
        </div>
      </form>
    </div>
  );
}

export default Support;
