import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAttributeColor,
  toPascalCase,
} from "../../../../Constants/Constants";
import { useScreenSize } from "../../../../Context/ScreenContext";
import LeaderBoardCard from "../LoadBoardCard/LeadBoardCard";

function GroupLeaderBoard({ groups = [], loadMore }: any) {
  // Default groups to an empty array if not provided
  const navigate = useNavigate();
  const totalRows = 7; // Ensure there are always 10 rows
  const { isSmallerThanLargeScreen } = useScreenSize();
  // Determine the number of blank rows needed by subtracting the current number of groups from the totalRows
  const rowsToFill = Math.max(totalRows - groups.length, 0);

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      loadMore();
    }
  };

  const scrollableDivRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (isSmallerThanLargeScreen) {
        const scrolledToBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight;
        if (scrolledToBottom) {
          loadMore();
        }
      }
      // For larger screens, listen to div scroll
      else {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
          loadMore();
        }
      }
    };

    // Attach the scroll event listener based on screen size
    if (isSmallerThanLargeScreen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      scrollableDivRef.current?.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts or if the screen size changes
    return () => {
      if (isSmallerThanLargeScreen) {
        window.removeEventListener("scroll", handleScroll);
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollableDivRef.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isSmallerThanLargeScreen, loadMore]); // Dependencies

  return (
    <div>
      {!isSmallerThanLargeScreen && (
        <div className="overflow-x-auto h-[379px] mt-4" ref={scrollableDivRef}>
          <table className="w-full">
            <thead
              className="bg-white font-thin text-xs sticky top-0 z-10"
              style={{
                color: "#7E858B",
                fontFamily: "Satoshi-Bold",
                fontSize: "10px",
                lineHeight: "12px",
                fontStyle: "normal",
                letterSpacing: "0.06px",
                zIndex: 1,
              }}
            >
              <tr>
                <th className="text-left py-2 px-6 w-[100px]">RANK</th>
                <th className="text-left py-2 px-4 w-[300px]">GROUP</th>
                <th className="text-left py-2 px-4 w-[150px]">TYPE</th>
                <th className="text-left py-2 px-4 w-[150px]">MEMBERS</th>
                <th className="text-left py-2 px-4 w-[150px]">REPUTATION</th>
              </tr>
            </thead>
            <tbody className="bg-white text-sm">
              {groups.map((group: any, index: any) => (
                <tr
                  key={index + group.id}
                  className="border-b"
                  onClick={() => navigate(`/groups/${group.id}`)}
                >
                  <td className="py-2 px-6 h-[50px]">{index + 4}.</td>
                  <td className="py-2 px-4">
                    <div className="flex items-center space-x-2">
                      <img
                        src={group.photo}
                        alt={group.name}
                        className="w-8 h-8 object-cover rounded-full"
                      />
                      <span>{group.name}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4">
                    <span
                      className="py-1 px-3 rounded-full"
                      style={{
                        color: getAttributeColor(group.attribute),
                        backgroundColor: getAttributeColor(
                          group.attribute,
                          0.2,
                        ),
                        fontFamily: "Satoshi-Regular",
                        fontSize: "11px",
                        lineHeight: "14px",
                        fontStyle: "normal",
                      }}
                    >
                      {toPascalCase(group.attribute)}
                    </span>
                  </td>
                  <td className="py-2 px-4">{group.members}</td>
                  <td className="py-2 px-4">{group.reputation}</td>
                </tr>
              ))}
              {Array.from({ length: rowsToFill }).map((_, index) => (
                <tr key={`placeholder-${index}`} className="border-b">
                  <td className="py-2 px-6 h-[50px]">
                    {groups.length + index + 4}.
                  </td>
                  <td className="py-2 px-4">&nbsp;</td>
                  <td className="py-2 px-4">&nbsp;</td>
                  <td className="py-2 px-4">&nbsp;</td>
                  <td className="py-2 px-4">&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isSmallerThanLargeScreen && (
        <div onScroll={handleScroll}>
          {groups?.map((group: any, index: any) => (
            <div className="pb-2">
              <LeaderBoardCard
                category="group"
                item={group}
                rank={index + 4}
                img={group?.photo}
                name={group?.name}
                firstLabel="MEMBERS"
                firstValue={group?.members}
                secondLabel="REPUTATION"
                secondValue={group?.reputation}
                attribute={group?.attribute}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GroupLeaderBoard;
