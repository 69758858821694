import React from "react";

interface IToggleButtonOption {
  value: string;
  label: string;
}

interface IToggleButtonGroupProps {
  label?: string; // Optional label prop
  options: IToggleButtonOption[];
  selectedValue: string;
  onChange: (value: string) => void;
}

function IToggleButtonGroup({
  label,
  options,
  selectedValue,
  onChange,
}: IToggleButtonGroupProps) {
  return (
    <div>
      {label && (
        <div
          className="mb-1"
          style={{
            fontFamily: "Satoshi-Bold",
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "0.6px",
            color: "#323439",
          }}
        >
          {label}
        </div>
      )}
      <div className="mb-[24px]">
        {options.map((option, index) => (
          <div key={index} className="mb-[8px]">
            <button
              key={index}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "13px",
                fontStyle: "normal",
                lineHeight: "16px",
                borderRadius: "8px",
              }}
              className={`  ${
                selectedValue === option.value
                  ? "bg-[#C7D5FB] text-[#4368CC]"
                  : "bg-white "
              } border py-[6px] px-[16px]  focus:outline-none focus:ring-2 focus:ring-regal-blue focus:border-transparent`}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default IToggleButtonGroup;
