import React, { useContext } from "react";
import ILabel from "../../../Library/Label/ILabel";
import IPanel from "../../../Library/Panel/IPanel";
import { NotificationContext } from "../../../Context/NotificationContext";

import { MARGIN } from "../../../Constants/Constants";
import { useScreenSize } from "../../../Context/ScreenContext";
import NotificationCard from "./NotificationCard";

function NotificationsComponent() {
  const webSocketContext = useContext(NotificationContext);
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { messages, markAllAsRead } = webSocketContext as any;

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.NOTIFICATIONS : MARGIN.LARGE.NOTIFICATIONS;
  };

  return (
    <div className={`${getMargin()} `}>
      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Notifications" />
      </div>

      <div className="xl:w-3/4">
        <div>
          {messages?.thisWeek?.filter((message: any) => !message.hidden)
            .length === 0 &&
            messages?.lastWeek?.filter((message: any) => !message.hidden)
              .length === 0 &&
            messages?.older?.filter((message: any) => !message.hidden)
              .length === 0 && (
              <IPanel
                height="h-[200px]"
                buttonLabel={
                  messages?.thisWeek?.filter((message: any) => !message.hidden)
                    .length > 0
                    ? "Read All"
                    : ""
                }
                title="This Week"
              >
                <div
                  className={`flex ${
                    isMobile ? "h-[80%]" : "h-[65%]"
                  } justify-center items-center`}
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#7E858B",
                  }}
                >
                  No Notifications
                </div>
              </IPanel>
            )}
        </div>

        <div className="pb-4">
          {messages?.thisWeek?.filter((message: any) => !message.hidden)
            .length > 0 && (
            <IPanel
              buttonLabel={
                messages?.thisWeek?.filter((message: any) => !message.hidden)
                  .length > 0
                  ? "Read All"
                  : ""
              }
              title="This Week"
              onButtonClick={() => markAllAsRead()}
            >
              <div>
                <NotificationCard
                  messages={messages?.thisWeek}
                  category="thisWeek"
                />
              </div>
            </IPanel>
          )}
        </div>

        <div className="pb-4">
          {messages?.lastWeek?.filter((message: any) => !message.hidden)
            .length > 0 && (
            <IPanel
              onButtonClick={() => markAllAsRead()}
              title="Last Week"
              buttonLabel={
                messages?.thisWeek?.filter((message: any) => !message.hidden)
                  .length === 0 &&
                messages?.lastWeek?.filter((message: any) => !message.hidden)
                  .length > 0
                  ? "Read All"
                  : ""
              }
            >
              <NotificationCard
                messages={messages?.lastWeek}
                category="lastWeek"
              />
            </IPanel>
          )}
        </div>

        <div className="pb-4">
          {messages?.older?.filter((message: any) => !message.hidden).length >
            0 && (
            <IPanel
              onButtonClick={() => markAllAsRead()}
              title="Older"
              buttonLabel={
                messages?.thisWeek?.filter((message: any) => !message.hidden)
                  .length === 0 &&
                messages?.lastWeek?.filter((message: any) => !message.hidden)
                  .length === 0
                  ? "Read All"
                  : ""
              }
            >
              <NotificationCard messages={messages?.older} category="older" />
            </IPanel>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationsComponent;
