import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";

import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN } from "../../../../Constants/Constants";
import IButton from "../../../../Library/Button/IButton";
import { useLocation, useNavigate } from "react-router-dom";

import Toast from "../../../../Library/Toast/Toast";
import IEventPanel from "../../../../Library/EventPanel/IEventPanel";
import { useDash } from "../../../../Context/DashboardContext";
import { useEventService } from "../../../../Services/EventService/EventService";

function EventHome() {
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const [eventHome, setEventHome] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();
  const [isError, setIsError] = useState(false);
  const { user } = useDash();
  const location = useLocation();
  const navigate = useNavigate();
  const { getEventHome } = useEventService();

  const fetchHome = async () => {
    try {
      const data = await getEventHome();

      setEventHome(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (location.state && location.state.toast) {
      setMessage(location.state.toast); // Display the toast message
      setIsError(false); // Set as success message

      // Navigate to the same URL without the state to "clear" the toast message
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchHome();
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getMargin = () => {
    return isMobile
      ? MARGIN.MOBILE.EVENTS.EVENT_HOME
      : MARGIN.LARGE.EVENTS.EVENT_HOME;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      {message && (
        <Toast message={message} setMessage={setMessage} isError={isError} />
      )}
      <div
        className={`flex justify-between ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <div>
          <ILabel text="Events" />
        </div>
        <div className="flex">
          <IButton
            text="Create Event"
            onClick={() => navigate(`/events/create`)}
            bgColor="bg-regal-blue"
            className="text-white "
          />
        </div>
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Event Discovery"
          events={eventHome?.eventDiscovery}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader="Discover events from groups you've joined"
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Event Invites"
          events={eventHome?.eventInvites}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader="View events you've been invited to"
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Joined Events"
          events={eventHome?.joinedEvents}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader="View your joined events"
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Created Events"
          events={eventHome?.createdEvents}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}/dashboard`);
          }}
          marginTop="mt-0"
          subHeader="View your created events"
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Event History"
          events={eventHome?.eventHistory}
          showAll={true}
          onEventClick={(eventName, eventId, eventOrganizerId) => {
            eventOrganizerId === user.id
              ? navigate(`/events/${eventId}/dashboard`)
              : navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader="View your event history"
        />
      </div>
    </div>
  );
}

export default EventHome;
