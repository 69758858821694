import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUserService } from "../Services/UserService/UserService";
import { useAuthService } from "../Services/AuthService/AuthService";

export const AuthContext = createContext<any>(null); // Use null for initial value if no default value

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: any) => {
  const { login, logout } = useAuthService();
  const { deleteAccount } = useUserService();
  const [authError] = useState<any>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authMessage, setAuthMessage] = useState<any>();
  const [activeStates, setActiveStates] = useState<any>(["Florida"]);

  const handleLogin = async (loginData: any) => {
    setIsLoading(true);
    try {
      const result = await login(loginData);

      if (!activeStates.includes(result.location)) {
        navigate("/waitlist");
      } else {
        localStorage.setItem("token", result.accessToken);
        navigate("/discover");
      }
    } catch (error: any) {
      if (
        error.response.data.message ===
        `Email not verified for: ${loginData.authIdentifier}`
      ) {
        navigate("/validate-email", { state: loginData });
      }
      const errorMessage = error.response.data.message;
      setAuthMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("token");
      navigate("/");
    } catch (error) {}
  };

  const deleteProfile = async () => {
    try {
      await deleteAccount();
      localStorage.removeItem("token");
      navigate("/");
    } catch (error) {}
  };

  const authContextValue = {
    authError,
    handleLogin,
    handleLogout,
    isLoading,
    deleteProfile,
    authMessage,
    setAuthMessage,
    activeStates,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
