// leaderboardService.js
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useLeaderboardService = () => {
  const axiosInstance = useAxios();

  const getLeaderboard = async (route: any) => {
    const response = await axiosInstance.get(`/leaderboard?${route}`);
    return response.data;
  };

  return { getLeaderboard };
};
