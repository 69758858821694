import React from "react";
import { Routes, Route } from "react-router-dom";
import { DashboardProvider } from "./Context/DashboardContext";
import NotificationProvider from "./Context/NotificationContext";
import { ChatProvider } from "./Context/ChatContext";
import Dashboard from "./Components/Dashboard/Dashboard";

import Event from "./Components/Dashboard/Events/Event/Event";

import GroupEdit from "./Components/Dashboard/Groups/GroupEdit/GroupEdit";

import EventHome from "./Components/Dashboard/Events/EventHome/EventHome";
import EventDashboard from "./Components/Dashboard/Events/EventDashboard/EventDashboard";
import EventEdit from "./Components/Dashboard/Events/EventEdit/EventEdit";
import EventAttendeesList from "./Components/Dashboard/Events/EventAttendeesList/EventAttendeesList";
import EventCreate from "./Components/Dashboard/Events/EventCreate/EventCreate";
import Profile from "./Components/Dashboard/Profile/Profile";
import Settings from "./Components/Dashboard/Settings/Settings";
import Leaderboard from "./Components/Dashboard/Leaderboard/Leaderboard";
import Notifications from "./Components/Dashboard/Notifications/Notifications";
import Chat from "./Components/Dashboard/Chat/Chat";
import ProtectedRoute from "./ProtectedRoute";

import Connections from "./Components/Dashboard/Profile/Connections";
import Discover from "./Components/Dashboard/Discover/Discover";
import DiscoverFilteredResults from "./Components/Dashboard/Discover/DiscoverFilteredResults";
import { SearchProvider } from "./Context/SearchContext";
import { StripeProvider } from "./Context/StripeContext";
import GroupDashboard from "./Components/Dashboard/Groups/GroupDashboard/GroupDashboard";
import GroupHome from "./Components/Dashboard/Groups/GroupHome/GroupHome";
import GroupCreate from "./Components/Dashboard/Groups/GroupCreate/GroupCreate";
import Group from "./Components/Dashboard/Groups/Group/Group";
import GroupMembersList from "./Components/Dashboard/Groups/GroupMembersList/GroupMembersList";

const ProtectedRoutes = () => {
  return (
    <DashboardProvider>
      <NotificationProvider>
        <ChatProvider>
          <SearchProvider>
            <StripeProvider>
              <Routes>
                <Route
                  path=""
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                >
                  <Route path="discover" element={<Discover />}>
                    <Route
                      path=":searchTerm"
                      element={<DiscoverFilteredResults />}
                    />
                  </Route>

                  <Route path="groups/:groupId" element={<Group />} />
                  <Route
                    path="groups/:groupId/members"
                    element={<GroupMembersList />}
                  />
                  <Route path="groups" element={<GroupHome />} />
                  <Route path="groups/create" element={<GroupCreate />} />
                  <Route
                    path="groups/:groupId/dashboard/edit"
                    element={<GroupEdit />}
                  />
                  <Route
                    path="groups/:groupId/dashboard"
                    element={<GroupDashboard />}
                  />

                  <Route path="events/:eventId" element={<Event />} />
                  <Route path="events" element={<EventHome />} />
                  <Route
                    path="events/:eventId/dashboard"
                    element={<EventDashboard />}
                  />
                  <Route
                    path="events/:eventId/dashboard/edit"
                    element={<EventEdit />}
                  />
                  <Route
                    path="events/:eventId/attendees"
                    element={<EventAttendeesList />}
                  />
                  <Route path="events/create" element={<EventCreate />} />
                  <Route path="/profile/:userId" element={<Profile />} />
                  <Route
                    path="/profile/:userId/connections"
                    element={<Connections />}
                  />
                  <Route path="settings" element={<Settings />} />
                  <Route path="leaderboard" element={<Leaderboard />} />
                  <Route path="notifications" element={<Notifications />} />

                  <Route path="chat" element={<Chat />} />
                  <Route path="chat/:groupId" element={<Chat />}></Route>
                  <Route
                    path="chat/:groupId/members"
                    element={<Chat />}
                  ></Route>
                </Route>
              </Routes>
            </StripeProvider>
          </SearchProvider>
        </ChatProvider>
      </NotificationProvider>
    </DashboardProvider>
  );
};

export default ProtectedRoutes;
