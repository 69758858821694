import { useAxios } from "../../Context/AxiosContext";

export const updateUserSubscription = async (tier: any) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/subscription`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ tier: tier }),
      },
    );
    if (response.ok) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const updatePaymentMethod = async (
  paymentMethodId: any,
  brand: any,
  lastFour: any,
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/billing`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethodId,
          paymentMethodType: brand,
          paymentMethodLastFour: lastFour,
        }),
      },
    );
    if (response.ok) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const useSubscriptionService = () => {
  const axiosInstance = useAxios();

  const addSubscriber = async (email: string) => {
    const response = await axiosInstance.post(`/subscription`, { email });
    return response.data;
  };

  return {
    addSubscriber,
  };
};
