import React, { useState } from "react";
import { CameraIcon } from "@heroicons/react/outline";

const ICarousel = ({ imageUrls }: any) => {
  const [activeIndex] = useState(0);

  const hasImages = Array.isArray(imageUrls) && imageUrls.length > 0;

  return (
    <div
      id="controls-carousel"
      className="relative w-full"
      data-carousel="static"
    >
      <div className="relative  overflow-hidden rounded-lg md:h-[480px] h-[240px]">
        {hasImages ? (
          imageUrls.map((imageUrl, index) => (
            <div
              key={index}
              className={`duration-700 ease-in-out ${
                index === activeIndex ? "block" : "hidden"
              }`}
              data-carousel-item
            >
              <img
                src={imageUrl}
                className="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                alt={`Slide ${index}`}
              />
            </div>
          ))
        ) : (
          <div className="border flex justify-center items-center w-full h-full">
            <CameraIcon className="w-20 h-20 text-gray-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ICarousel;
