import React, { useEffect, useState } from "react";

function Toast({ message, isError, setMessage }: any) {
  const [slideState, setSlideState] = useState<"in" | "out" | "hidden">(
    "hidden",
  );

  useEffect(() => {
    if (message) {
      setSlideState("in");
      const slideOutTimer = setTimeout(() => {
        setSlideState("out");
        const hideTimer = setTimeout(() => {
          setMessage(null);
          setSlideState("hidden");
        }, 500); // Assuming the slide-out duration is 500ms
        return () => clearTimeout(hideTimer);
      }, 4500); // Show for 4.5 seconds, then start slide-out

      return () => {
        clearTimeout(slideOutTimer);
      };
    }
  }, [message, setMessage]);

  return (
    <div>
      {message && (
        <div
          className={`fixed bottom-0 right-0 mb-4 p-4 text-center ${
            isError ? "bg-red-600 text-white" : "bg-regal-blue text-white"
          }`}
          style={{
            transform:
              slideState === "in" ? "translateX(0%)" : "translateX(100%)",
            transition: "transform 500ms ease-in-out",
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "20px",
            fontStyle: "normal",
            zIndex: 9999,
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
}

export default Toast;
