import React, { useEffect } from "react";
import EditProfile from "./EditProfile/EditProfile";
import Security from "./Security/Security";
import ILabel from "../../../Library/Label/ILabel";
import Subscriptions from "./Subscriptions/Subscriptions";
import ITab from "../../../Library/Tab/ITab";
import Support from "./Support/Support";
import { useScreenSize } from "../../../Context/ScreenContext";
import { MARGIN } from "../../../Constants/Constants";
import { useLocation } from "react-router-dom";
import TermsOfService from "./TermsOfService/TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

function Settings() {
  const [tabValue, setTabValue] = React.useState(0);
  const { isMobile } = useScreenSize();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tab !== undefined) {
      setTabValue(location.state.tab);
    }
  }, [location.state]);

  const handleTabChange = (newValue: any) => {
    setTabValue(newValue); // Update the state to the new tab index
  };

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.SETTINGS : MARGIN.LARGE.SETTINGS;
  };
  return (
    <div>
      <div className={`pt-[32px] pb-[36px] ${getMargin()}`}>
        <ILabel text="Settings"></ILabel>
      </div>
      <div className={`${getMargin()}`}>
        <ITab
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          tabs={[
            "Account",
            //"Subscription",
            "Security",
            //"Notifications",
            "Support",
            "Terms of Service",
            "Privacy Policy",
          ]}
        />
      </div>

      <div className="border-b"></div>

      <div className={`pt-6 ${getMargin()}`}>
        <div>
          {tabValue === 0 && <EditProfile />}
          {/* {tabValue === 1 && <Subscriptions />} */}
          {tabValue === 1 && <Security />}
          {tabValue === 2 && <Support />}
          {tabValue === 3 && <TermsOfService />}
          {tabValue === 4 && <PrivacyPolicy />}
        </div>
      </div>
    </div>
  );
}

export default Settings;
