import React from "react";
import IPanel from "../../../../Library/Panel/IPanel";

import { ArrowRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatEventMapping,
  getAttributeColor,
  getIconForAttribute,
} from "../../../../Constants/Constants";
import IReputationBar from "../../../../Library/ReputationBar/IReputationBar";
import { MembersIcon } from "../../../../Icons/Icons";
import { useScreenSize } from "../../../../Context/ScreenContext";

function EventDetails({ event, group, attendeesList }: any) {
  let navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const getGender = () => {
    switch (event?.requirements?.gender) {
      case "NONE":
        return "N/A";
      case "MALE":
        return "MALE ONLY";
      case "FEMALE":
        return "FEMALE ONLY";
    }
  };

  return (
    <div className="grid grid-cols-3 xl:grid-cols-3 gap-5">
      <div className="col-span-3 xl:col-span-2">
        <IPanel height={`${isMobile ? "h-[360px]" : "h-[515px]"}`}>
          <div>
            {event && (
              <div className="flex justify-between mb-[12px] items-center">
                <div
                  style={{
                    fontFamily: "Satoshi-Bold",
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontStyle: "normal",
                    letterSpacing: "-0.5",
                  }}
                >
                  {event?.name}
                </div>

                <div className="flex items-center bg-black rounded-full py-[4px] px-[12px]">
                  <div
                    className="mr-1 text-white"
                    style={{
                      fontFamily: "Satoshi-Bold",
                      fontSize: "10px",
                      lineHeight: "12px",
                      letterSpacing: "0.6px",
                    }}
                  >
                    {event?.maxAttendees === 0
                      ? Object.keys(attendeesList)?.length
                      : Object.keys(attendeesList)?.length +
                        " / " +
                        event?.maxAttendees}
                  </div>
                  <MembersIcon stroke={"white"} />
                </div>
              </div>
            )}

            <div className=" flex mb-[24px]">
              <span
                className=" mr-1 rounded-full  px-[12px] py-[4px]  text-white bg-black"
                style={{
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                }}
              >
                {event?.requirements?.privacy === "PRIVATE"
                  ? "PRIVATE"
                  : event?.requirements?.privacy === "RESTRICTED"
                  ? "RESTRICTED"
                  : "PUBLIC"}
              </span>

              <span
                className=" rounded-full mr-1  px-[12px] py-[4px] text-white "
                style={{
                  backgroundColor: getAttributeColor(
                    event?.requirements?.attribute,
                    0.2,
                  ),
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                }}
              >
                <span
                  style={{
                    color: getAttributeColor(event?.requirements?.attribute),
                  }}
                >
                  {formatEventMapping(event?.requirements?.attribute)}
                </span>
              </span>
              <span
                className=" rounded-full mr-1  px-[12px] py-[4px] text-white "
                style={{
                  backgroundColor: getAttributeColor(
                    event?.requirements?.attribute,
                    0.2,
                  ),
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                }}
              >
                <span
                  style={{
                    color: getAttributeColor(event?.requirements?.attribute),
                  }}
                >
                  {formatEventMapping(event?.category)}
                </span>
              </span>
            </div>

            <div className=" flex mb-[24px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_428_7576)">
                  <path
                    d="M17.5 8.3335C17.5 14.1668 10 19.1668 10 19.1668C10 19.1668 2.5 14.1668 2.5 8.3335C2.5 6.34437 3.29018 4.43672 4.6967 3.0302C6.10322 1.62367 8.01088 0.833496 10 0.833496C11.9891 0.833496 13.8968 1.62367 15.3033 3.0302C16.7098 4.43672 17.5 6.34437 17.5 8.3335Z"
                    stroke="#323439"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 10.8335C11.3807 10.8335 12.5 9.71421 12.5 8.3335C12.5 6.95278 11.3807 5.8335 10 5.8335C8.61929 5.8335 7.5 6.95278 7.5 8.3335C7.5 9.71421 8.61929 10.8335 10 10.8335Z"
                    stroke="#323439"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_428_7576">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div
                className="text-[#7E858B] ml-1"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontStyle: "normal",
                }}
              >
                {event?.address}
              </div>
            </div>
            <div className=" flex mb-[24px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.3333 1.66699V5.00033M6.66667 1.66699V5.00033M2.5 8.33366H17.5M4.16667 3.33366H15.8333C16.7538 3.33366 17.5 4.07985 17.5 5.00033V16.667C17.5 17.5875 16.7538 18.3337 15.8333 18.3337H4.16667C3.24619 18.3337 2.5 17.5875 2.5 16.667V5.00033C2.5 4.07985 3.24619 3.33366 4.16667 3.33366Z"
                  stroke="#323439"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div
                className="text-[#7E858B] ml-[8px]"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontStyle: "normal",
                }}
              >
                {formatDate(event.startTime, isMobile)} -{" "}
                {formatDate(event.endTime, isMobile)}
              </div>
            </div>

            <div
              className={` overflow-y-auto whitespace-pre-wrap flex-grow text-[#323439] `}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                lineHeight: "20px",
                fontStyle: "normal",
              }}
            >
              {event?.description}
            </div>
          </div>
        </IPanel>
      </div>

      <div className="col-span-3 xl:col-span-1 flex flex-col gap-5">
        <IPanel height="160px">
          <div
            className="flex items-center "
            onClick={() => navigate(`/groups/${event?.host?.id}`)}
          >
            <div>
              <img
                src={event?.host?.photo}
                alt={``}
                style={{ borderRadius: "15px", objectFit: "cover" }}
                className="w-[30px] h-[30px] mr-[8px] mb-[8px]"
              />
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
                whiteSpace: "nowrap", // Keeps the text on a single line
                overflow: "hidden", // Ensures content that overflows is hidden
                textOverflow: "ellipsis", // Adds an ellipsis to signify truncated text
                maxWidth: "100%",
              }}
            >
              {event?.host?.name}
            </div>
          </div>

          <div className=" mb-[24px]">
            <IReputationBar
              reputation={event?.host?.reputation}
              color="regal-blue"
            />
          </div>
          <div
            className="flex items-center "
            onClick={() => navigate(`/profile/${event?.organizer?.id}`)}
          >
            <div>
              <img
                src={event?.organizer?.photo}
                alt={``}
                style={{ borderRadius: "15px", objectFit: "cover" }}
                className="w-[30px] h-[30px] mr-2  mb-[8px]"
              />
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {event?.organizer?.name}
            </div>
          </div>
          <div>
            <IReputationBar
              reputation={event?.organizer?.reputation}
              color="regal-blue"
            />
          </div>
        </IPanel>

        <IPanel height="h-[220px]">
          <div
            style={{
              fontFamily: "Satoshi-bold",
              fontSize: "10px",
              lineHeight: "12px",
              letterSpacing: "0.6px",
              color: "#323439",
            }}
            className="mb-[16px]"
          >
            REQUIREMENTS
          </div>
          <div>
            <div>Age</div>
            <div
              className=" mb-[16px]"
              style={{
                fontFamily: "Satoshi-regular",
                fontSize: "10px",
                lineHeight: "12px",
                letterSpacing: "0.6px",
                color: "#323439",
              }}
            >
              {event?.requirements?.minimumAge} -{" "}
              {event?.requirements?.maximumAge}
            </div>
          </div>
          <div className="pb-[16px]">
            <div>Gender</div>
            <div
              style={{
                fontFamily: "Satoshi-regular",
                fontSize: "10px",
                lineHeight: "12px",
                letterSpacing: "0.6px",
                color: "#323439",
              }}
            >
              {getGender()}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div
                className="font-medium capitalize"
                style={{
                  color: getAttributeColor(event?.requirements.attribute),
                }}
              >
                {event?.requirements?.attribute?.toLowerCase()}
              </div>
              <div
                className="text-[#7E858B]"
                style={{
                  fontFamily: "Satoshi-regular",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                  color: "#323439",
                }}
              >
                LEVEL {event?.requirements?.level}
              </div>
            </div>
            <div
              style={{
                color: getAttributeColor(event?.requirements.attribute),
                borderColor: getAttributeColor(
                  event?.requirements.attribute,
                  0.2,
                ),
                backgroundColor: getAttributeColor(
                  event?.requirements.attribute,
                  0.2,
                ),
              }}
              className="border px-2 py-2 rounded flex items-center justify-center w-[50px]" // Added flex centering
            >
              {getIconForAttribute(event?.requirements.attribute)}
            </div>
          </div>
        </IPanel>

        <IPanel
          height="h-[20px] " // Adjust this value to ensure there's enough space to center the contents vertically.
          onPanelClick={() => {
            navigate(`/events/${event.id}/attendees`);
          }}
        >
          <div className="flex justify-between items-center h-full ">
            {" "}
            {/* h-full to stretch to the height of IPanel */}
            <span
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
              }}
            >
              {Object.keys(attendeesList)?.length}
              {`${
                Object.keys(attendeesList)?.length === 1
                  ? " Attendee"
                  : " Attendees"
              }`}
            </span>
            <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        </IPanel>
      </div>
    </div>
  );
}

export default EventDetails;
