// connectionService.ts
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useConnectionService = () => {
  const axiosInstance = useAxios();

  const addConnection = async (userId: any) => {
    const response = await axiosInstance.post(
      `/user/${userId}/connect`,
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const acceptConnectionRequest = async (userId: any) => {
    const response = await axiosInstance.put(
      `/user/connections/requests/${userId}`,
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const declineConnectionRequest = async (userId: any) => {
    const response = await axiosInstance.delete(
      `/user/connections/requests/${userId}`,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const removeConnection = async (userId: any) => {
    const response = await axiosInstance.delete(`/user/connections/${userId}`, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const getConnections = async () => {
    const response = await axiosInstance.get("/user/connections");
    return response.data;
  };

  return {
    addConnection,
    acceptConnectionRequest,
    declineConnectionRequest,
    removeConnection,
    getConnections,
  };
};
