// useUserService.js
import { useAxios } from "../../Context/AxiosContext";

export const useUserService = () => {
  const axiosInstance = useAxios();

  const fetchUserProfile = async () => {
    const response = await axiosInstance.get("/user/profile");

    return response.data;
  };

  const fetchUserHome = async () => {
    const response = await axiosInstance.get("/user/home");
    return response.data;
  };

  const fetchUser = async (id: any) => {
    const response = await axiosInstance.get(`/user/${id}`);
    return response.data;
  };

  const updateProfilePicture = async (file: any) => {
    const response = await axiosInstance.put("/user/photo", file);
    return response.data;
  };

  const updateProfileInfo = async (data: any) => {
    const response = await axiosInstance.put("/user", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const updateUserSettings = async (data: any) => {
    const payload = {
      notificationSettings: data,
    };
    const response = await axiosInstance.put("/user/settings", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const createUser = async (user: any) => {
    const response = await axiosInstance.post("/user", user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const deleteAccount = async () => {
    const response = await axiosInstance.delete("/user", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  return {
    fetchUserProfile,
    fetchUserHome,
    fetchUser,
    updateProfilePicture,
    updateProfileInfo,
    updateUserSettings,
    createUser,
    deleteAccount,
  };
};
