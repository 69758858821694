import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import ICarousel from "../../../../Library/Carousel/ICarousel";
import IButton from "../../../../Library/Button/IButton";
import IEventPanel from "../../../../Library/EventPanel/IEventPanel";
import GroupDetails from "../GroupDetails/GroupDetails";
import { useParams } from "react-router-dom";
import { useDash } from "../../../../Context/DashboardContext";

import { useNavigate } from "react-router-dom";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../../Context/ScreenContext";
import {
  MARGIN,
  TOAST_MESSAGES,
  calculateAge,
} from "../../../../Constants/Constants";
import { useChat } from "../../../../Context/ChatContext";
import Toast from "../../../../Library/Toast/Toast";
import ConfirmationDialog from "../../../../Library/ConfirmationDialog/ConfirmationDialog";
import { useGroupService } from "../../../../Services/GroupService/GroupService";
import { useConnectionService } from "../../../../Services/ConnectionsService/ConnectionsService";
function Group() {
  const { groupId } = useParams();
  const { user, joinGroup, leaveGroup, updateUserFields } = useDash();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { getGroupPage, leaveCurrentGroup, joinCurrentGroup } =
    useGroupService();
  const { addConnection } = useConnectionService();
  const navigate = useNavigate();
  const [group, setGroup] = useState<any>();
  const [groupPicture] = useState<any>();
  const [organizer, setOrganizer] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [, setUpcomingEvents] = useState<any>();
  const [organizerId, setOrganizerId] = useState<any>();
  const [membersList, setMembersList] = useState<any>();
  const { addGroupToWebSocket, removeGroupAndCloseWebSocket } = useChat();
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>();
  const [buttonMessage, setButtonMessage] = useState<any>();
  const [groupPage, setGroupPage] = useState<any>();

  const [joinedGroup, setJoinedGroup] = useState(false);
  const [showModal, setShowModal] = useState<any>(false);
  const { setUnreadMessagesCount, unreadMessagesCount } = useChat();

  const fetchGroupData = async () => {
    try {
      const groupPage = await getGroupPage(groupId);

      setGroupPage(groupPage);
      setGroup(groupPage?.group);
      setOrganizerId(groupPage?.group?.organizer?.id);
      setOrganizer(groupPage?.organizer);
      setUpcomingEvents(groupPage?.upcomingEvents);

      const membersObject = groupPage?.group?.members;
      const membersArray = Object.keys(membersObject).map((key) => ({
        id: key,
        ...membersObject[key],
      }));

      setMembersList(membersArray);
      setJoinedGroup(membersArray?.includes(user?.id));
    } catch (error) {}
  };

  useEffect(() => {
    // This will update `joinedGroup` whenever `groupPage` or `user` changes.
    const isMember = user?.id
      ? user.id in (groupPage?.group?.members || {})
      : false;
    setJoinedGroup(isMember);
  }, [groupPage, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGroupData();
      } catch (error) {}
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const handleJoinOrLeaveGroup = async () => {
    if (
      group.requirements.privacy === "RESTRICTED" &&
      !user?.connections?.includes(group.organizer.id) &&
      !user?.pendingConnectionRequests?.includes(group.organizer.id) &&
      !user.joinedGroups.includes(groupId)
    ) {
      //setShowModal(true);
      addNewConnection();
    }

    if (
      group.requirements.privacy === "RESTRICTED" &&
      user?.pendingConnectionRequests?.includes(group.organizer.id)
    ) {
      setMessage("Restricted: Connection request already sent");
      setIsError(true);
    }

    if (
      group.requirements.privacy === "PUBLIC" ||
      group.requirements.privacy === "PRIVATE" ||
      (group.requirements.privacy === "RESTRICTED" &&
        user.connections.includes(group.organizer.id)) ||
      (group.requirements.privacy === "RESTRICTED" &&
        !user.connections.includes(group.organizer.id) &&
        user.joinedGroups.includes(groupId))
    ) {
      try {
        user?.joinedGroups?.includes(groupId)
          ? await leaveCurrentGroup(groupId)
          : await joinCurrentGroup(groupId);

        const attemptingToJoin = !user?.joinedGroups?.includes(groupId);

        if (!attemptingToJoin) {
          console.log(membersList);
          const updatedMembersList = membersList.filter(
            (member: any) => member.id !== user?.id,
          );

          removeGroupAndCloseWebSocket(groupId);

          setMembersList(updatedMembersList);
          leaveGroup(groupId);
          const newCounts: any = { ...unreadMessagesCount };
          groupId ? delete newCounts[groupId] : console.log("");
          setUnreadMessagesCount(newCounts);
          setJoinedGroup(false);
          setMessage(TOAST_MESSAGES.LEAVE_GROUP.SUCCESS); // Use a specific success message for leaving
          setIsError(false);
        } else {
          // User is joining the group
          checkIfPublicOrRestricted(); // This function likely handles adding the user to the group
          // Use a specific success message for joining
          setIsError(false);
        }
      } catch (error) {
        setMessage("Something went wrong");
        setIsError(true);
      }
    }
  };

  const checkIfPublicOrRestricted = () => {
    if (
      group.requirements.privacy === "PUBLIC" ||
      group.requirements.privacy === "RESTRICTED"
    ) {
      joinGroup(groupId);
      setMembersList([...membersList, user?.id]);
      addGroupToWebSocket(group);
      setJoinedGroup(true);
      setMessage(TOAST_MESSAGES.JOIN_GROUP.SUCCESS);
    } else {
      const updatedPendingGroupRequests = [
        ...user.pendingGroupRequests,
        groupId,
      ];
      setMessage("Group request sent");
      updateUserFields({
        pendingGroupRequests: updatedPendingGroupRequests,
      });
    }
  };

  const addNewConnection = async () => {
    try {
      await addConnection(group.organizer.id);

      const updatedPendingConnectionRequests = [
        ...user.pendingConnectionRequests,
        group.organizer.id,
      ];
      updateUserFields({
        pendingConnectionRequests: updatedPendingConnectionRequests,
      });
      setShowModal(false);
      setMessage("Connection request sent");
      setIsError(false);
    } catch (error) {}
  };

  useEffect(() => {
    getGroupStatus();
  });

  const eligibleToJoin = () => {
    let eligibility = true;
    const age = calculateAge(user?.dateOfBirth);
    if (
      age < group?.requirements?.minimumAge ||
      age > group?.requirements?.maximumAge
    ) {
      eligibility = false;
    }

    if (
      user?.gender !== group?.requirements?.gender &&
      group?.requirements?.gender !== "NONE"
    ) {
      eligibility = false;
    }
    if (
      group?.requirements?.level >
      user?.attributes[group?.requirements?.attribute]?.level
    ) {
      eligibility = false;
    }
    return eligibility;
  };

  const getGroupStatus = () => {
    if (user?.joinedGroups?.includes(groupId)) {
      setButtonMessage("Leave");
      setJoinedGroup(true);
    }

    if (
      (!user?.joinedGroups?.includes(groupId) &&
        !user?.pendingGroupRequests?.includes(groupId) &&
        group?.requirements?.privacy === "PUBLIC") ||
      (group?.requirements?.privacy === "RESTRICTED" &&
        user?.connections?.includes(organizerId) &&
        !user.joinedGroups.includes(groupId)) ||
      (group?.requirements?.privacy === "PRIVATE" &&
        !user.joinedGroups.includes(groupId))
    ) {
      group?.requirements?.privacy === "PRIVATE"
        ? setButtonMessage("Request to Join")
        : setButtonMessage("Join");
    }

    if (user?.pendingGroupRequests?.includes(groupId)) {
      setButtonMessage("Group Request Sent");
    }

    if (
      group?.requirements?.privacy === "RESTRICTED" &&
      !user?.connections?.includes(groupPage?.group?.organizer?.id) &&
      !user.joinedGroups.includes(groupId)
    ) {
      setButtonMessage("Connect With Organizer");
    }

    if (
      group?.requirements?.privacy === "RESTRICTED" &&
      user?.pendingConnectionRequests?.includes(groupPage?.group?.organizer?.id)
    ) {
      setButtonMessage("Connection Request Sent");
    }

    if (!eligibleToJoin()) {
      setButtonMessage("Not Eligible");
    }
  };

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.DISCOVER : MARGIN.LARGE.DISCOVER;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <ConfirmationDialog
        show={showModal}
        message={"Restricted"}
        subMessage={"This group is restricted to connections only"}
        confirm={`Connect with ${group.organizer.name}`}
        onClose={() => setShowModal(false)}
        onConfirm={() => addNewConnection()}
      />
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <div
        className={`flex justify-between items-center ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <div className="truncate mr-[10px]">
          <ILabel text={group?.name} />
        </div>

        <IButton
          text={buttonMessage}
          onClick={handleJoinOrLeaveGroup}
          bgColor={
            user?.id === organizerId ||
            !eligibleToJoin() ||
            buttonMessage === "Group Request Sent" ||
            (group?.requirements?.privacy === "RESTRICTED" &&
              user?.pendingConnectionRequests?.includes(
                groupPage?.group?.organizer?.id,
              ))
              ? "bg-white"
              : "bg-regal-blue"
          }
          textColor={
            user?.id === organizerId ||
            !eligibleToJoin() ||
            buttonMessage === "Group Request Sent" ||
            (group?.requirements?.privacy === "RESTRICTED" &&
              user?.pendingConnectionRequests?.includes(
                groupPage?.group?.organizer?.id,
              ))
              ? "text-black"
              : "text-white"
          }
          className="px-6 "
          disabled={
            user?.id === organizerId ||
            !eligibleToJoin() ||
            buttonMessage === "Group Request Sent" ||
            (group?.requirements?.privacy === "RESTRICTED" &&
              user?.pendingConnectionRequests?.includes(
                groupPage?.group?.organizer?.id,
              ))
          }
        />
      </div>

      <div className="w-full  pb-4">
        <ICarousel imageUrls={[group?.photo]} />
      </div>

      <div className="pb-4">
        <GroupDetails
          group={group}
          organizer={organizer}
          organizerId={organizerId}
          groupId={groupId}
          groupPicture={groupPicture}
          membersList={membersList}
        />
      </div>

      {(groupPage?.group?.requirements?.privacy === "PUBLIC" ||
        joinedGroup ||
        (groupPage?.group?.requirements?.privacy === "RESTRICTED" &&
          user.connections.includes(groupPage.group.organizer.id))) && (
        <div className="mb-[1.85vh]">
          <IEventPanel
            title="Upcoming Events"
            events={groupPage?.upcomingEvents}
            showAll={true}
            onEventClick={(eventName, eventId) => {
              navigate(`/events/${eventId}`);
            }}
            marginTop="mt-0"
            subHeader={`View upcoming events for ${groupPage.group.name}`}
          />
        </div>
      )}

      {(groupPage?.group?.requirements?.privacy === "PUBLIC" ||
        joinedGroup) && (
        <div className="mb-[1.85vh]">
          <IEventPanel
            title="Event History"
            events={groupPage?.eventHistory}
            showAll={true}
            onEventClick={(eventName, eventId) => {
              navigate(`/events/${eventId}`);
            }}
            marginTop="mt-0"
            subHeader={`View event history for ${groupPage.group.name}`}
          />
        </div>
      )}
    </div>
  );
}

export default Group;
