// groupService.ts
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useGroupService = () => {
  const axiosInstance = useAxios();

  const createGroup = async (group: any) => {
    const response = await axiosInstance.post("/group", group, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const getGroupDashboard = async (groupId: any) => {
    const response = await axiosInstance.get(`/group/${groupId}/dashboard`);
    return response.data;
  };

  const getGroupDiscovery = async () => {
    const response = await axiosInstance.get("/discovery");
    return response.data;
  };

  const getGroupEvents = async (id: any, status: any) => {
    const response = await axiosInstance.get(`/group/${id}/events/${status}`);
    return response.data;
  };

  const getGroupMembers = async (id: any) => {
    const response = await axiosInstance.get(`/group/${id}/members`);
    return response.data;
  };

  const getGroupPage = async (id: any) => {
    const response = await axiosInstance.get(`/group/${id}/page`, {
      withCredentials: true,
    });
    return response.data;
  };

  const getGroupHome = async () => {
    const response = await axiosInstance.get("/group/home");
    return response.data;
  };

  const updateGroupPicture = async (id: any, image: any) => {
    const response = await axiosInstance.put(`/group/${id}/photo`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  const getUsersGroups = async (id: any) => {
    const response = await axiosInstance.get(`/user/${id}/groups`);
    return response.data;
  };

  const leaveCurrentGroup = async (id: any) => {
    const response = await axiosInstance.delete(`/group/${id}/members`);
    return response.data;
  };

  const joinCurrentGroup = async (id: any) => {
    const response = await axiosInstance.put(`/group/${id}/members`);
    return response.data;
  };

  const editGroup = async (id: any, group: any) => {
    const response = await axiosInstance.put(`/group/${id}`, group, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const getGroupPhotoGallery = async (id: any) => {
    const response = await axiosInstance.get(`/group/${id}/gallery`);
    return response.data;
  };

  const getGroupDiscoveryShowAll = async (url: any) => {
    const response = await axiosInstance.get(`/discovery/${url}`);
    return response.data;
  };

  const deleteGroup = async (groupId: any) => {
    const response = await axiosInstance.delete(`/group/${groupId}`);
    return response.data;
  };

  const transferGroup = async (groupId: any, userId: any) => {
    const response = await axiosInstance.post(
      `/group/${groupId}/transfer/${userId}`,
    );
    return response.data;
  };

  const getEventHistory = async (groupId: any) => {
    const response = await axiosInstance.get(`/group/${groupId}/event-history`);
    return response.data;
  };

  const acceptGroupRequest = async (groupId: any, userId: any) => {
    const response = await axiosInstance.put(
      `/group/${groupId}/members/requests/${userId}`,
    );
    return response.data;
  };

  const declineGroupRequest = async (groupId: any, userId: any) => {
    const response = await axiosInstance.delete(
      `/group/${groupId}/members/requests/${userId}`,
    );
    return response.data;
  };

  return {
    createGroup,
    getGroupDashboard,
    getGroupDiscovery,
    getGroupEvents,
    getGroupMembers,
    getGroupPage,
    getGroupHome,
    updateGroupPicture,
    getUsersGroups,
    leaveCurrentGroup,
    joinCurrentGroup,
    editGroup,
    getGroupPhotoGallery,
    getGroupDiscoveryShowAll,
    deleteGroup,
    transferGroup,
    getEventHistory,
    acceptGroupRequest,
    declineGroupRequest,
  };
};
