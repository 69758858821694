// GroupCreateValidations.tsx

export const validateName = (name: string): string => {
  if (name?.trim() === "") {
    return "Required";
  }

  // // Regular expression to match valid name characters (letters, space, apostrophe, hyphen)
  // // Adjust the regex as needed based on what characters you want to allow
  // const regex = /^[a-zA-Z\s'-]+$/;

  // // Test the name against the regex
  // if (!regex.test(name)) {
  //   return "Invalid name (letters, ', - only).";
  // }

  // If all checks pass, return an empty string indicating no error
  return "";
};

export const validateDescription = (description: string): string => {
  return description?.trim() === "" ? "Required" : "";
};

export const validateCategory = (category: any): string => {
  return !category ? "Required" : "";
};

export const validateGroup = (group: any): string => {
  return group?.trim() === "" ? "Required" : "";
};

export const validateAddress = (address: any): string => {
  return address?.trim() === "" ? "Required" : "";
};

export const validateStartDate = (startDate: any): string => {
  if (startDate === "") {
    return "Required";
  }

  const currentDate = new Date();
  const selectedStartDate = new Date(startDate);

  if (selectedStartDate <= currentDate) {
    return "Start date and time must be in the future";
  }

  return "";
};

export const validateEndDate = (endDate: any, startDate: any): string => {
  if (endDate === "") {
    return "Required";
  }

  const selectedStartDate = new Date(startDate);
  const selectedEndDate = new Date(endDate);

  if (selectedEndDate <= selectedStartDate) {
    return "End date and time must be after start date and time";
  }

  return "";
};

export const validateMaxAttendees = (attendees: any): string => {
  return !attendees ? "Required" : "";
};

// Assuming this validator is added to your GroupCreateValidations.tsx

export const validateImageFile = (
  image: File | string | null | undefined,
): string => {
  // Checks if image is not provided or is an empty string (for URL case)
  return !image || (typeof image === "string" && image.trim() === "")
    ? "Required"
    : "";
};
