import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { useDash } from "./DashboardContext";
import { useGroupService } from "../Services/GroupService/GroupService";
import { useChatService } from "../Services/ChatService/ChatService";
import { useLocation } from "react-router-dom";

type ChatHistoryType = any;
type GroupType = any;

interface ChatContextType {
  groups: GroupType[];
  chatHistories: { [key: string]: ChatHistoryType[] };
  addGroupToWebSocket: (group: GroupType) => void;
  removeGroupAndCloseWebSocket: any;
  unreadMessagesCount: any;
  setUnreadMessagesCount: any;
  chatTimestamps: any;
  lastUpdateReason: any;
  setLastUpdateReason: any;
  setChatHistories: any;
}

const ChatContext = createContext<ChatContextType>({
  groups: [],
  chatHistories: {},
  addGroupToWebSocket: () => {},
  removeGroupAndCloseWebSocket: () => {},
  unreadMessagesCount: {},
  setUnreadMessagesCount: "",
  chatTimestamps: {},
  lastUpdateReason: "",
  setLastUpdateReason: "",
  setChatHistories: "",
});

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useDash();
  const [lastUpdateReason, setLastUpdateReason] = useState("message"); // 'like' or 'message'
  const { getChatHistory } = useChatService();
  const { getUsersGroups } = useGroupService();
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [chatHistories, setChatHistories] = useState<{
    [key: string]: ChatHistoryType[];
  }>({});

  const [chatTimestamps, setChatTimestamps] = useState<{
    [key: string]: string;
  }>({});
  const stompClientRef = useRef<any>(null);
  const subscriptionsRef = useRef<{
    [key: string]: { unsubscribe: () => void };
  }>({});
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<any>({});

  const location = useLocation();

  const getUnReadCount = (allMessages: any) => {
    let count = 0;
    allMessages.map((message: any) => {
      if (!message.readBy.includes(user.id)) {
        count++;
      }
      return count;
    });
    return count;
  };

  const addPersonToGroup = (groupId: any, userId: any) => {
    setGroups((prevGroups) => {
      return prevGroups.map((group) => {
        if (group.id === groupId) {
          const members = group.members ?? {};
          if (!(userId in members)) {
            // Only add the member if they are not already in the group
            return {
              ...group,

              memberCount: group.memberCount + 1, // Increment the member count
            };
          }
        }
        return group;
      });
    });
  };

  const removePersonFromGroup = (groupId: any, userId: any) => {
    setGroups((prevGroups) => {
      return prevGroups.map((group) => {
        if (group.id === groupId) {
          // Decrement the member count directly if conditions are met
          return {
            ...group,
            memberCount: Math.max(0, group.memberCount - 1), // Ensures memberCount does not go negative
          };
        }
        return group;
      });
    });
  };

  useEffect(() => {
    const fetchChatHistories = async (groupsData: any) => {
      if (!groupsData || !Array.isArray(groupsData)) {
        return;
      }

      const newHistories: any = {};
      const newUnreadCounts: any = {};
      const chatTimestamps: any = {};
      for (const group of groupsData) {
        const joinedDate = group?.members[user.id].joinedDate;

        try {
          const history = await getChatHistory(group.id, 0, joinedDate);

          newHistories[group.id] = history.content.reverse();

          chatTimestamps[group.id] =
            history.content.length > 0 &&
            history.content[history.content.length - 1].timestamp
              ? history.content[history.content.length - 1].timestamp
              : group.creationDate;

          const unreadCount = getUnReadCount(history.content);
          newUnreadCounts[group.id] = unreadCount;
        } catch (error) {}
      }

      setChatTimestamps(chatTimestamps);
      sortGroups(groupsData, chatTimestamps);
      setChatHistories(newHistories);
      setUnreadMessagesCount(newUnreadCounts);
    };

    const fetchGroups = async () => {
      if (!user?.id) return;

      try {
        const groupsData = await getUsersGroups(user.id);

        await fetchChatHistories(groupsData);

        if (user && groupsData.length > 0) {
          if (!stompClientRef.current) {
            const socket = new SockJS(
              `${
                process.env.REACT_APP_API_URL
              }/ws/chat?token=${localStorage.getItem("token")}`,
            );
            stompClientRef.current = Stomp.over(socket);
            stompClientRef.current.connect({}, () => {
              groupsData.forEach((group: any) => {
                const subscription = stompClientRef.current?.subscribe(
                  `/topic/chat/${group.id}`,
                  (message: any) => {
                    const chatMessage = JSON.parse(message.body);
                    updateChatTimeStamp(chatMessage);

                    switch (chatMessage.type) {
                      case "TEXT":
                        console.log(chatMessage);
                        updateChatHistory(group.id, chatMessage);
                        setLastUpdateReason("message");
                        break;

                      case "JOIN_GROUP":
                        updateChatHistory(group.id, chatMessage);
                        addPersonToGroup(group.id, chatMessage.senderId);
                        break;

                      case "LEAVE_GROUP":
                        removePersonFromGroup(group.id, chatMessage.senderId);
                        updateChatHistory(group.id, chatMessage);
                        break;

                      case "TRANSFER_GROUP":
                        updateChatHistory(group.id, chatMessage);
                        break;

                      case "IMAGE":
                        updateChatHistory(group.id, chatMessage);
                        break;

                      case "LIKE":
                        updateLikes(group.id, chatMessage);
                        setLastUpdateReason("like");
                        break;

                      case "CREATE_EVENT":
                        updateChatHistory(group.id, chatMessage);
                        break;

                      case "JOIN_EVENT":
                        updateChatHistory(group.id, chatMessage);
                        break;

                      case "LEAVE_EVENT":
                        updateChatHistory(group.id, chatMessage);
                        break;
                    }
                  },
                );

                if (subscription) {
                  subscriptionsRef.current[group.id] = subscription;
                }
              });
            });
          }
        }
      } catch (error) {
        console.error("Failed to fetch groups", error);
      }
    };

    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.subscription?.tier]);

  const addGroupToWebSocket = async (group: any) => {
    const userId = user.id; // This should be the current user's ID
    const currentDateTime = new Date().toISOString(); // Current date-time in ISO format

    group.members = {
      ...group.members,
      [userId]: {
        joinedDate: currentDateTime,
      },
    };

    setChatTimestamps((prevChatTimestamps) => ({
      ...prevChatTimestamps,
      [group.id]: currentDateTime,
    }));

    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups, group];
      return updatedGroups;
    });

    try {
      const history = await getChatHistory(
        group.id,
        0,
        new Date(Date.now()).toISOString(),
      );

      setChatHistories((prevHistories) => {
        const updatedHistories = {
          ...prevHistories,
          [group.id]: history.content.reverse(),
        };
        return updatedHistories;
      });
    } catch (error) {
      console.error("Error fetching existing chat history for group:", error);
    }

    if (stompClientRef.current && stompClientRef.current.connected) {
      const subscription = stompClientRef.current.subscribe(
        `/topic/chat/${group?.id}`,
        (message: any) => {
          const chatMessage = JSON.parse(message.body);
          updateChatHistory(group?.id, chatMessage);
        },
      );
      subscriptionsRef.current[group.id] = subscription;
    } else {
      const socket = new SockJS(
        `${process.env.REACT_APP_API_URL}/ws/chat?token=${localStorage.getItem(
          "token",
        )}`,
      );
      stompClientRef.current = Stomp.over(socket);

      stompClientRef.current.connect({}, () => {
        const subscription = stompClientRef.current.subscribe(
          `/topic/chat/${group?.id}`,
          (message: any) => {
            const chatMessage = JSON.parse(message.body);
            updateChatHistory(group?.id, chatMessage);
          },
        );
        subscriptionsRef.current[group.id] = subscription;
      });
    }
  };

  const removeGroupAndCloseWebSocket = (groupId: string) => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group.id !== groupId),
    );
    setChatHistories((prevHistories) => {
      const newHistories = { ...prevHistories };
      delete newHistories[groupId];
      return newHistories;
    });

    if (stompClientRef.current && stompClientRef.current.connected) {
      const subscription = subscriptionsRef.current[groupId];

      if (subscription) {
        console.log("group closed");
        subscription.unsubscribe();
        console.log(`Unsubscribed from group ${groupId}`);

        delete subscriptionsRef.current[groupId];
      } else {
        console.log(`No active subscription found for group ${groupId}`);
      }
    }
  };

  const updateChatTimeStamp = (chatMessage: any) => {
    const groupId = chatMessage.groupId;
    const timestamp = chatMessage.timestamp;
    setChatTimestamps((prevChatTimestamps) => ({
      ...prevChatTimestamps,
      [groupId]: timestamp,
    }));
  };

  const sortGroups = (groups: any, chatTimestamps: any) => {
    const sorted: any = groups
      .map((group: any) => ({ ...group }))
      .sort((a: any, b: any) => {
        // Parse timestamps; default to 0 if unavailable or invalid
        const timeStampA = new Date(
          chatTimestamps[a.id] || new Date(a.creationDate),
        ).getTime();
        const timeStampB = new Date(
          chatTimestamps[b.id] || new Date(b.creationDate),
        ).getTime();

        return timeStampB - timeStampA; // Compare numerically
      });
    setGroups(sorted);
  };

  const updateChatHistory = (groupId: string, newMessage: any) => {
    const pathId = location.pathname.split("/")[2];

    if (newMessage.senderId !== user.id) {
      if (!newMessage.readBy.includes(user.id) && pathId !== groupId) {
        setUnreadMessagesCount((prevCounts: any) => {
          const currentCount = prevCounts[groupId] || 0;
          return {
            ...prevCounts,
            [groupId]: currentCount + 1,
          };
        });
      }
    }

    setChatHistories((prevHistories) => {
      const updatedHistory = [...(prevHistories[groupId] || []), newMessage];
      return { ...prevHistories, [groupId]: updatedHistory };
    });
  };

  const updateLikes = (groupId: any, newMessage: any) => {
    setChatHistories((prevHistories) => {
      const updatedMessages = prevHistories[groupId].map((message) => {
        if (message.id === newMessage.id) {
          return { ...message, likedBy: newMessage.likedBy };
        }
        return message;
      });

      return { ...prevHistories, [groupId]: updatedMessages };
    });
  };

  return (
    <ChatContext.Provider
      value={{
        groups,
        chatHistories,
        addGroupToWebSocket,
        removeGroupAndCloseWebSocket,
        unreadMessagesCount,
        setUnreadMessagesCount,
        chatTimestamps,
        lastUpdateReason,
        setLastUpdateReason,
        setChatHistories,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

export default ChatProvider;
