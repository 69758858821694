import React from "react";
import { getAttributeColor, toPascalCase } from "../../Constants/Constants";
import { useScreenSize } from "../../Context/ScreenContext";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  photo: string;
  age: number;
  level: number;
  points: number;
  topAttribute: string;
}

interface IUserTableProps {
  users: User[];
  onRowClick: (userId: string) => void;
}

const IUserTable: React.FC<IUserTableProps> = ({ users, onRowClick }) => {
  const { isMobile } = useScreenSize();
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse" as "collapse",
  };

  const thStyle = {
    textAlign: "left" as "left",
    padding: "8px",
    color: "#7E858B",
    fontFamily: "Satoshi-Bold",
    fontSize: "10px",
    lineHeight: "12px",
    fontStyle: "normal",
    letterSpacing: "0.06px",
  };

  const nameStyle = {
    textAlign: "left" as "left",
    paddingLeft: isMobile ? "8px" : "24px",
    color: "#7E858B",
    fontFamily: "Satoshi-Bold",
    fontSize: "10px",
    lineHeight: "12px",
    fontStyle: "normal",
    letterSpacing: "0.06px",
  };

  const tdStyle = {
    textAlign: "left" as "left",
    padding: "8px",
    fontFamily: "Satoshi-Regular",
    fontSize: "14px",
    lineHeight: "16px",
    fontStyle: "normal",
    paddingBottom: "15px",
    paddingTop: "15px",
  };

  const imgStyle = {
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    objectFit: "cover" as "cover",
    marginRight: "8px",
  };

  const tdContentStyle = {
    display: "flex", // Use flexbox to lay out children inline
    alignItems: "center", // Center children vertically within the td
    gap: "10px",
    paddingLeft: isMobile ? "" : "16px",
    // Add some space between the image and the text
  };

  return (
    <div
      className="rounded"
      style={{ width: "100%", overflowX: "auto", background: "white" }}
    >
      <table style={tableStyle}>
        <thead>
          <tr>
            <th className="text-xs" style={nameStyle}>
              Name
            </th>
            <th className="text-xs" style={thStyle}>
              Top Interest
            </th>

            <th className="text-xs" style={thStyle}>
              Level
            </th>
            <th className="text-xs" style={thStyle}>
              Age
            </th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "white" }}>
          {users?.map((user) => (
            <tr
              key={user.id}
              style={{
                borderBottom: "1px solid #DADEE5",
              }}
              onClick={() => onRowClick(user.id)}
            >
              <td style={tdStyle}>
                <div style={tdContentStyle}>
                  <img
                    src={user?.photo || "default-avatar.png"}
                    alt={`${user.firstName} ${user.lastName}`}
                    style={imgStyle}
                  />
                  <span className="truncate">{`${user.firstName} ${user.lastName}`}</span>
                </div>
              </td>
              <td style={tdStyle}>
                <span
                  className="py-1 px-3 rounded-full"
                  style={{
                    color: getAttributeColor(user.topAttribute),
                    backgroundColor: getAttributeColor(user.topAttribute, 0.2),
                    fontFamily: "Satoshi-Regular",
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontStyle: "normal",
                  }}
                >
                  {toPascalCase(user.topAttribute)}
                </span>
              </td>

              <td style={tdStyle}>{user.level}</td>
              <td style={tdStyle}>{user.age}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IUserTable;
