/* eslint-disable no-useless-escape */
export const validateEmail = (email: string): string => {
  if (email.trim() === "") {
    return "Required";
  }

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(email)) {
    return "Invalid email format";
  }

  return "";
};

export const validatePassword = (password: string): string => {
  if (password.trim() === "") {
    return "Required";
  }

  return "";
};

export const validatePasswordsMatch = (
  password: string,
  confirmPassword: string,
): string => {
  if (password.trim() !== confirmPassword.trim()) {
    return "Passwords do not match.";
  }
  return "";
};
