import React from "react";
import IPanel from "../../../../Library/Panel/IPanel";
import { ArrowRightIcon } from "@heroicons/react/solid";

import { useNavigate } from "react-router-dom";
import { getIconForAttribute } from "../../../../Constants/Constants";
import IReputationBar from "../../../../Library/ReputationBar/IReputationBar";
import { getAttributeColor } from "../../../../Constants/Constants";
import { MembersIcon } from "../../../../Icons/Icons";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { useDash } from "../../../../Context/DashboardContext";

const GroupDetails = ({ group, groupId, membersList }: any) => {
  let navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const { user } = useDash();

  const getGender = () => {
    switch (group?.requirements?.gender) {
      case "NONE":
        return "N/A";
      case "MALE":
        return "MALE ONLY";
      case "FEMALE":
        return "FEMALE ONLY";
    }
  };

  const showArrow = () => {
    if (group.requirements.privacy === "PUBLIC") {
      return true;
    }
    // Check if the user's ID is a key in the group.members object for PRIVATE groups
    if (
      group.requirements.privacy === "PRIVATE" &&
      group.members.hasOwnProperty(user.id)
    ) {
      return true;
    }

    if (
      group.requirements.privacy === "RESTRICTED" &&
      user.connections.includes(group.organizer.id)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="grid grid-cols-3 xl:grid-cols-3 gap-5">
      <div className="col-span-3 xl:col-span-2">
        <IPanel height={`${isMobile ? "h-[360px]" : "h-[515px]"}`}>
          <div>
            {group && (
              <div className="flex justify-between mb-[12px] items-center">
                <div
                  style={{
                    fontFamily: "Satoshi-Bold",
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontStyle: "normal",
                    letterSpacing: "-0.5",
                  }}
                >
                  {group?.name}
                </div>

                <div className="flex items-center bg-black rounded-full py-[4px] px-[12px]">
                  <div
                    className="mr-1 text-white"
                    style={{
                      fontFamily: "Satoshi-Bold",
                      fontSize: "10px",
                      lineHeight: "12px",
                      letterSpacing: "0.6px",
                    }}
                  >
                    {membersList?.length}
                  </div>
                  <MembersIcon stroke={"white"} />
                </div>
              </div>
            )}

            <div className=" flex mb-[24px]">
              <span
                className="mr-1 rounded-full px-[12px] py-[4px] text-white bg-black"
                style={{
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                }}
              >
                {group?.requirements?.privacy === "PRIVATE"
                  ? "PRIVATE"
                  : group?.requirements?.privacy === "RESTRICTED"
                  ? "RESTRICTED"
                  : "PUBLIC"}
              </span>

              <span
                className=" rounded-full mr-1  px-[12px] py-[4px] text-white "
                style={{
                  backgroundColor: getAttributeColor(
                    group?.requirements?.attribute,
                    0.2,
                  ),
                  fontFamily: "Satoshi-Bold",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                }}
              >
                <span
                  style={{
                    color: getAttributeColor(group?.requirements?.attribute),
                  }}
                >
                  {group?.requirements?.attribute}
                </span>
              </span>
            </div>

            <div className=" flex mb-[24px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_428_7576)">
                  <path
                    d="M17.5 8.3335C17.5 14.1668 10 19.1668 10 19.1668C10 19.1668 2.5 14.1668 2.5 8.3335C2.5 6.34437 3.29018 4.43672 4.6967 3.0302C6.10322 1.62367 8.01088 0.833496 10 0.833496C11.9891 0.833496 13.8968 1.62367 15.3033 3.0302C16.7098 4.43672 17.5 6.34437 17.5 8.3335Z"
                    stroke="#323439"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 10.8335C11.3807 10.8335 12.5 9.71421 12.5 8.3335C12.5 6.95278 11.3807 5.8335 10 5.8335C8.61929 5.8335 7.5 6.95278 7.5 8.3335C7.5 9.71421 8.61929 10.8335 10 10.8335Z"
                    stroke="#323439"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_428_7576">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div
                className="text-[#7E858B] ml-1"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontStyle: "normal",
                }}
              >
                {group?.city + ", " + group?.state}
              </div>
            </div>

            <div
              className={` overflow-y-auto whitespace-pre-wrap flex-grow text-[#323439] `}
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                lineHeight: "20px",
                fontStyle: "normal",
              }}
            >
              {group?.description}
            </div>
          </div>
        </IPanel>
      </div>

      <div className="col-span-3 xl:col-span-1 flex flex-col gap-5">
        <IPanel height="160px">
          <div
            className="flex items-center "
            onClick={() => navigate(`/groups/${group?.id}`)}
          >
            <div>
              <img
                src={group?.photo}
                alt={``}
                style={{ borderRadius: "15px", objectFit: "cover" }}
                className="w-[30px] h-[30px] mr-[8px] mb-[8px]"
              />
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
                whiteSpace: "nowrap", // Keeps the text on a single line
                overflow: "hidden", // Ensures content that overflows is hidden
                textOverflow: "ellipsis", // Adds an ellipsis to signify truncated text
                maxWidth: "100%",
              }}
            >
              {group?.name}
            </div>
          </div>

          <div className=" mb-[24px]">
            <IReputationBar reputation={group?.reputation} color="regal-blue" />
          </div>
          <div
            className="flex items-center "
            onClick={() => navigate(`/profile/${group?.organizer?.id}`)}
          >
            <div>
              <img
                src={group?.organizer?.photo}
                alt={``}
                style={{ borderRadius: "15px", objectFit: "cover" }}
                className="w-[30px] h-[30px] mr-2  mb-[8px]"
              />
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {group?.organizer?.name}
            </div>
          </div>
          <div>
            <IReputationBar
              reputation={group?.organizer?.reputation}
              color="regal-blue"
            />
          </div>
        </IPanel>

        <IPanel height="h-[220px]">
          <div
            style={{
              fontFamily: "Satoshi-bold",
              fontSize: "10px",
              lineHeight: "12px",
              letterSpacing: "0.6px",
              color: "#323439",
            }}
            className="mb-[16px]"
          >
            REQUIREMENTS
          </div>
          <div>
            <div>Age</div>
            <div
              className=" mb-[16px]"
              style={{
                fontFamily: "Satoshi-regular",
                fontSize: "10px",
                lineHeight: "12px",
                letterSpacing: "0.6px",
                color: "#323439",
              }}
            >
              {group?.requirements?.minimumAge} -{" "}
              {group?.requirements?.maximumAge}
            </div>
          </div>
          <div className="pb-[16px]">
            <div>Gender</div>
            <div
              style={{
                fontFamily: "Satoshi-regular",
                fontSize: "10px",
                lineHeight: "12px",
                letterSpacing: "0.6px",
                color: "#323439",
              }}
            >
              {getGender()}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div
                className="font-medium capitalize"
                style={{
                  color: getAttributeColor(group?.requirements.attribute),
                }}
              >
                {group?.requirements?.attribute?.toLowerCase()}
              </div>
              <div
                className="text-[#7E858B]"
                style={{
                  fontFamily: "Satoshi-regular",
                  fontSize: "10px",
                  lineHeight: "12px",
                  letterSpacing: "0.6px",
                  color: "#323439",
                }}
              >
                LEVEL {group?.requirements?.level}
              </div>
            </div>
            <div
              style={{
                color: getAttributeColor(group?.requirements.attribute),
                borderColor: getAttributeColor(
                  group?.requirements.attribute,
                  0.2,
                ),
                backgroundColor: getAttributeColor(
                  group?.requirements.attribute,
                  0.2,
                ),
              }}
              className="border px-2 py-2 rounded flex items-center justify-center w-[50px]" // Added flex centering
            >
              {getIconForAttribute(group?.requirements.attribute)}
            </div>
          </div>
        </IPanel>

        <IPanel
          height="h-[20px] " // Adjust this value to ensure there's enough space to center the contents vertically.
          onPanelClick={() => {
            if (group.requirements.privacy === "PUBLIC") {
              navigate(`/groups/${groupId}/members`);
            }

            if (
              group.requirements.privacy === "PRIVATE" &&
              group.members.hasOwnProperty(user.id)
            ) {
              navigate(`/groups/${groupId}/members`);
            }

            if (
              group?.requirements?.privacy === "RESTRICTED" &&
              user?.connections?.includes(group.organizer.id)
            ) {
              navigate(`/groups/${groupId}/members`);
            }
          }}
        >
          <div className="flex justify-between items-center h-full ">
            {" "}
            {/* h-full to stretch to the height of IPanel */}
            <span
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
              }}
            >
              {membersList?.length}
              {`${membersList?.length === 1 ? " Member" : " Members"}`}
            </span>
            {showArrow() && (
              <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </div>
        </IPanel>
      </div>
    </div>
  );
};

export default GroupDetails;
