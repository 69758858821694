import React from "react";
import { Listbox } from "@headlessui/react";
import IToolTip from "../ToolTip/IToolTip"; // Update the import path as necessary
import { ChevronDownIcon, ChevronUpIcon } from "../../Icons/Icons";

interface IDropdownProps {
  options: Array<{ value: any; label: any }>;
  placeholder?: string;
  onChange?: (value: string) => void;
  labelText: string;
  value: any;
  errorMessage?: string;
}

function IDropdown({
  options,
  placeholder,
  onChange,
  labelText,
  value,
  errorMessage,
}: IDropdownProps) {
  const handleValueChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="relative">
      <div style={{ display: "flex" }}>
        {" "}
        {/* Flex container for label and tooltip */}
        {labelText && (
          <label
            className="mb-1"
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "16px",
              lineHeight: "20px",
              fontStyle: "normal",
            }}
          >
            {labelText}
          </label>
        )}
        {/* <div className="ml-1">
          {errorMessage && <IToolTip message={errorMessage} />}
        </div> */}
      </div>

      <Listbox value={value} onChange={handleValueChange}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={` hover:border-blue-500 bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                options?.find((option) => option.value === value)
                  ? "text-black"
                  : "text-gray-400"
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "20px",
                fontStyle: "normal",
              }}
            >
              {options?.find((option) => option.value === value) ? (
                <span className="text-gray-900">
                  {options.find((option) => option.value === value)?.label}
                </span>
              ) : (
                <span
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "16px",
                    lineHeight: "2px",
                    fontStyle: "normal",
                  }}
                  className="text-gray-500"
                >
                  {placeholder}
                </span> // Ensure placeholder is text-gray-900
              )}
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Listbox.Button>

            <Listbox.Options className=" absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none text-xs">
              {options?.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className="py-2 pl-3 pr-10 cursor-pointer hover:bg-gray-100"
                >
                  {({ selected }: any) => (
                    <span
                      className={`block truncate`}
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      {option.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
      <div
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "12px",
          lineHeight: "20px",
          fontStyle: "normal",
          color: "red",
        }}
      >
        {errorMessage && errorMessage}
      </div>
    </div>
  );
}

export default IDropdown;
