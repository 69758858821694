import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import IButton from "../../../../Library/Button/IButton";
import { useLocation, useNavigate } from "react-router-dom";

import Toast from "../../../../Library/Toast/Toast";
import { useScreenSize } from "../../../../Context/ScreenContext";
import {
  MARGIN,
  TOAST_MESSAGES,
  toPascalCase,
} from "../../../../Constants/Constants";
import {
  validateAttribute,
  validateDescription,
  validateName,
} from "../GroupValidators/GroupValidators";
import IInput from "../../../../Library/Input/IInput";
import IDropdown from "../../../../Library/Dropdown/IDropdown";
import { useDash } from "../../../../Context/DashboardContext";
import ITextArea from "../../../../Library/TextArea/ITextArea";
import { useGroupService } from "../../../../Services/GroupService/GroupService";

function GroupDashboardEdit() {
  const location = useLocation();

  const { editGroup, updateGroupPicture } = useGroupService();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const navigate = useNavigate();
  const [group, setGroup] = useState<any>();
  const [image, setImage] = useState<string>(group?.photo);
  const [name, setName] = useState<string>(group?.name);
  const [privacy, setPrivacy] = useState<any>(group?.requirements?.privacy);

  const [description, setDescription] = useState("");

  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>("");
  const [attribute, setAttribute] = useState<any>(
    group?.requirements?.attribute,
  );

  const [level, setLevel] = useState<any>();
  const [, setIsFileValid] = useState<boolean>(false);
  const fileInputRef: any = useRef<HTMLInputElement>(null);
  const { user } = useDash();

  const getLevelOptions = () => {
    let levelOptions = [];
    const userLevel = user?.attributes[group?.requirements?.attribute]?.level;

    for (let i = 1; i <= userLevel; i++) {
      levelOptions.push({ label: String(i), value: i });
    }

    return levelOptions;
  };

  const [detailErrors, setDetailErrors] = useState({
    nameError: "",
    descriptionError: "",
    attributeError: "",
  });

  useEffect(() => {
    if (location.state?.group) {
      const groupData = location.state.group;
      setGroup(groupData);
      setImage(groupData.photo || "");
      setName(groupData.name || "");

      setDescription(groupData.description || "");
      setPrivacy(groupData.requirements.privacy);
      setLevel(groupData.requirements.level);
      setAttribute(toPascalCase(groupData.requirements.attribute) || "");
    }
  }, [location.state?.group]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 1048576; // 10MB in bytes
    const allowedFormats = ["jpg", "jpeg", "png", "webp"];
    setMessage(""); // Clear previous messages
    setIsError(false); // Reset error state

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      // Check if the file size exceeds the maximum limit or if the format is not allowed
      if (
        fileSize > maxFileSize ||
        (fileExtension && !allowedFormats.includes(fileExtension))
      ) {
        setMessage(`Invalid photo size or type`);
        setIsError(true);
        setIsFileValid(false); // Set file as invalid
        // Reset the file input so the user can try uploading the same file again
        event.target.value = ""; // Reset the input value
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target?.result as string); // Set image for preview
          setMessage(""); // Clear message
          setIsError(false); // Clear error state
          setIsFileValid(true); // Mark file as valid
        };
        reader.readAsDataURL(file);
      }
    } else {
      // Handle case where file selection is cancelled
      setIsFileValid(false); // Remain or set to invalid
    }
  };

  useEffect(() => {
    if (location.state?.group) {
      setGroup(location.state.group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDetailsStep = () => {
    const nameError = validateName(name);
    const descriptionError = validateDescription(description);
    const attributeError = validateAttribute(attribute);

    setDetailErrors({ nameError, descriptionError, attributeError });

    return !nameError && !descriptionError && !attributeError;
  };

  const handleEditGroup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fieldsToUpdate = {
      name: name.trim(), // Preemptively trim inputs
      description: description.trim(),
      level: level,
      privacy: privacy,
    };

    if (!validateDetailsStep()) {
      return;
    }

    const updatedFields: { [key: string]: any } = {};

    Object.entries(fieldsToUpdate).forEach(([key, value]) => {
      // Check and update level and privacy within requirements
      if (key === "level" || key === "privacy") {
        if (!updatedFields.requirements) {
          updatedFields.requirements = {};
        }

        if (group.requirements && group.requirements[key] !== value) {
          updatedFields.requirements[key] = value;
        }
      } else {
        // Direct comparison for non-nested fields
        if (value !== "" && group[key]?.trim() !== value) {
          updatedFields[key] = value;
        }
      }
    });

    // Remove requirements if it's empty
    if (
      updatedFields.requirements &&
      Object.keys(updatedFields.requirements).length === 0
    ) {
      delete updatedFields.requirements;
    }

    if (
      Object.keys(updatedFields).length > 0 ||
      (fileInputRef.current?.files && fileInputRef.current.files[0])
    ) {
      if (Object.keys(updatedFields).length > 0) {
        try {
          await editGroup(group?.id, updatedFields);

          navigate(`/groups/${group.id}/dashboard`, {
            state: { toast: TOAST_MESSAGES.UPDATE_GROUP.SUCCESS },
          });
          setIsError(false);
        } catch (error) {
          setMessage(TOAST_MESSAGES.UPDATE_GROUP.FAILURE);
          setIsError(true);
        }
      }

      // Photo update logic remains unchanged
      if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
        const formData = new FormData();
        formData.append("file", fileInputRef.current.files[0]);

        try {
          await updateGroupPicture(group?.id, formData);

          navigate(`/groups/${group.id}/dashboard`, {
            state: { toast: TOAST_MESSAGES.UPDATE_GROUP.SUCCESS },
          });
          setIsError(false);
        } catch (error) {
          setMessage(TOAST_MESSAGES.UPDATE_GROUP.FAILURE);
          setIsError(true);
        }
      }
    } else {
      setMessage("No changes detected.");
      setIsError(true);
    }
  };

  return (
    <div
      className={`${
        isMobile
          ? MARGIN.MOBILE.GROUPS.GROUP_EDIT
          : MARGIN.LARGE.GROUPS.GROUP_EDIT
      } pb-8`}
    >
      <Toast message={message} isError={isError} setMessage={setMessage} />

      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Edit Group" />
      </div>

      <div style={{ maxWidth: "460px" }}>
        <form onSubmit={handleEditGroup}>
          {/* <GroupDetailForm
            group={group}
            name={name}
            setName={setName}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            description={description}
            setDescription={setDescription}
            disabled={true}
            attribute={attribute}
            setAttribute={setAttribute}
            nameError={detailErrors.nameError}
            descriptionError={detailErrors.descriptionError}
            attributeError={detailErrors.attributeError}
          /> */}

          <div className="w-full pb-4">
            <IInput
              label="Name"
              name="name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              errorMessage={detailErrors.nameError}
            ></IInput>
          </div>

          {/* <div className="pb-4">
            <IDropdown
              options={[
                { label: "Public - Open to everyone", value: "PUBLIC" },
                { label: "Private - Request to access", value: "PRIVATE" },
                { label: "Restricted - Connections only", value: "RESTRICTED" },
              ]}
              labelText="Privacy"
              value={privacy}
              onChange={setPrivacy}
              //errorMessage={requirementErrors.privacyError}
            />
          </div> */}

          <div className=" pb-4">
            <IInput
              label="Type"
              name="attribute"
              value={attribute}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAttribute(e.target.value)
              }
              errorMessage={detailErrors.nameError}
              disabled={true}
            ></IInput>
          </div>

          <div className="pb-4">
            <div className="w-full">
              <IDropdown
                options={getLevelOptions()}
                labelText="Level"
                value={level}
                onChange={(value) => setLevel(value)}
                //errorMessage={requirementErrors.levelError}
              />
            </div>
          </div>

          {/* <div className="flex gap-2">
            <div className="pb-4 w-full">
              <IInput
                label="Min age"
                placeholder={""}
                numberOnly={true}
                name="minAge"
                value={minAge}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMinAge(e.target.value)
                }
                //errorMessage={requirementErrors.minAgeError}
              ></IInput>
            </div>
            <div className="pb-4 w-full">
              <IInput
                label="Max age"
                placeholder={""}
                name="maxAge"
                numberOnly={true}
                value={maxAge}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMaxAge(e.target.value)
                }
                //errorMessage={requirementErrors.maxAgeError}
              ></IInput>
            </div>
          </div> */}
          <div className="pb-4">
            <ITextArea
              name="description"
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              errorMessage={detailErrors.descriptionError}
            />
          </div>

          <div className="flex w-full pt-4">
            <div className="flex items-center justify-center border rounded w-[200px] h-[128px] mr-4 md:mb-4">
              <label className="flex flex-col items-center justify-center mx-4 cursor-pointer">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />
                <span className="text-regal-blue text-4xl font-light">+</span>
                <div>Add Image</div>
              </label>
            </div>

            <div className="flex items-center justify-center rounded w-[200px] h-[128px] mr-4 mb-4">
              <img
                src={image}
                alt="Uploaded"
                className="w-full h-full object-cover rounded"
              />
            </div>
          </div>

          <div className="pt-4">
            <IButton
              type="submit"
              className="px-4 py-2 "
              text="Save"
              bgColor="bg-regal-blue"
              textColor="text-white"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default GroupDashboardEdit;
