import React from "react";
import { useScreenSize } from "../../../Context/ScreenContext";
import { useNavigate } from "react-router-dom";

function DashBodyOverView() {
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const navigate = useNavigate();

  const headerStyle = {
    fontFamily: "Satoshi-Bold",
    fontSize: isMobile ? "44px" : "64px",
    fontStyle: "normal",
    lineHeight: isMobile ? "50px" : "76px", // Adjusted to be greater than font-size
    letterSpacing: "-2.56px",
  };

  const subHeaderStyle = {
    fontFamily: "Satoshi-Regular",
    fontSize: isMobile ? "14px" : "18px",
    fontStyle: "normal",
    lineHeight: "30px", // Adjusted to be greater than font-size
    letterSpacing: "-0.5px",
    color: "#7E858B",
  };

  const openAccountStyle = {
    fontFamily: "Satoshi-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    borderRadius: "8px",
  };

  const loginStyle = {
    fontFamily: "Satoshi-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    borderRadius: "8px",
  };

  return (
    <div>
      {" "}
      <div
        className={`  flex justify-center text-center ${
          isMobile ? "px-[25px] mt-[75px]" : "px-[144px] mt-[100px]"
        }`}
        style={headerStyle}
      >
        Discover local groups <br />
        in your city.
      </div>
      <div
        className={`mt-[12px] flex justify-center text-center ${
          isMobile ? "px-[30px]" : "px-[144px]"
        }`}
        style={subHeaderStyle}
      >
        ChatFindr helps you discover and join groups based on age, gender, and
        personal interests. Connect with people and find events happening near
        you!
      </div>
      <div
        className={`mt-[40px] mb-[116px] flex justify-center gap-[16px] text-center ${
          isMobile ? "px-[25px]" : "px-[144px]"
        }`}
      >
        <button
          style={openAccountStyle}
          className="border px-[40px] py-[14px] bg-regal-blue text-white"
        >
          <div className="flex gap-[8px]">
            <button
              type="button"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Create Account
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M6.33325 14.1668L14.6666 5.8335M14.6666 5.8335H6.33325M14.6666 5.8335V14.1668"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>{" "}
        </button>
        <button
          type="button"
          onClick={() => {
            navigate("/login");
          }}
          style={loginStyle}
          className="border px-[40px] py-[14px]"
        >
          Login
        </button>
      </div>
      <div
        style={{
          height: isSmallerThanLargeScreen ? "auto" : "720px",
          overflow: isSmallerThanLargeScreen ? "visible" : "hidden",
        }}
        className="relative w-full px-4 sm:px-[72px]" // Adjust padding based on screen size
      >
        <img
          src="/discover.png"
          alt="Description"
          className="w-full h-auto sm:h-full object-cover" // Adjust height based on screen size
          style={{
            height: isSmallerThanLargeScreen ? "auto" : "100%", // Full width on mobile, fixed height on larger screens
          }}
        />
      </div>
    </div>
  );
}

export default DashBodyOverView;
