import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IInput from "../../../Library/Input/IInput";
import {
  handleValidateEmail,
  validatePassword,
} from "./EmailPasswordValidators";
import { useAuthService } from "../../../Services/AuthService/AuthService";

function EmailPasswordForm({ onNextStep, userData, updateUser }: any) {
  const [emailPasswordErrors, setEmailPasswordErrors] = useState({
    emailError: "",
    passwordError: "",
  });
  const { validateEmail } = useAuthService();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        handleContinue();
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [userData.email, userData.password]);

  const handleContinue = async (): Promise<void> => {
    // Validate email and password formats locally first
    const canProceed = validateEmailAndPassword();

    if (!canProceed) {
      return;
    }

    const bodyData = {
      authType: "EMAIL",
      authIdentifier: userData.email,
    };

    try {
      const response = await validateEmail(bodyData);
      // Assuming the service returns JSON data

      // Assuming `data.available` indicates if the email is available (true) or taken (false)
      if (response) {
        onNextStep();
      } else {
        setEmailPasswordErrors((prevErrors) => ({
          ...prevErrors,
          emailError: "Email is already taken",
        }));
      }
    } catch (error) {
      console.error("Error during email validation:", error);
    }
  };

  const validateEmailAndPassword = () => {
    const emailError = handleValidateEmail(userData.email);
    const passwordError = validatePassword(userData.password);

    setEmailPasswordErrors({ emailError, passwordError });

    return !emailError && !passwordError;
  };

  return (
    <div>
      <div className="grid-rows-2">
        <div className="h-screen flex items-center justify-center">
          <div className="grid grid-rows" style={{ width: "360px" }}>
            <div
              style={{
                fontFamily: "Satoshi-Bold",
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "-0.5px",
              }}
            >
              Sign Up
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Get started today for free
            </div>
            <div className="mt-8">
              <IInput
                label="Email"
                type="email"
                name="email"
                placeholder=""
                value={userData.email}
                onChange={(e) =>
                  updateUser({ ...userData, email: e.target.value })
                }
                errorMessage={emailPasswordErrors.emailError}
              />
            </div>
            <div className="mt-4">
              <IInput
                type="password"
                name="password"
                placeholder=""
                label="Password"
                value={userData.password}
                onChange={(e) =>
                  updateUser({ ...userData, password: e.target.value })
                }
                errorMessage={emailPasswordErrors.passwordError}
              />
            </div>
            <div className="flex mt-4">
              <div
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                At least 8 characters
              </div>
            </div>

            <div>
              <button
                className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
                onClick={handleContinue}
                type="button"
              >
                Continue
              </button>
            </div>

            <div
              className="text-center mt-6 "
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-regal-blue"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailPasswordForm;
