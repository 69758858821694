import React, { useEffect } from "react";
import LoginForm from "./LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../Services/AuthService/AuthService";
import { Logo } from "../../Icons/Icons";
import { DiscoverGroupsOn } from "../../Constants/Constants";
import { useScreenSize } from "../../Context/ScreenContext";
import { Helmet } from "react-helmet-async";

function Login() {
  const { isAuthenticated } = useAuthService();
  const { isSmallerThanLargeScreen, isMobile } = useScreenSize();
  const navigate = useNavigate();
  useEffect(() => {
    // Disable scroll on mount
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    // Enable scroll on unmount
    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await isAuthenticated();
        if (response) {
          navigate("/discover");
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
      }
    };

    checkAuthentication();
  }, [navigate, isAuthenticated]);

  return (
    <div className="w-screen h-screen relative overflow-hidden flex justify-center items-center">
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Login to your account on ChatFindr and discover groups in your city."
        />
      </Helmet>
      <div className="absolute inset-0 w-1/2 h-full bg-white"></div>
      {!isSmallerThanLargeScreen && (
        <div className="absolute inset-0 left-1/2 w-1/2 h-full bg-gradient-to-tl from-slate-ish via-regal-blue to-white"></div>
      )}

      {/* Content Container */}
      <div className="w-full max-w-[1440px] h-full flex z-10">
        <button
          type="button"
          className={`absolute top-0 flex items-center ${
            isMobile ? "ml-[10px]" : "pl-[32px]"
          } pt-[32px] `}
          onClick={() => navigate("/")}
        >
          <Logo />
          <div
            style={{
              fontFamily: "Satoshi-Bold",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-1px",
              color: "#323439",
            }}
          >
            ChatFindr
          </div>
        </button>
        <div
          className={`h-full flex items-center justify-center ${
            isSmallerThanLargeScreen ? "w-full" : "w-1/2"
          }`}
        >
          <div className="max-w-[720px] w-full flex items-center justify-center">
            <LoginForm />
          </div>
        </div>
        <div
          className={`absolute bottom-0  pb-[32px] ${
            isMobile ? "ml-[16px]" : "pl-[32px]"
          } `}
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          ©Copyright ChatFindr 2024
        </div>
        {!isSmallerThanLargeScreen && (
          <div className="w-1/2 h-full flex items-center justify-center">
            <div className="max-w-[720px] w-full flex items-center justify-center">
              <DiscoverGroupsOn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
