/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import ILabel from "../../../Library/Label/ILabel";
import { useLocation, useNavigate } from "react-router-dom";
import { useChat } from "../../../Context/ChatContext";
import ChatGroupsCard from "./ChatGroupsCard";
import { formatFullDate } from "../../../Constants/Constants";
import { useScreenSize } from "../../../Context/ScreenContext";
import { useChatService } from "../../../Services/ChatService/ChatService";

function ChatGroups({ groups, chatTimestamps }: any) {
  const { isMobile } = useScreenSize();
  const { readMessages } = useChatService();

  const location = useLocation();
  function startOfWeek(date: Date, { weekStartsOn = 0 } = {}) {
    const diff = date.getDate() - date.getDay() + weekStartsOn;
    return new Date(date.getFullYear(), date.getMonth(), diff);
  }

  function endOfWeek(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay() + 6,
    );
  }

  function isInThisWeek(date: Date) {
    const now = new Date();

    const firstDayThisWeek = startOfWeek(new Date(now), { weekStartsOn: 0 });
    const lastDayThisWeek = endOfWeek(new Date(firstDayThisWeek));

    firstDayThisWeek.setHours(0, 0, 0, 0);
    lastDayThisWeek.setHours(23, 59, 59, 999);

    return date >= firstDayThisWeek && date <= lastDayThisWeek;
  }

  function isInLastWeek(date: any) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const firstDayThisWeek = startOfWeek(new Date(now), { weekStartsOn: 0 });
    const firstDayLastWeek = new Date(
      new Date(firstDayThisWeek).setDate(firstDayThisWeek.getDate() - 7),
    );

    const lastDayLastWeek = new Date(
      new Date(firstDayLastWeek).setDate(firstDayLastWeek.getDate() + 6),
    );

    firstDayLastWeek.setHours(0, 0, 0, 0);
    lastDayLastWeek.setHours(23, 59, 59, 999);

    return date >= firstDayLastWeek && date <= lastDayLastWeek;
  }

  const navigate = useNavigate();
  const {
    unreadMessagesCount,
    setUnreadMessagesCount,
    //setTotalUnreadMessagesCount,
  } = useChat();

  const handleGroupClick = async (group: any) => {
    const currentGroupId = location.pathname.split("/")[2]; // Get the current path
    try {
      await readMessages(currentGroupId);
      setUnreadMessagesCount((prevCounts: any) => {
        return {
          ...prevCounts,
          [group.id]: 0,
        };
      });
    } catch (error) {}

    navigate(`/chat/${group.id}`, { state: group });

    try {
      await readMessages(group.id);
      setUnreadMessagesCount((prevCounts: any) => {
        return {
          ...prevCounts,
          [group.id]: 0,
        };
      });
    } catch (error) {
      console.error("Failed to mark messages as read:", error);
    }
  };

  const { thisWeek, lastWeek, older } = useMemo(() => {
    const thisWeek: any = [];
    const lastWeek: any = [];
    const older: any = [];
    groups?.forEach((group: any) => {
      const formattedDate = formatFullDate(chatTimestamps[group.id]);

      const timestamp = new Date(formattedDate);
      if (isInThisWeek(timestamp)) {
        thisWeek.push(group);
      } else if (isInLastWeek(timestamp)) {
        lastWeek.push(group);
      } else {
        older.push(group);
      }
    });

    return { thisWeek, lastWeek, older };
  }, [groups, chatTimestamps]);

  return (
    <div
      className={`pb-[75px] max-h-screen h-screen overflow-y-auto bg-[#F9FAFB] ${
        isMobile ? "px-[16px]" : "px-[32px]"
      }`}
    >
      <div className="pt-[32px] pb-[32px]">
        <ILabel text="Chat"></ILabel>
      </div>

      <div>
        {groups && groups.length > 0 ? (
          <>
            {thisWeek.length > 0 && (
              <div>
                <div
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "12px",
                    fontStyle: "normal",
                    lineHeight: "16px",
                    color: "#323439",
                  }}
                  className="mb-[16px]"
                >
                  This Week
                </div>
                {thisWeek.map((group: any) => (
                  <ChatGroupsCard
                    key={group.id}
                    group={group}
                    handleGroupClick={handleGroupClick}
                    unreadMessagesCount={unreadMessagesCount}
                  />
                ))}
              </div>
            )}

            {lastWeek.length > 0 && (
              <div>
                <div
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "12px",
                    fontStyle: "normal",
                    lineHeight: "16px",
                    color: "#323439",
                  }}
                  className="mb-[16px]"
                >
                  Last Week
                </div>
                {lastWeek.map((group: any) => (
                  <ChatGroupsCard
                    key={group.id}
                    group={group}
                    handleGroupClick={handleGroupClick}
                  />
                ))}
              </div>
            )}

            {older.length > 0 && (
              <div>
                <div
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "12px",
                    fontStyle: "normal",
                    lineHeight: "16px",
                    color: "#323439",
                  }}
                  className="mb-[16px]"
                >
                  Older
                </div>
                {older.map((group: any) => (
                  <ChatGroupsCard
                    key={group.id}
                    group={group}
                    handleGroupClick={handleGroupClick}
                    unreadMessagesCount={unreadMessagesCount}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <ILabel text="No Chat History" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatGroups;
