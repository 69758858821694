import React, { useState, useRef, createRef } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useLocation } from "react-router-dom";
import Toast from "../../../Library/Toast/Toast";
import { useAuthService } from "../../../Services/AuthService/AuthService";

function ValidateEmail() {
  const location = useLocation();
  const userData = location.state;
  const { verifyUser, resendVerificationEmail } = useAuthService();
  const [digits, setDigits] = useState(["", "", "", ""]);
  const { handleLogin } = useAuth();
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>(false);
  // Create an array of refs for the input fields
  const digitRefs = useRef<any>([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const handleChange = async (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      if (value && index === 3) {
        // Once the last digit is entered
        const verificationCode = newDigits.join("");

        try {
          const verifyResponse = await verifyUser(verificationCode);

          try {
            await handleLogin({
              authType: "EMAIL",
              authIdentifier: userData.authIdentifier,
              password: userData.password,
            });
          } catch (loginError) {
            console.error("Login failed:", loginError);
          }
        } catch (verifyError) {
          setDigits(["", "", "", ""]);
          digitRefs.current[0]?.focus();
          return;
        }
      } else if (value && index < 3) {
        // Auto-focus to next input if a digit is entered
        digitRefs.current[index + 1]?.focus();
      }
    }
  };

  const resendVerification = async () => {
    try {
      await resendVerificationEmail({
        authType: "EMAIL",
        authIdentifier: userData.authIdentifier,
      });

      setMessage("Verification Email Resent");
      setIsError(false);
    } catch (error) {}
  };

  return (
    <div className="h-screen flex items-center justify-center">
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <div className="flex flex-col" style={{ width: "350px" }}>
        <div
          className="mb-[8px]"
          style={{
            fontFamily: "Satoshi-Bold",
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.5px",
          }}
        >
          Email Sent
        </div>
        <div
          className="mb-[24px]"
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#323439",
          }}
        >
          Check your inbox for the code to activate your account, expires in 24
          hours.
        </div>
        <div className="flex justify-between">
          {digits.map((digit: any, index: any) => (
            <input
              key={index}
              ref={(el) => (digitRefs.current[index] = el)} // Assign the ref to the corresponding input
              className="w-14 h-14 border rounded text-center text-lg"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              type="tel"
            />
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button
            type="button"
            onClick={() => resendVerification()}
            style={{
              fontFamily: "Satoshi-Regular",
              fontSize: "14px",
              fontStyle: "normal",
              lineHeight: "16px",
            }}
          >
            Resend Verification Email
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValidateEmail;
