import React, { useState } from "react";
import IInput from "../../../Library/Input/IInput";
import {
  validatePassword,
  validatePasswordsMatch,
} from "../LoginForm/LoginValidators";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthService } from "../../../Services/AuthService/AuthService";
import { DiscoverGroupsOn } from "../../../Constants/Constants";
import { useScreenSize } from "../../../Context/ScreenContext";

function ResetPasswordConfirmation() {
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<any>("");
  const { token } = useParams();
  const navigate = useNavigate();
  const { isSmallerThanLargeScreen } = useScreenSize();
  const [passwordErrors, setPasswordErrors] = useState({
    newPasswordError: "",
    confirmNewPasswordError: "",
  });
  const { confirmResetPassword } = useAuthService();

  const validatePasswords = () => {
    const newPasswordError = validatePassword(newPassword);
    const confirmNewPasswordError = validatePassword(confirmNewPassword);
    setPasswordErrors({ newPasswordError, confirmNewPasswordError });

    return !newPasswordError && !confirmNewPasswordError;
  };

  const validatePasswordMatch = () => {
    const confirmError = validatePasswordsMatch(
      newPassword,
      confirmNewPassword,
    );

    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      confirmNewPasswordError: confirmError,
    }));

    return !confirmError;
  };

  const handleConfirm = async (event: any) => {
    event.preventDefault();

    const canProceed = validatePasswords();
    const passwordMatch = validatePasswordMatch();
    console.log(canProceed);
    if (!canProceed) {
      return;
    }

    console.log("2");

    if (!passwordMatch) {
      return;
    }

    try {
      await confirmResetPassword(newPassword, token);

      navigate("/login");
    } catch (error) {}
  };

  return (
    <div className="w-screen h-screen relative overflow-hidden flex justify-center items-center">
      <div className="absolute inset-0 w-1/2 h-full bg-white"></div>
      {!isSmallerThanLargeScreen && (
        <div className="absolute inset-0 left-1/2 w-1/2 h-full bg-gradient-to-tl from-slate-ish via-regal-blue to-white"></div>
      )}

      {/* Content Container */}
      <div className="w-full max-w-[1440px] h-full flex z-10">
        <div
          className={`h-full flex items-center justify-center relative ${
            isSmallerThanLargeScreen ? "w-full" : "w-1/2"
          }`}
        >
          <div className="max-w-[720px] w-full flex items-center justify-center">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="grid-rows-2">
                <div className="grid grid-rows" style={{ width: "360px" }}>
                  <form onSubmit={handleConfirm}>
                    <div
                      style={{
                        fontFamily: "Satoshi-Bold",
                        fontSize: "24px",
                        lineHeight: "32px",
                        letterSpacing: "-0.5px",
                      }}
                    >
                      Reset Password
                    </div>
                    <div
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Enter and confirm your new password
                    </div>
                    <div className="mt-8">
                      <IInput
                        label="New Password"
                        name="new"
                        type="password"
                        placeholder=""
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        errorMessage={passwordErrors.newPasswordError}
                      />
                    </div>
                    <div className="mt-4">
                      <IInput
                        type="password"
                        name="password"
                        placeholder=""
                        label="Confirm Password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        errorMessage={passwordErrors.confirmNewPasswordError}
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 pl-[32px] pb-[32px] "
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          ©Copyright ChatFindr 2024
        </div>
        {!isSmallerThanLargeScreen && (
          <div className="w-1/2 h-full flex items-center justify-center">
            <div className="max-w-[720px] w-full flex items-center justify-center">
              <DiscoverGroupsOn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPasswordConfirmation;
