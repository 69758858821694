import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import IEventPanel from "../../../../Library/EventPanel/IEventPanel";
import {
  transformMembersData,
  transformReputationData,
} from "./GroupDashboardGraphAnalytics";
import IGraph from "../../../../Library/Graph/IGraph";
import IButton from "../../../../Library/Button/IButton";
import IMenuButton from "../../../../Library/MenuButton/IMenuButton";
import GroupDetails from "../GroupDetails/GroupDetails";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import ConfirmationDialog from "../../../../Library/ConfirmationDialog/ConfirmationDialog";
import { useDash } from "../../../../Context/DashboardContext";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN } from "../../../../Constants/Constants";
import { useChat } from "../../../../Context/ChatContext";
import { VerticalDots } from "../../../../Icons/Icons";
import Toast from "../../../../Library/Toast/Toast";
import { useGroupService } from "../../../../Services/GroupService/GroupService";

function GroupDashboard() {
  const { groupId } = useParams();
  const { user, updateUserFields } = useDash();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();

  const { getGroupDashboard, getGroupMembers, deleteGroup, transferGroup } =
    useGroupService();
  const navigate = useNavigate();

  const [groupDashboard, setGroupDashboard] = useState<any>(null);
  const [organizer, setOrganizer] = useState<any>();
  const [, setOrganizerId] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [groupPicture] = useState<any>("");
  const [membersList, setMembersList] = useState<any>();
  const [, setUpComingEvents] = useState<any>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirm, setConfirm] = useState<any>();
  const [cancel] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<any>();
  const [dialogHeaderMessage, setDialogHeaderMessage] = useState<any>();
  const [transferableMembers, setTransferableMembers] = useState<any>();
  const [transferMember, setTransferMember] = useState<any>();
  const { removeGroupAndCloseWebSocket } = useChat();
  const [message, setMessage] = useState();
  const [isError, setIsError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.toast) {
      setMessage(location.state.toast); // Display the toast message
      setIsError(false); // Set as success message

      // Navigate to the same URL without the state to "clear" the toast message
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const getDashboard = async (callback = () => {}) => {
    try {
      const groupDashboard = await getGroupDashboard(groupId);

      if (groupDashboard && groupDashboard?.group?.organizer?.id === user.id) {
        setGroupDashboard(groupDashboard);
        setOrganizerId(groupDashboard?.group?.organizer);
        setOrganizer(groupDashboard?.organizer);
        const membersObject = groupDashboard?.group?.members;
        const membersArray = Object.keys(membersObject).map((key) => ({
          id: key,
          ...membersObject[key],
        }));

        setMembersList(membersArray);
        setUpComingEvents(groupDashboard?.upcomingEvents);
      } else {
        navigate(`/groups/${groupId}`);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDashboard();
      } catch (error) {}
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const membersChartData = groupDashboard?.group?.analytics
    ? transformMembersData(groupDashboard?.group?.analytics)
    : null;

  const reputationChartData = groupDashboard?.group?.analytics
    ? transformReputationData(groupDashboard?.group?.analytics)
    : null;

  const handleConfirmationDialog = async (option: string) => {
    switch (option) {
      case "Delete":
        setConfirm("Delete");
        setDialogMessage(
          "Are you sure? This will delete all chat and event related data.",
        );
        setShowConfirmationDialog(true);
        setDialogHeaderMessage("Delete");
        break;
      case "Transfer":
        setConfirm("Transfer");
        setDialogHeaderMessage("Transfer");
        setDialogMessage("Select a member to transfer to");
        try {
          const response = await getGroupMembers(groupId);
          const members = response.filter(
            (member: any) => member.id !== user.id,
          );

          setTransferableMembers(members);
          setShowConfirmationDialog(true);
        } catch (error) {}
        break;
    }
  };

  const handleConfirm = async () => {
    switch (confirm) {
      case "Delete":
        try {
          await deleteGroup(groupId);
          setShowConfirmationDialog(false);
          navigate("/groups");
          removeGroupAndCloseWebSocket(groupId);
        } catch (error) {}
        break;
      case "Transfer": {
        try {
          await transferGroup(groupId, transferMember);
          const updatedCreatedGroups = user.createdGroups.filter(
            (id: any) => id !== groupId,
          );
          setShowConfirmationDialog(false);
          navigate("/groups");
          updateUserFields({ createdGroups: updatedCreatedGroups });
        } catch (error) {}
      }
    }
  };

  const handleCancel = () => {
    setShowConfirmationDialog(false);
  };

  const getMargin = () => {
    return isMobile
      ? MARGIN.MOBILE.GROUPS.GROUP_DASH
      : MARGIN.LARGE.GROUPS.GROUP_DASH;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      {message && (
        <Toast message={message} setMessage={setMessage} isError={isError} />
      )}
      <div>
        {" "}
        <ConfirmationDialog
          show={showConfirmationDialog}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          cancel={cancel}
          confirm={confirm}
          message={dialogHeaderMessage}
          subMessage={dialogMessage}
          transferableMembers={transferableMembers}
          transferMember={transferMember}
          setTransferMember={setTransferMember}
        />
        <div
          className={`flex justify-between ${
            !isSmallerThanLargeScreen
              ? " mt-[2.96vh] mb-[2.96vh]"
              : "mt-[1.48vh] mb-[2.96vh]"
          } `}
        >
          <div className="truncate mr-[10px]">
            <ILabel text={groupDashboard?.group?.name} />
          </div>

          <div className="flex">
            <IButton
              text={"Edit"}
              onClick={() =>
                navigate(`/groups/${groupId}/dashboard/edit`, {
                  state: { group: groupDashboard?.group },
                })
              }
              bgColor="bg-regal-blue"
              textColor="text-white"
              className="px-6 mr-2"
            />
            <IMenuButton
              options={[
                {
                  label: "Transfer",
                  action: () => handleConfirmationDialog("Transfer"),
                },
                {
                  label: "Delete",
                  action: () => handleConfirmationDialog("Delete"),
                },
              ]}
              icon={<VerticalDots />}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 w-full pb-4 ">
        {reputationChartData && (
          <IGraph
            title="Reputation"
            data={reputationChartData?.series}
            categories={reputationChartData?.categories}
            attribute={groupDashboard.group?.requirements?.attribute}
          />
        )}
        {membersChartData && (
          <IGraph
            data={membersChartData.series}
            categories={membersChartData.categories}
            title="Members"
            attribute={groupDashboard.group?.requirements?.attribute}
          />
        )}
      </div>

      <div className="pb-4">
        <GroupDetails
          group={groupDashboard?.group}
          organizer={organizer}
          groupId={groupId}
          groupPicture={groupPicture}
          membersList={membersList}
        />
      </div>

      <div className="mb-[1.85vh]">
        <div className="mb-[1.85vh]">
          <IEventPanel
            title="Upcoming Events"
            events={groupDashboard?.upcomingEvents}
            showAll={true}
            onEventClick={(eventName, eventId) => {
              navigate(`/events/${eventId}`);
            }}
            marginTop="mt-0"
            subHeader={`View upcoming events for ${groupDashboard.group.name}`}
          />
        </div>
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Event History"
          events={groupDashboard?.eventHistory}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader={`View event history for ${groupDashboard.group.name}`}
        />
      </div>
    </div>
  );
}

export default GroupDashboard;
