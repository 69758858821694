export const validateName = (name: string): string => {
  // First check if the name is empty
  if (name.trim() === "") {
    return "Required";
  }

  // If all checks pass, return an empty string indicating no error
  return "";
};

export const validateDescription = (description: string): string => {
  return description.trim() === "" ? "Required" : "";
};

export const validateAttribute = (attribute: any): string => {
  return !attribute ? "Required" : "";
};

export const validateMinAge = (minAge: string): string => {
  return minAge.trim() === "" ? "Required" : "";
};

export const validateMaxAge = (maxAge: string): string => {
  return maxAge.trim() === "" ? "Required" : "";
};

export const validateGenderRequirements = (gender: string): string => {
  return gender.trim() === "" ? "Required" : "";
};

export const validatePrivacy = (privacy: string): string => {
  return privacy === "" ? "Required" : "";
};

export const validateLevel = (level: any): string => {
  return level === "" ? "Required" : "";
};

export const validateImageFile = (
  image: File | string | null | undefined,
): string => {
  // Checks if image is not provided or is an empty string (for URL case)
  return !image || (typeof image === "string" && image.trim() === "")
    ? "Required"
    : "";
};
