/* eslint-disable no-useless-escape */
export const handleValidateEmail = (email: string): string => {
  if (email.trim() === "") {
    return "Required";
  }

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(email)) {
    return "Invalid email format";
  }

  return "";
};

export const validatePassword = (password: string): string => {
  if (password.trim() === "") {
    return "Required";
  }

  const minLength = password.length >= 8;

  if (!minLength) {
    return "Password must be at least 8 characters long";
  }

  // If all conditions are met
  return "";
};
