import React, { useEffect } from "react";
import { Element } from "react-scroll";
import DashBody from "./Body/DashBody";
import DashHeader from "./Header/DashHeader";
import DashFooter from "./Footer/DashFooter";
import DashBodyHeader from "./Body/DashBodyHeader";

function Dashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
  }, []);

  return (
    <div className="w-screen" style={{ position: "relative" }}>
      <DashHeader />
      <div
        className=" w-full"
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1000,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DashBodyHeader />
      </div>
      <DashBody />
      <Element name="footer">
        <DashFooter />
      </Element>
    </div>
  );
}

export default Dashboard;
