import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDash } from "../../Context/DashboardContext";
import { useScreenSize } from "../../Context/ScreenContext";
import { MenuIcon } from "../../Icons/Icons";
import { Colors } from "../../Constants/Constants";

function Dashboard() {
  const location = useLocation();
  const { user } = useDash();
  const { isMobile } = useScreenSize();
  const [sidebarVisibilty, setSidebarVisibility] = useState<boolean>(false);
  const [viewProfile, setViewProfile] = useState<boolean>(false);
  const [sideBarSelection, setSideBarSelection] = useState<any>(
    sessionStorage.getItem("sidebarSelection"),
  );
  const { updateUserFields } = useDash();

  const toggleSidebar = () => {
    setSidebarVisibility(!sidebarVisibilty);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const newPhotoUrl = localStorage.getItem("newPhotoUrl");
    if (newPhotoUrl) {
      updateUserFields({ photo: newPhotoUrl });
      localStorage.removeItem("newPhotoUrl"); // Clear the item from Local Storage
    }
  });

  useEffect(() => {
    sessionStorage.setItem("sidebarSelection", sideBarSelection);
    return () => {
      sessionStorage.removeItem("sidebarSelection");
    };
  }, [sideBarSelection]);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleSidebar();
  };

  const getBackGroundColor = () => {
    if (location.pathname.includes("edit")) {
      return Colors.White;
    }
    if (location.pathname.includes("chat")) {
      return Colors.White;
    }
    if (location.pathname.includes("create")) {
      return Colors.White;
    }
    switch (location.pathname) {
      case "/settings":
        return Colors.White;

      default:
        return Colors.Slate;
    }
  };

  const getMargin = () => {
    return isMobile ? "" : "ml-[320px]";
  };

  const getPaddingBottom = () => {
    if (!location.pathname.includes("/chat")) {
      return "pb-[75px]";
    }
  };

  const isBasicUserOnChatPage =
    location.pathname === "/chat" && user?.subscription?.tier === "FREE";

  return (
    <div
      className={`${getBackGroundColor()} ${getPaddingBottom()}  flex flex-col min-h-screen `}
    >
      <div className="max-w-[1440px]">
        {" "}
        {!sidebarVisibilty && (
          <header
            className={`z-50 fixed ${
              isMobile ? "fixed top-0 left-0 right-0" : ""
            }`}
            style={{ height: "60px" }}
          >
            <div className="px-[16px]  flex items-center absolute inset-0 border-b bg-white md:hidden focus:ring-gray-200">
              <button onClick={handleButtonClick}>
                <MenuIcon />
              </button>
              <div className="flex items-center ml-[24px]">
                <svg
                  className="mt-[1px]"
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 150 150"
                  height="45"
                  width="45"
                >
                  <path
                    fill="#5081ff"
                    d="M82.29,19.74v10.1c18.26,0,33.1,14.85,33.1,33.1s-14.85,33.1-33.1,33.1c-3.12,0-6.14-.44-9-1.26-3.05-.85-5.94-2.14-8.56-3.8-2.56-1.59-4.87-3.53-6.9-5.75l-6.6,7.7-25.65,29.93c-1.6,1.87-1.39,4.71,.49,6.33,.84,.72,1.89,1.08,2.92,1.08,1.27,0,2.52-.53,3.41-1.57l25.7-29.98c2.52,1.72,5.25,3.18,8.12,4.32,4.97,2.01,10.39,3.11,16.07,3.11,23.82,0,43.2-19.38,43.2-43.2s-19.38-43.2-43.2-43.2Z"
                  />
                  <path
                    fill="#5081ff"
                    d="M45.6,85.72l7-8.17c-1.64-3.29-2.73-6.88-3.18-10.68h17.16c2.48,0,4.49-2.01,4.49-4.49,0-1.64-.88-3.06-2.18-3.84-.67-.42-1.47-.65-2.31-.65h-17.01c1.99-12.88,11.41-23.34,23.74-26.81v-10.4c-17.92,3.79-31.77,18.74-33.93,37.21-.19,1.66-.29,3.34-.29,5.05,0,1.32,.06,2.64,.18,3.93,.62,6.88,2.86,13.29,6.33,18.85Z"
                  />
                  <path
                    fill="#5081ff"
                    d="M82.29,67.99c1.12,0,2.17-.36,3-.99,.89-.65,1.51-1.58,1.83-2.66,.07-.21,.12-.42,.16-.63,.02-.12,.04-.26,.04-.38,.02-.13,.02-.26,.02-.39,0-.16-.01-.31-.02-.47-.24-2.57-2.39-4.58-5.03-4.58s-4.85,2.05-5.04,4.68c-.01,.12-.01,.25-.01,.37,0,2.79,2.26,5.05,5.05,5.05Z"
                  />
                  <path
                    fill="#5081ff"
                    d="M97.44,67.99c2.79,0,5.05-2.26,5.05-5.05s-2.26-5.05-5.05-5.05c-.7,0-1.36,.13-1.96,.39-1.82,.77-3.09,2.56-3.09,4.66s1.28,3.89,3.1,4.66c.59,.26,1.26,.39,1.95,.39Z"
                  />
                </svg>
                <span
                  style={{
                    fontFamily: "Satoshi-Bold",
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "-1px",
                    color: "#323439",
                  }}
                >
                  ChatFindr
                </span>
              </div>
            </div>
          </header>
        )}
        <Sidebar
          sideBarSelection={sideBarSelection}
          sideBarVisibility={sidebarVisibilty}
          viewProfile={viewProfile}
          setViewProfile={setViewProfile}
          setSideBarSelection={setSideBarSelection}
          setSideBarVisibility={setSidebarVisibility}
          isBasicUserOnChatPage={isBasicUserOnChatPage}
        />
        <div className={`${getMargin()} flex-1 overflow-auto pt-14 md:pt-0`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
