import React, { useState, useRef, useEffect } from "react";

interface IToolTipProps {
  message: string;
}

const IToolTip: React.FC<IToolTipProps> = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<any>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Handle clicking outside the tooltip to close it
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  const tooltipStyles: any = {
    position: "absolute",
    bottom: "100%",
    zIndex: 10,
    whiteSpace: "nowrap",
  };

  return (
    <div className="relative" ref={tooltipRef}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4 text-red-500"
        onClick={toggleVisibility}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
        />
      </svg>

      {isVisible && (
        <div
          role="tooltip"
          className="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-black bg-white border rounded-lg  tooltip dark:bg-gray-700"
          style={tooltipStyles}
        >
          {message}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

export default IToolTip;
