import React, { useState } from "react";
import IInput from "../../../Library/Input/IInput";
import ICheckbox from "../../../Library/Checkbox/ICheckbox";
import { Link } from "react-router-dom";
import ResetPasswordForm from "../ResetPasswordForm/ResetPasswordForm";
import { useAuth } from "../../../Context/AuthContext";
import Toast from "../../../Library/Toast/Toast";
import { validateEmail, validatePassword } from "./LoginValidators";

function LoginForm() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [setError] = useState<any>("");
  const [, setIsLoading] = useState(false);
  const { handleLogin, authMessage, setAuthMessage } = useAuth();

  const [emailPasswordErrors, setEmailPasswordErrors] = useState({
    emailError: "",
    passwordError: "",
  });

  const validateEmailAndPassword = () => {
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    setEmailPasswordErrors({ emailError, passwordError });

    return !emailError && !passwordError;
  };

  const handleLoginAttempt = async (event: any) => {
    event.preventDefault();
    const canProceed = validateEmailAndPassword();

    if (!canProceed) {
      return;
    }
    setIsLoading(true);
    try {
      await handleLogin({
        authType: "EMAIL",
        authIdentifier: email,
        password: password,
      });
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return (
    <div className="grid-rows-2 flex flex-col items-center justify-center">
      <div className="grid grid-rows" style={{ width: "360px" }}>
        {showForgotPassword ? (
          <ResetPasswordForm
            handleForgotPasswordClick={handleForgotPasswordClick}
          />
        ) : (
          <form onSubmit={handleLoginAttempt}>
            <div
              style={{
                fontFamily: "Satoshi-Bold",
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "-0.5px",
              }}
            >
              Login
            </div>
            <div
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Sign in with your email address
            </div>
            <div className="mt-8">
              <IInput
                label="Email"
                name="email"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errorMessage={emailPasswordErrors.emailError}
              />
            </div>
            <div className="mt-4">
              <IInput
                type="password"
                name="password"
                placeholder=""
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={emailPasswordErrors.passwordError}
              />
            </div>

            <Toast
              message={authMessage}
              setMessage={setAuthMessage}
              isError={true}
            />

            <div className="flex mt-4 justify-between">
              <ICheckbox label="Remember me" />
              <button
                onClick={handleForgotPasswordClick}
                type="button"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Forgot password?
              </button>
            </div>
            <button
              type="submit"
              className="w-full rounded-2xl font-light text-white text-md bg-regal-blue py-2 px-8 border border-grey mt-6"
            >
              Continue
            </button>
            <div
              className="text-center mt-6 "
              style={{
                fontFamily: "Satoshi-Regular",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Don't have an account yet?{" "}
              <Link
                to="/signup"
                className="text-regal-blue"
                style={{
                  fontFamily: "Satoshi-Regular",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Sign Up
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default LoginForm;
