// authService.ts
import { useAxios } from "../../Context/AxiosContext"; // Adjust the import path

export const useAuthService = () => {
  const axiosInstance = useAxios();

  const logout = async () => {
    const response = await axiosInstance.post(
      "/auth/logout",
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    if (!response.status.toString().startsWith("2")) {
      throw new Error("Logout failed");
    }
    return response.data;
  };

  const login = async (loginData: any) => {
    const response = await axiosInstance.post("/auth/login", loginData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const refreshToken = async () => {
    const response = await axiosInstance.post(
      "/auth/refresh-token",
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    if (response.status.toString().startsWith("2")) {
      return response.data;
    }
    return response;
  };

  const verifyUser = async (token: any) => {
    const response = await axiosInstance.get("/auth/verify", {
      params: { token },
    });
    return response.data;
  };

  const isAuthenticated = async () => {
    const response = await axiosInstance.get("/auth/authenticated", {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const validateEmail = async (data: any) => {
    const response = await axiosInstance.post("/user/validate", data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  const resetPassword = async (email: any) => {
    const response = await axiosInstance.post(
      "/auth/reset-password",
      {
        authType: "EMAIL",
        authIdentifier: email,
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const confirmResetPassword = async (password: any, token: any) => {
    const response = await axiosInstance.post(
      "/auth/reset-password-confirmation",
      {
        token,
        password,
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  const resendVerificationEmail = async (loginData: any) => {
    const response = await axiosInstance.post(
      "/auth/resend-verification",
      loginData,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  };

  return {
    logout,
    login,
    refreshToken,
    verifyUser,
    isAuthenticated,
    validateEmail,
    resetPassword,
    confirmResetPassword,
    resendVerificationEmail,
  };
};
