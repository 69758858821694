/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";
import IButton from "../../../../Library/Button/IButton";
import IEventPanel from "../../../../Library/EventPanel/IEventPanel";
import EventDetails from "../EventDetails/EventDetails";
import ICarousel from "../../../../Library/Carousel/ICarousel";
import { useNavigate, useParams } from "react-router-dom";
import { useDash } from "../../../../Context/DashboardContext";
import ISpinner from "../../../../Library/Spinner/ISpinner";
import { useScreenSize } from "../../../../Context/ScreenContext";
import { MARGIN, TOAST_MESSAGES } from "../../../../Constants/Constants";
import Toast from "../../../../Library/Toast/Toast";
import { useEventService } from "../../../../Services/EventService/EventService";

function Event() {
  const { eventId } = useParams();
  const { user, joinEvent, leaveEvent } = useDash();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { getEvent, joinCurrentEvent, leaveCurrentEvent } = useEventService();
  const navigate = useNavigate();
  const [event, setEvent] = useState<any>();
  const [groupPicture] = useState<any>();
  const [organizer, setOrganizer] = useState<any>();
  const [organizerId, setOrganizerId] = useState<any>();
  const [relatedEvents, setRelatedEvents] = useState<any>();
  const [group, setGroup] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attendeesList, setAttendeesList] = useState<any>();
  const [eventStatus, setEventStatus] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>();

  const fetchEvent = async () => {
    try {
      const eventPage = await getEvent(eventId);
      setEvent(eventPage?.event);
      setOrganizerId(eventPage?.event?.organizer?.id);
      setOrganizer(eventPage?.event?.organizer);
      setGroup(eventPage?.event?.host);
      setAttendeesList(eventPage?.event?.attendees);
      setRelatedEvents(eventPage?.relatedEvents);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchEvent();
      } catch (error) {}
      setIsLoading(false);
    };

    fetchData();
  }, [eventId]);

  useEffect(() => {
    if (!event || !user || typeof eventId === "undefined") {
      return;
    }

    const now = new Date();
    const startDate = new Date(event.startTime);
    const endDate = new Date(event.endTime);

    let status;

    if (now >= startDate && now <= endDate) {
      status = "Ongoing";
    } else if (now > endDate) {
      status = "Completed";
    } else if (user.eventInvites.includes(eventId)) {
      status = "Pending Response";
    } else {
      status = user.joinedEvents?.includes(eventId) ? "Leave" : "Join";
    }

    setEventStatus(status);

    const isDisabled =
      user.id === event.organizerId ||
      status === "Ongoing" ||
      status === "Completed" ||
      status === "Pending Response";
    setIsButtonDisabled(isDisabled);
  }, [event, user, eventId]);

  const handleJoinOrLeaveEvent = async () => {
    try {
      user?.joinedEvents.includes(event.id)
        ? await leaveCurrentEvent(eventId)
        : await joinCurrentEvent(eventId);

      // Before attempting to join/leave the event
      const attemptingToJoin = !user?.joinedEvents?.includes(eventId);
      // Use this flag to determine the correct failure message later

      if (!attemptingToJoin) {
        // Logic when user is leaving the event
        const updatedMembersList = attendeesList.filter(
          (id: any) => id !== user?.id,
        );
        setAttendeesList(updatedMembersList);
        leaveEvent(eventId); // Assuming this updates user.joinedEvents appropriately
        setMessage(TOAST_MESSAGES.LEAVE_EVENT.SUCCESS);
        setIsError(false);
        setEventStatus("Join Event");
      } else {
        // Logic when user is joining the event
        const updatedMembersList = [...attendeesList, user?.id];
        setAttendeesList(updatedMembersList);
        joinEvent(eventId); // Assuming this updates user.joinedEvents appropriately
        setMessage(TOAST_MESSAGES.JOIN_EVENT.SUCCESS);
        setIsError(false);
        setEventStatus("Leave Event");
      }
    } catch (error) {
      setMessage("Something went wrong");
      setIsError(true);
    }
  };

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.EVENTS.EVENT : MARGIN.LARGE.EVENTS.EVENT;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <div
        className={`flex justify-between ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <div className="truncate mr-[10px]">
          <ILabel text={event?.name} />
        </div>
        <div className="flex">
          <IButton
            text={eventStatus}
            onClick={handleJoinOrLeaveEvent}
            bgColor={isButtonDisabled ? "bg-white" : "bg-regal-blue"}
            textColor={isButtonDisabled ? "text-black" : "text-white"}
            className="px-6 py-2 mr-2"
            disabled={isButtonDisabled}
          />
          {/* <IMenuButton options={[]} icon={<VerticalDots />} /> */}
        </div>
      </div>
      <div className="pb-4">
        <ICarousel imageUrls={[event?.photo]} />
      </div>

      <div className="pb-4">
        <EventDetails
          event={event}
          organizer={organizer}
          organizerId={organizerId}
          group={group}
          groupPicture={groupPicture}
          attendeesList={attendeesList}
        />
      </div>

      <div className="mb-[1.85vh]">
        <IEventPanel
          title="Related Events"
          events={relatedEvents}
          showAll={true}
          onEventClick={(eventName, eventId) => {
            navigate(`/events/${eventId}`);
          }}
          marginTop="mt-0"
          subHeader={`View related events for ${event.name}`}
        />
      </div>
    </div>
  );
}

export default Event;
