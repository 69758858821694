// AxiosContext.js
import React, { createContext, useContext, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AxiosContext = createContext<any>(null);

const handle403Error = (navigate: any) => {
  navigate("/");
};

export const AxiosProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    // Intercept requests to add the token
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Intercept responses to handle 403 errors
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 403) {
          handle403Error(navigate);
        }
        return Promise.reject(error);
      },
    );

    return instance;
  }, [navigate]);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => {
  return useContext(AxiosContext);
};
