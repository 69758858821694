/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import IAttributeBar from "../../../Library/AttributeBar/IAttributeBar";
import IPanel from "../../../Library/Panel/IPanel";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MARGIN,
  calculateAge,
  formatLastLogin,
  getAttributeColor,
  getIconForAttribute,
  toPascalCase,
} from "../../../Constants/Constants";
import ISpinner from "../../../Library/Spinner/ISpinner";
import IReputationBar from "../../../Library/ReputationBar/IReputationBar";
import { useDash } from "../../../Context/DashboardContext";
import { useScreenSize } from "../../../Context/ScreenContext";
import IGroupPanel from "../../../Library/GroupPanel/IGroupPanel";
import { useUserService } from "../../../Services/UserService/UserService";
import { useGroupService } from "../../../Services/GroupService/GroupService";
import { useConnectionService } from "../../../Services/ConnectionsService/ConnectionsService";
function Profile() {
  const { userId } = useParams();
  const { user, updateUserFields } = useDash();
  const { isMobile, isLargeScreen } = useScreenSize();
  const { removeConnection, addConnection } = useConnectionService();
  const { fetchUser } = useUserService();
  const { getUsersGroups } = useGroupService();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<any>();
  const [groups, setGroups] = useState<any>();
  const [connectionStatus, setConnectionStatus] = useState<any>();

  const getUser = async () => {
    try {
      const currentUser = await fetchUser(userId);
      setCurrentUser(currentUser);
    } catch (error) {}
  };

  const getGroups = async () => {
    try {
      const groups = await getUsersGroups(userId);
      setGroups(groups);
    } catch (error) {}
  };

  useEffect(() => {
    getConnectionStatus();
  }, [
    user.connections,
    user.pendingConnectionRequests,
    user.receivedConnectionRequests,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getUser(), getGroups()]);
      setIsLoading(false);
    };

    getConnectionStatus();
    fetchData();
    setIsLoading(true);
  }, [userId]);

  const getConnectionStatus = () => {
    if (userId === user?.id) {
      setConnectionStatus(
        `${
          user?.connections?.length === 1
            ? `${user?.connections?.length} Connection`
            : `${user?.connections?.length} Connections`
        } `,
      );
    } else if (user?.connections?.includes(userId)) {
      setConnectionStatus("Remove Connection");
    } else if (user?.pendingConnectionRequests?.includes(userId)) {
      setConnectionStatus("Pending");
    } else if (user?.receivedConnectionRequests?.includes(userId)) {
      setConnectionStatus("Pending");
    } else {
      setConnectionStatus("Add Connection");
    }
  };

  const handleStatusClick = async () => {
    switch (connectionStatus) {
      case "Add Connection":
        try {
          await addConnection(userId);

          const updatedPendingConnectionRequests = [
            ...user.pendingConnectionRequests,
            userId,
          ];
          updateUserFields({
            pendingConnectionRequests: updatedPendingConnectionRequests,
          });
        } catch (error) {}
        break;

      case "Remove Connection":
        try {
          await removeConnection(userId);
          const updatedConnections = user.connections.filter(
            (id: any) => id !== userId,
          );

          updateUserFields({
            connections: updatedConnections,
          });
        } catch (error) {}
        break;
    }
    if (userId === user?.id) {
      navigate(`/profile/${user?.id}/connections`);
    }
  };

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.PROFILE : MARGIN.LARGE.PROFILE;
  };

  if (isLoading) {
    return <ISpinner />;
  }

  return (
    <div className={getMargin()}>
      <div className="pb-4 mt-[2.96vh]">
        <IPanel height="600px ">
          <div>
            {isLargeScreen && (
              <div className="flex justify-between">
                <div className="lg:flex">
                  <img
                    src={currentUser?.photo}
                    alt="Avatar"
                    className="mr-[16px] w-[136px] h-[136px]  object-cover rounded-full overflow-hidden"
                  />
                  <div className="lg:mt-0 mt-[20px] ">
                    <div
                      className="mb-[8px]"
                      style={{
                        fontFamily: "Satoshi-Bold",
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontStyle: "normal",
                        letterSpacing: "-0.5px",
                      }}
                    >
                      {currentUser?.firstName} {currentUser?.lastName}
                    </div>
                    <div
                      className="mb-[20px]"
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontStyle: "normal",
                        color: "#323439",
                      }}
                    >
                      {currentUser?.city}, {currentUser?.state} |{" "}
                      {calculateAge(currentUser?.dateOfBirth)}{" "}
                      {currentUser?.gender !== "NONE"
                        ? " | " + toPascalCase(currentUser?.gender)
                        : ""}
                    </div>

                    <div className="text-sm text-[#7E858B] mb-[10px]">
                      <IReputationBar
                        reputation={currentUser?.reputation}
                        color="regal-blue"
                      />
                    </div>
                    <div
                      className="mb-[4px]"
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#7E858B",
                      }}
                    >
                      Last active:{" "}
                      {formatLastLogin(currentUser?.metadata?.lastLoginDate)}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleStatusClick()}
                    disabled={connectionStatus === "Pending"}
                    className="border px-[12px] py-[4px] "
                    style={{
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Regular",
                      lineHeight: "20px",
                    }}
                  >
                    {connectionStatus}
                  </button>
                </div>
              </div>
            )}

            {!isLargeScreen && (
              <div>
                <div className="flex justify-end mb-6">
                  <button
                    type="button"
                    onClick={() => handleStatusClick()}
                    disabled={connectionStatus === "Pending"}
                    className="border px-[12px] py-[4px] "
                    style={{
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "Satoshi-Regular",
                      lineHeight: "20px",
                    }}
                  >
                    {connectionStatus}
                  </button>
                </div>

                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-4">
                    <img
                      src={currentUser?.photo}
                      alt="Avatar"
                      className="mr-[16px]  object-cover rounded-full overflow-hidden"
                    />
                  </div>
                  <div className="col-span-8">
                    <div
                      className="mb-[8px]"
                      style={{
                        fontFamily: "Satoshi-Bold",
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontStyle: "normal",
                        letterSpacing: "-0.5px",
                      }}
                    >
                      {currentUser?.firstName} {currentUser?.lastName}
                    </div>
                    <div
                      className="mb-[20px]"
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontStyle: "normal",
                        color: "#323439",
                      }}
                    >
                      {currentUser?.city}, {currentUser?.state} |{" "}
                      {calculateAge(currentUser?.dateOfBirth)}
                      {currentUser?.gender !== "NONE"
                        ? " | " + toPascalCase(currentUser?.gender)
                        : ""}
                    </div>

                    <div className="text-sm text-[#7E858B] mb-[10px]">
                      <IReputationBar
                        reputation={currentUser?.reputation}
                        color="regal-blue"
                      />
                    </div>
                    <div
                      className="mb-[4px]"
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#7E858B",
                      }}
                    >
                      Last active:{" "}
                      {formatLastLogin(currentUser?.metadata?.lastLoginDate)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="grid lg:grid-cols-2 gap-8 pt-8 lg:gap-4  ">
              {Object.entries(currentUser?.attributes || {})
                .sort(([, attrA]: any, [, attrB]: any) => {
                  if (attrA.level > attrB.level) return -1;
                  if (attrA.level < attrB.level) return 1;
                  return attrB.points - attrA.points;
                })
                .map(([attributeKey, attributeValue], index) => (
                  <div
                    key={attributeKey}
                    className="flex items-center xl:w-[100%] "
                  >
                    <IAttributeBar
                      attributeKey={attributeKey}
                      attributeValue={attributeValue as any}
                      color={getAttributeColor(attributeKey) as any}
                    />
                    <div
                      className="ml-auto border py-3 px-3 rounded "
                      style={{
                        color: getAttributeColor(attributeKey),
                        borderColor: getAttributeColor(attributeKey, 0.2),
                        backgroundColor: getAttributeColor(attributeKey, 0.2),
                      }}
                    >
                      {getIconForAttribute(attributeKey)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </IPanel>
      </div>

      <div>
        {groups &&
          (userId === user.id || user?.connections?.includes(userId)) && (
            <div className="mb-[1.85vh]">
              <IGroupPanel
                title={`${
                  userId !== user?.id
                    ? currentUser?.firstName + "'s Groups"
                    : "Your Groups"
                }`}
                groups={groups}
                onGroupClick={(groupName, groupId) => {
                  navigate(`/groups/${groupId}`);
                }}
                subHeader={`View groups ${
                  userId !== user?.id ? currentUser?.firstName : "you"
                } ${userId !== user?.id ? "has joined" : "have joined"}`}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default Profile;
