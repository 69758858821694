import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import { useGeolocation } from "../../../Context/GeoLocationContext";
import { DiscoverGroupsOn } from "../../../Constants/Constants";
import { useScreenSize } from "../../../Context/ScreenContext";

function Success() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const { location } = useGeolocation();
  const { isSmallerThanLargeScreen } = useScreenSize();

  const confettiColors = ["#5081FF", "#232427", "C7D5FB"];

  const confettiConfig = {
    width: viewportWidth,
    height: viewportHeight,
    numberOfPieces: 50,
    recycle: true,
    colors: confettiColors,
  };

  useEffect(() => {
    const handleResize = (): void => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="w-screen h-screen relative overflow-hidden flex justify-center items-center">
      <div className="absolute inset-0 w-1/2 h-full bg-white"></div>
      {!isSmallerThanLargeScreen && (
        <div className="absolute inset-0 left-1/2 w-1/2 h-full bg-gradient-to-tl from-slate-ish via-regal-blue to-white"></div>
      )}

      {/* Content Container */}
      <div className="w-full max-w-[1440px] h-full flex z-10">
        <div
          className={`h-full flex items-center justify-center relative ${
            isSmallerThanLargeScreen ? "w-full" : "w-1/2"
          }`}
        >
          <div className="max-w-[720px] w-full flex items-center justify-center">
            <div className="w-full flex flex-col items-center justify-center">
              <Confetti {...confettiConfig} />
              <div className="h-screen flex items-center justify-center">
                <div className="flex flex-col" style={{ width: "360px" }}>
                  <div className="mb-3 text-center">
                    <span
                      style={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "32px",
                        lineHeight: "24px",
                        color: "#323439",
                      }}
                    >
                      You're on the waitlist!
                    </span>
                  </div>
                  <div
                    className="text-center"
                    style={{
                      fontFamily: "Satoshi-Regular",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#323439",
                    }}
                  >
                    {location.state} access coming soon.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 pl-[32px] pb-[32px] "
          style={{
            fontFamily: "Satoshi-Regular",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "16px",
            color: "#7E858B",
          }}
        >
          ©Copyright ChatFindr 2024
        </div>
        {!isSmallerThanLargeScreen && (
          <div className="w-1/2 h-full flex items-center justify-center">
            <div className="max-w-[720px] w-full flex items-center justify-center">
              <DiscoverGroupsOn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Success;
