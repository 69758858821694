import React from "react";
import {
  AdventureIcon,
  CultureIcon,
  FitnessIcon,
  IntelligenceIcon,
  NightLifeIcon,
  SocialIcon,
} from "../Icons/Icons";

export function getAttributeColor(attribute: string, opacity = 1) {
  switch (attribute) {
    case "SOCIAL":
      return `rgba(104, 190, 241, ${opacity})`; // #68BEF1
    case "CULTURE":
      return `rgba(255, 166, 86, ${opacity})`; // #FF5050
    case "FITNESS":
      return `rgba(75, 206, 201, ${opacity})`; // #4BCEC9
    case "NIGHTLIFE":
      return `rgba(167, 121, 230, ${opacity})`; // #A979E6
    case "ADVENTURE":
      return `rgba(255, 80, 80, ${opacity})`; // #FF5050
    case "INTELLIGENCE":
      return `rgba(64, 184, 126, ${opacity})`; // #40B87E
  }
}

export const getIconForAttribute = (attribute: any) => {
  const icons: any = {
    social: <SocialIcon />,
    fitness: <FitnessIcon />,
    nightlife: <NightLifeIcon />,
    intelligence: <IntelligenceIcon />,
    culture: <CultureIcon />,
    adventure: <AdventureIcon />,
  };
  return icons[attribute?.toLowerCase()];
};

export const formatDate = (utcDateString: any, isMobile?: any) => {
  // Define options for displaying the date in local time zone
  const options: any = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // User's local time zone
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Construct the date object assuming UTC if there's no timezone in the string
  const date = new Date(
    utcDateString.includes("Z") ? utcDateString : utcDateString + "Z",
  );

  // Format the date using the user's local time zone
  return date.toLocaleString("en-US", options);
};

export const formatFullDate = (utcDateString: any, isMobile?: any) => {
  // Define options for displaying the date in local time zone
  const options: any = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // User's local time zone
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    hour12: true,
  };

  // Check if the string includes "Z" or if we need to append it to ensure UTC format
  const formattedDateString = utcDateString?.includes("Z")
    ? utcDateString
    : `${utcDateString}Z`;

  // Construct the date object assuming UTC if there's no timezone in the string
  const date = new Date(formattedDateString);

  // Ensure the date is valid before formatting
  if (isNaN(date.getTime())) {
    console.error("Invalid date provided:", utcDateString);
    return utcDateString; // Return original string or handle error as needed
  }

  // Format the date using the user's local time zone
  return date.toLocaleString("en-US", options);
};

export const formatDateToMonthYear = (dateString: any) => {
  const options: any = {
    month: "long", // "long" for full month name, "short" for abbreviated
    year: "numeric", // "numeric" for full year
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

export const toPascalCase = (str: any) => {
  const conjunctions = ["and", "or", "but", "nor", "so", "for", "yet"]; // Add more conjunctions if needed

  if (str?.startsWith("OTHER_")) {
    return "Other";
  }
  // Replace underscores with spaces and split the string into words
  return str
    ?.replace(/_/g, " ")
    ?.split(" ")
    ?.map((word: any, index: any) => {
      if (conjunctions?.includes(word?.toLowerCase()) && index !== 0) {
        return word?.toLowerCase();
      } else {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
      }
    })
    ?.join(" ");
};

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // covers 4th to 20th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatLastLogin = (lastLoginDate: string | undefined) => {
  if (!lastLoginDate) {
    return "New Account";
  }

  const date = new Date(lastLoginDate);
  const day = date.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  // Format month and day separately to correctly insert the ordinal day
  const month = date.toLocaleString("en-US", { month: "long" });

  // Assuming you want to show the time as well

  // Construct the date string manually
  return `${month} ${day}${ordinalSuffix}`;
};

export const calculateAge = (birthdate: any) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const describeDateAge = (dateString: string): string => {
  const now = new Date().getTime(); // Get current time in milliseconds
  const date = new Date(dateString).getTime(); // Convert input date to milliseconds
  const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
  const oneWeek = 7 * oneDay;
  const oneMonth = 30 * oneDay; // Approximate value
  const oneYear = 365 * oneDay; // Approximate value

  const diff = now - date; // Difference in milliseconds

  if (diff < oneWeek) {
    return "Less than a week old";
  } else if (diff < oneMonth) {
    return "Less than a month old";
  } else if (diff < oneYear) {
    const months = Math.round(diff / oneMonth);
    return `${months} month${months > 1 ? "s" : ""} old`;
  } else {
    const years = Math.round(diff / oneYear);
    return `${years} year${years > 1 ? "s" : ""} old`;
  }
};

export const ACCORDIAN_OPTIONS = [
  {
    header: "How can I discover local groups in my area?",
    body: "You can find local groups via discovery or a direct search. Filter by distance, privacy, and lifestyle attributes",
  },
  {
    header: "What are the steps to join public, private, or restricted groups?",
    body: "To join a group, request access through the group’s page. Public groups are open to all, private groups require approval, and restricted groups are only available to the organizer's connections.",
  },
  {
    header: "How do I access the group chat once I join?",
    body: "Once you’ve joined a group, you’ll automatically gain access to the group chat. Engage with other members and start participating in discussions.",
  },
  {
    header: "How can I view and participate in the social calendar?",
    body: "See an overview of all events in Event Home or visit each group to see the social calendar accessible from the group’s main page. Join the group to view and participate in upcoming events.",
  },
  {
    header: "What are attribute points and how can I earn them?",
    body: "Attribute points are earned through active participation in group chats, attending events, and engaging with the app. The more you interact, the more points you accumulate.",
  },
  {
    header: "Is it possible to create my own groups and events?",
    body: "Yes, you can create your own groups and events. Navigate to the 'Create Group' or 'Create Event' section in the app and fill in the required details.",
  },
  {
    header: "How can I contact support for assistance or to provide feedback?",
    body: "You can contact our support team through the 'Support' section in the app settings or by emailing support@chatfindr.com. We're here to assist you with any questions or feedback.",
  },
  {
    header: "How does the ChatFindr ranking system work?",
    body: "The ranking system is based on your activity within the app. Earn points by engaging in group chats, attending events, and participating in activities. Check the leaderboards to see how you rank.",
  },
];

export const CHECK_SECTION_ITEMS = [
  {
    heading: "Heading goes here",
    text: "A lot of random works just to see if this is working the way that it is supposed to.",
  },
  {
    heading: "Heading goes here",
    text: "A lot of random works just to see if this is working the way that it is supposed to.",
  },
  {
    heading: "Heading goes here",
    text: "A lot of random works just to see if this is working the way that it is supposed to.",
  },
];

export const MONTH_OPTIONS: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const STATES: string[] = [
  "AL", // Alabama
  "AK", // Alaska
  "AZ", // Arizona
  "AR", // Arkansas
  "CA", // California
  "CO", // Colorado
  "CT", // Connecticut
  "DE", // Delaware
  "FL", // Florida
  "GA", // Georgia
  "HI", // Hawaii
  "ID", // Idaho
  "IL", // Illinois
  "IN", // Indiana
  "IA", // Iowa
  "KS", // Kansas
  "KY", // Kentucky
  "LA", // Louisiana
  "ME", // Maine
  "MD", // Maryland
  "MA", // Massachusetts
  "MI", // Michigan
  "MN", // Minnesota
  "MS", // Mississippi
  "MO", // Missouri
  "MT", // Montana
  "NE", // Nebraska
  "NV", // Nevada
  "NH", // New Hampshire
  "NJ", // New Jersey
  "NM", // New Mexico
  "NY", // New York
  "NC", // North Carolina
  "ND", // North Dakota
  "OH", // Ohio
  "OK", // Oklahoma
  "OR", // Oregon
  "PA", // Pennsylvania
  "RI", // Rhode Island
  "SC", // South Carolina
  "SD", // South Dakota
  "TN", // Tennessee
  "TX", // Texas
  "UT", // Utah
  "VT", // Vermont
  "VA", // Virginia
  "WA", // Washington
  "WV", // West Virginia
  "WI", // Wisconsin
  "WY", // Wyoming
];

export const SIDE_BAR_ITEMS = [
  {
    label: "Discover",
    value: "discover",
    secondIcon:
      "M10 18.3334C14.6024 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6024 1.66669 10 1.66669C5.39765 1.66669 1.66669 5.39765 1.66669 10C1.66669 14.6024 5.39765 18.3334 10 18.3334Z",
    icon: "M13.5334 6.46669L11.7667 11.7667L6.46669 13.5334L8.23335 8.23335L13.5334 6.46669Z",
  },
  {
    label: "Groups",
    value: "groups",

    icon: "M14.1666 17.5V15.8333C14.1666 14.9493 13.8155 14.1014 13.1903 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16665C3.28259 12.5 2.43475 12.8512 1.80962 13.4763C1.1845 14.1014 0.833313 14.9493 0.833313 15.8333V17.5M19.1666 17.5V15.8333C19.1661 15.0948 18.9203 14.3773 18.4678 13.7936C18.0153 13.2099 17.3818 12.793 16.6666 12.6083M13.3333 2.60833C14.0503 2.79192 14.6858 3.20892 15.1397 3.79359C15.5935 4.37827 15.8398 5.09736 15.8398 5.8375C15.8398 6.57764 15.5935 7.29673 15.1397 7.88141C14.6858 8.46608 14.0503 8.88308 13.3333 9.06667M10.8333 5.83333C10.8333 7.67428 9.34093 9.16667 7.49998 9.16667C5.65903 9.16667 4.16665 7.67428 4.16665 5.83333C4.16665 3.99238 5.65903 2.5 7.49998 2.5C9.34093 2.5 10.8333 3.99238 10.8333 5.83333Z",
  },

  {
    label: "Events",
    value: "events",
    icon: "M13.3333 1.66667V5M6.66667 1.66667V5M2.5 8.33333H17.5M4.16667 3.33333H15.8333C16.7538 3.33333 17.5 4.07953 17.5 5V16.6667C17.5 17.5871 16.7538 18.3333 15.8333 18.3333H4.16667C3.24619 18.3333 2.5 17.5871 2.5 16.6667V5C2.5 4.07953 3.24619 3.33333 4.16667 3.33333Z",
    secondIcon: "",
  },
  {
    label: "Chat",
    value: "chat",
    icon: "M17.5 9.58336C17.5029 10.6832 17.2459 11.7683 16.75 12.75C16.162 13.9265 15.2581 14.916 14.1395 15.6078C13.021 16.2995 11.7319 16.6662 10.4167 16.6667C9.31678 16.6696 8.23176 16.4126 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7683 3.33047 10.6832 3.33333 9.58336C3.33384 8.26815 3.70051 6.97907 4.39227 5.86048C5.08402 4.7419 6.07355 3.838 7.25 3.25002C8.23176 2.75413 9.31678 2.49716 10.4167 2.50002H10.8333C12.5703 2.59585 14.2109 3.32899 15.441 4.55907C16.671 5.78915 17.4042 7.42973 17.5 9.16669V9.58336Z",
    secondIcon: "",
  },
  {
    label: "Notifications",
    value: "notifications",
    secondIcon: "",
    icon: "M11.4417 17.5C11.2952 17.7526 11.0849 17.9622 10.8319 18.1079C10.5788 18.2537 10.292 18.3304 10 18.3304C9.70802 18.3304 9.42116 18.2537 9.16814 18.1079C8.91513 17.9622 8.70484 17.7526 8.55833 17.5M15 6.66667C15 5.34058 14.4732 4.06881 13.5355 3.13113C12.5979 2.19345 11.3261 1.66667 10 1.66667C8.67392 1.66667 7.40215 2.19345 6.46447 3.13113C5.52678 4.06881 5 5.34058 5 6.66667C5 12.5 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5 15 6.66667Z",
  },
  {
    label: "Leaderboard",
    value: "leaderboard",
    icon: "M6.84169 11.575L5.83335 19.1666L10 16.6666L14.1667 19.1666L13.1584 11.5666M15.8334 6.66665C15.8334 9.88831 13.2217 12.5 10 12.5C6.77836 12.5 4.16669 9.88831 4.16669 6.66665C4.16669 3.44499 6.77836 0.833313 10 0.833313C13.2217 0.833313 15.8334 3.44499 15.8334 6.66665Z",
  },
];

export const Colors = { White: "bg-white", Slate: "bg-slate-50" };

export const MARGIN = {
  MOBILE: {
    DISCOVER: "ml-[16px] mr-[16px]",
    MEMBERS: "ml-[16px] mr-[16px]",
    PROFILE: "ml-[16px] mr-[16px]",
    NOTIFICATIONS: "ml-[16px] mr-[16px]",
    CHAT: "ml-[16px] mr-[16px]",
    LEADER_BOARD: "ml-[16px] mr-[16px]",
    SETTINGS: "ml-[16px] mr-[16px]",
    GROUPS: {
      GROUP: "ml-[16px] mr-[16px]",
      GROUP_HOME: "ml-[16px] mr-[16px]",
      GROUP_DASH: "ml-[16px] mr-[16px]",
      GROUP_MANAGE: "ml-[16px] mr-[16px]",
      GROUP_CREATE: "ml-[16px] mr-[16px]",
      GROUP_EDIT: "ml-[16px] mr-[16px]",
      GROUP_SHOW_ALL: "ml-[16px] mr-[16px]",
    },
    EVENTS: {
      EVENT: "ml-[16px] mr-[16px]",
      EVENT_HOME: "ml-[16px] mr-[16px]",
      EVENT_DASH: "ml-[16px] mr-[16px]",
      EVENT_MANAGE: "ml-[16px] mr-[16px]",
      EVENTS_ALL: "ml-[16px] mr-[16px]",
      EVENTS_CREATE: "ml-[16px] mr-[16px]",
    },
  },
  LARGE: {
    LANDING: "ml-[144px] mr-[144px]",
    DISCOVER: "ml-[72px] mr-[72px]",
    MEMBERS: "ml-[72px] mr-[72px]",
    PROFILE: "ml-[72px] mr-[72px]",
    NOTIFICATIONS: "ml-[72px] mr-[72px]",
    CHAT: "ml-[20px] mr-[20px]",
    LEADER_BOARD: "ml-[72px] mr-[72px]",
    SETTINGS: "ml-[72px] mr-[72px]",
    GROUPS: {
      GROUP: "ml-[72px] mr-[72px]",
      GROUP_HOME: "ml-[72px] mr-[72px]",
      GROUP_DASH: "ml-[72px] mr-[72px]",
      GROUP_MANAGE: "ml-[72px] mr-[72px]",
      GROUP_CREATE: "ml-[72px] mr-[72px]",
      GROUP_EDIT: "ml-[72px] mr-[72px]",
      GROUP_SHOW_ALL: "ml-[72px] mr-[72px]",
    },

    EVENTS: {
      EVENT: "ml-[80px] mr-[80px]",
      EVENT_HOME: "ml-[72px] mr-[72px]",
      EVENT_DASH: "ml-[80px] mr-[80px]",
      EVENT_MANAGE: "ml-[80px] mr-[80px]",
      EVENTS_ALL: "ml-[80px] mr-[80px]",
      EVENTS_CREATE: "ml-[80px] mr-[80px]",
    },
  },
};

export const SUBSCRIPTION_PLANS = [
  {
    header: "Basic",
    subHeader: "Free plan for all users.",
    price: "$1.99",
    details: [
      "Account creation",
      "Public groups and events",
      "platform access",
    ],
    value: "BASIC",
  },
  {
    header: "Plus",
    subHeader: "Ideal for most users.",
    price: "$3.99",
    details: [
      "Public groups & events",
      "Private groups & events",
      "Create public groups & events",
      "Earn points and level up attributes",
    ],
    value: "PLUS",
  },
  {
    header: "Premium",
    subHeader: "Ideal for the full control.",
    price: "$7.99",
    details: [
      "Public groups & events",
      "Private groups & events",
      "Create public groups & events",
      "Earn points and level up attributes",
      "Early access to advanced features & analytics",
    ],
    value: "PREMIUM",
  },
];

export const STRIPE_PROMISE = process.env.REACT_APP_STRIPE_KEY || "";

export const TOAST_MESSAGES = {
  SUPPORT: {
    SUCCESS: "Support request sent",
    FAILURE: "Support request failed",
  },
  NOTIFICATIONS: {
    SUCCESS: "Notifications updated",
    FAILURE: "Failed to update notifications",
  },
  PASSWORD: {
    SUCCESS: "Password updated",
    FAILURE: "Password update failed",
  },
  PAYMENT_METHOD: {
    SUCCESS: "Payment method updated",
    FAILURE: "Payment method update failed",
  },
  SUBSCRIPTION_UPDATE: {
    SUCCESS: "Subscription updated",
    FAILURE: "Subscription update failed",
  },
  SUBSCRIPTION_CANCEL: {
    SUCCESS: "Subscription canceled",
    FAILURE: "Failed to cancel subscription",
  },
  ACCOUNT_UPDATE: {
    SUCCESS: "Account updated",
    FAILURE: "Account update failed",
  },
  ACCOUNT_PHOTO: {
    SUCCESS: "Profile picture updated",
    FAILURE: "Profile picture update failed",
  },
  JOIN_GROUP: {
    SUCCESS: "Joined group",
    FAILURE: "Failed to join group",
  },
  JOIN_EVENT: {
    SUCCESS: "Event joined",
    FAILURE: "Event join failed",
  },
  LEAVE_GROUP: {
    SUCCESS: "Left group",
    FAILURE: "Failed to leave group",
  },
  LEAVE_EVENT: {
    SUCCESS: "Event left",
    FAILURE: "Event leave failed",
  },

  UPDATE_GROUP: {
    SUCCESS: "Group updated",
    FAILURE: "Group update failed",
  },
  UPDATE_EVENT: {
    SUCCESS: "Event updated",
    FAILURE: "Event update failed",
  },
  DELETE_EVENT: {
    SUCCESS: "Event deleted",
    FAILURE: "Event deletion failed",
  },
};

export const formatEventMapping = (type: string): string => {
  if (!type) return "";

  if (type.startsWith("OTHER_")) {
    return "OTHER";
  }

  // Replace "AND" with "&"
  let formattedType = type.replace(/AND/g, "&");

  // Split the string by underscores
  const words = formattedType.split("_");

  // Check if the type is in uppercase, then return as it is with spaces and replaced "&"
  if (type === type.toUpperCase()) {
    return words.join(" ");
  }

  // If not in uppercase, capitalize each word and join them with spaces
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const maxAttendeesOptions = [
  { label: "No Limit", value: 0 },

  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
];

export const DiscoverGroupsOn = () => {
  return (
    <div className="text-white text-3xl text-center" style={{ width: "500px" }}>
      <span
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "32px",
          lineHeight: "24px",
          color: "white",
        }}
      >
        Discover groups on
      </span>{" "}
      <span
        style={{
          fontFamily: "Satoshi-Bold",
          fontSize: "32px",
          lineHeight: "24px",
          color: "white",
        }}
      >
        ChatFindr
      </span>{" "}
      <span
        style={{
          fontFamily: "Satoshi-Regular",
          fontSize: "32px",
          lineHeight: "24px",
          color: "white",
        }}
      >
        and get access to exclusive chats and members only social calendars.
      </span>
    </div>
  );
};
