import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ILabel from "../../../../Library/Label/ILabel";

import { useNavigate } from "react-router-dom";
import { useDash } from "../../../../Context/DashboardContext";
import { CategoryKey, EventCreateMapping } from "./EventCreateMapping";
import ISpinner from "../../../../Library/Spinner/ISpinner";

import EventPhotoForm from "../EventForms/EventPhotoForm";

import {
  MARGIN,
  maxAttendeesOptions,
  toPascalCase,
} from "../../../../Constants/Constants";
import { useScreenSize } from "../../../../Context/ScreenContext";
import {
  validateAddress,
  validateCategory,
  validateGroup,
  validateDescription,
  validateEndDate,
  validateName,
  validateStartDate,
  validateMaxAttendees,
} from "./EventCreateValidations";
import Toast from "../../../../Library/Toast/Toast";
import IButton from "../../../../Library/Button/IButton";
import IInput from "../../../../Library/Input/IInput";
import IDropdown from "../../../../Library/Dropdown/IDropdown";
import ITextArea from "../../../../Library/TextArea/ITextArea";
import IDateTimePicker from "../../../../Library/DateTimePicker/DateTimePicker";
import { useChat } from "../../../../Context/ChatContext";
import IMultiSelect from "../../../../Library/MultiSelect/IMultiSelect";
import { useGroupService } from "../../../../Services/GroupService/GroupService";
import { useEventService } from "../../../../Services/EventService/EventService";

function EventCreate() {
  let navigate = useNavigate();
  const { groups } = useChat();
  const { isMobile, isSmallerThanLargeScreen } = useScreenSize();
  const { getGroupMembers } = useGroupService();
  const { user } = useDash();
  const { updateEventPicture, createEvent } = useEventService();
  const [name, setName] = useState<string>("");

  const [address, setAddress] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [imageFiles] = useState<File[]>([]);
  const [type] = useState<any>();
  const [group, setGroup] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [, setAttribute] = useState<any>();
  const [joinedGroups, setJoinedGroups] = useState<any>([]);
  const [message, setMessage] = useState<any>();
  const [isError, setIsError] = useState<any>();
  const [, setIsFileValid] = useState<any>();
  const [maxAttendees, setMaxAttendees] = useState<any>(0);
  const [groupMembers, setGroupMembers] = useState<any>();
  const [attendees, setAttendees] = useState<any>();
  const [image, setImage] = useState<string>("");

  const fileInputRef: any = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [privacy] = useState<any>();

  const [detailErrors, setDetailErrors] = useState({
    nameError: "",
    groupError: "",
    categoryError: "",
    descriptionError: "",
    maxAttendeesError: "",
  });

  const [locationTimeErrors, setLocationTimeErrors] = useState({
    addressError: "",
    startDateError: "",
    endDateError: "",
  });

  const [photoError, setPhotoError] = useState("");

  const validateDetailsStep = () => {
    const nameError = validateName(name);
    const groupError = validateGroup(group);
    const categoryError = validateCategory(category);
    const descriptionError = validateDescription(description);
    const maxAttendeesError = validateMaxAttendees(maxAttendees);

    setDetailErrors({
      nameError,
      descriptionError,
      groupError,
      categoryError,
      maxAttendeesError,
    });

    return (
      !nameError && !descriptionError && !categoryError && !groupError
      //!maxAttendeesError
    );
  };

  const validateLocationTimeStep: any = () => {
    const addressError = validateAddress(address);
    const startDateError = validateStartDate(startTime);
    const endDateError = validateEndDate(endTime, startTime);

    setLocationTimeErrors({
      addressError,
      startDateError,
      endDateError,
    });

    return !addressError && !startDateError && !endDateError;
  };

  // const validateImage = () => {
  //   const imageError = validateImageFile(image);
  //   setPhotoError(imageError);
  //   return !imageError;
  // };

  const [filteredOptions, setFilteredOptions] = useState<any>();

  useEffect(() => {
    setMaxAttendees(0);
  }, []);

  useEffect(() => {
    if (group) {
      const fetchGroupMembers = async () => {
        const selectedGroup = joinedGroups?.find(
          (comm: any) => comm.value === group,
        );

        if (selectedGroup) {
          try {
            const response = await getGroupMembers(selectedGroup.value);
            const members = response
              .map((member: any) => ({
                label: `${toPascalCase(member?.firstName)} ${toPascalCase(
                  member?.lastName,
                )}`,
                value: member.id,
              }))
              .filter((member: any) => member.value !== user.id);
            console.log("Members:", members);
            setGroupMembers(members);
          } catch (error) {
            console.error("Failed to fetch group members:", error);
          }
        }

        if (selectedGroup && selectedGroup.attribute) {
          const filteredOptions = Object.entries(EventCreateMapping)
            .filter(([, attribute]) => attribute === selectedGroup.attribute)
            .map(([key]) => {
              const label = key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (l) => l.toUpperCase());
              return { label: toPascalCase(label), value: key };
            })
            .map((option) => {
              if (option?.value?.startsWith("OTHER_")) {
                return {
                  ...option,
                  label: "Other",
                };
              }
              return option;
            });

          console.log("Filtered Options:", filteredOptions);
          setFilteredOptions(filteredOptions);
        }
      };

      fetchGroupMembers();
    }
  }, [group]);

  // const categoryOptions = Object.entries(EventCreateMapping).map(
  //   ([key, value]) => ({
  //     label: toPascalCase(key.replace(/_/g, " ")),
  //     value: key,
  //   }),
  // );

  const handleCategoryChange = (selectedCategory: CategoryKey) => {
    setCategory(selectedCategory);
    setAttribute(EventCreateMapping[selectedCategory]);
  };

  useEffect(() => {
    setAttendees([]);
  }, [maxAttendees]);

  useEffect(() => {
    setAttendees([]);
    setMaxAttendees(0);
    setCategory("");
  }, [group]);

  useEffect(() => {
    if (!groups) {
      console.log("Waiting for groups to be loaded...");
      return;
    }

    const transformedData = groups.map((group) => ({
      label: group.name,
      value: group.id,
      attribute: group?.requirements?.attribute,
    }));

    setJoinedGroups(transformedData);
    setLoading(false);
  }, [groups]); // Depend on groups

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 1048576; // 10MB in bytes
    const allowedFormats = ["jpg", "jpeg", "png", "webp"];
    setMessage(""); // Clear previous messages
    setIsError(false); // Reset error state

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (
        fileSize > maxFileSize ||
        (fileExtension && !allowedFormats.includes(fileExtension))
      ) {
        setMessage(`Invalid photo size or type`);
        setIsError(true);
        setIsFileValid(false);
        event.target.value = "";
      } else {
        setPhotoError("");
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target?.result as string); // Set image for preview
          setMessage(""); // Clear message
          setIsError(false); // Clear error state
          setIsFileValid(true); // Mark file as valid
        };
        reader.readAsDataURL(file);
      }
    } else {
      // Handle case where file selection is cancelled
      setIsFileValid(false); // Remain or set to invalid
    }
  };

  const handleSelectedAttendees = (attendees: any) => {
    setAttendees(attendees);
  };

  const handleCreateEvent = async (e: any) => {
    e.preventDefault();
    const isDetailValid = validateDetailsStep();
    const isLocationValid = validateLocationTimeStep();

    if (isDetailValid && isLocationValid) {
      const eventAttendees = attendees.reduce((acc: any, attendee: any) => {
        acc[attendee] = {};
        return acc;
      }, {});

      const event = {
        name,
        description,
        address,
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
        type,
        category,
        host: {
          id: group,
        },
        requirements: {
          privacy,
        },
        maxAttendees: maxAttendees,
        attendees: attendees.length > 0 ? eventAttendees : {},
      };

      let eventId;
      try {
        const result = await createEvent(event);
        eventId = result.id;

        navigate(`/events/${eventId}/dashboard`, {
          state: { toast: "Event Created" },
        });
      } catch (error) {
        setMessage("Failed to create event.");
        setIsError(true);
        setLoading(false);
        return;
      }

      if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
        const formData = new FormData();
        formData.append("file", fileInputRef.current.files[0]);

        try {
          const response = await updateEventPicture(eventId, formData);
          const imageUrl = await response.text();
          setImage(imageUrl);
          setMessage("Photo updated successfully!");
          setIsError(false);
        } catch (error) {
          console.error("Failed to update photo:", error);
          setMessage("Failed to update photo.");
          setIsError(true);
        }
      }

      setLoading(false);
    } else {
      console.log("Validation failed", { isDetailValid, isLocationValid });
    }
  };

  if (loading) {
    return <ISpinner />;
  }

  return (
    <div
      className={`${
        isMobile
          ? MARGIN.MOBILE.EVENTS.EVENTS_CREATE
          : MARGIN.LARGE.EVENTS.EVENTS_CREATE
      } pb-8`}
    >
      <Toast message={message} setMessage={setMessage} isError={isError} />
      <div
        className={` ${
          !isSmallerThanLargeScreen
            ? " mt-[2.96vh] mb-[2.96vh]"
            : "mt-[1.48vh] mb-[2.96vh]"
        } `}
      >
        <ILabel text="Create Event"></ILabel>
      </div>
      <form onSubmit={handleCreateEvent}>
        <div style={{ maxWidth: "460px" }}>
          <div className="pb-4">
            <IInput
              label="Name"
              name="name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              errorMessage={detailErrors.nameError}
            ></IInput>
          </div>

          <div className="pb-4">
            <IDropdown
              labelText="Group"
              options={joinedGroups}
              onChange={(newValue) => setGroup(newValue)}
              value={group}
              errorMessage={detailErrors.groupError}
            ></IDropdown>
          </div>

          <div className="pb-4">
            <IDropdown
              labelText="Category"
              options={filteredOptions}
              onChange={(newValue) =>
                handleCategoryChange(newValue as CategoryKey)
              }
              value={category}
              errorMessage={detailErrors.categoryError}
            ></IDropdown>
          </div>

          <div className="pb-4">
            <IDropdown
              labelText="Max Attendees"
              options={maxAttendeesOptions}
              onChange={(newValue) => setMaxAttendees(newValue)}
              value={maxAttendees}
              //errorMessage={detailErrors.maxAttendeesError}
            ></IDropdown>
          </div>

          <div className="pb-4">
            <IMultiSelect
              labelText={`Invites ${
                maxAttendees ? `( Select up to ${maxAttendees - 1} )` : ""
              }`}
              options={groupMembers}
              onChange={(selectedAttendees: any[]) =>
                handleSelectedAttendees(selectedAttendees)
              }
              maxAttendees={maxAttendees}
              value={attendees}
            />
          </div>

          <div className="pb-4">
            <IInput
              label="Address"
              name="name"
              value={address}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAddress(e.target.value)
              }
              errorMessage={locationTimeErrors.addressError}
            ></IInput>
          </div>

          <div className="pb-4">
            {" "}
            <IDateTimePicker
              label="Start Date"
              value={startTime}
              onChange={(newDate: any) => setStartTime(newDate)}
              errorMessage={locationTimeErrors.startDateError}
            />
          </div>

          <div className="pb-4">
            <IDateTimePicker
              label="End Date"
              value={endTime}
              onChange={(newDate: any) => setEndTime(newDate)}
              errorMessage={locationTimeErrors.endDateError}
            />
          </div>

          <div className="pb-4">
            <ITextArea
              name="description"
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              errorMessage={detailErrors.descriptionError}
            />
          </div>
        </div>

        <div className="mt-4">
          <EventPhotoForm
            imageFiles={imageFiles}
            handleImageChange={handleImageChange}
            fileInputRef={fileInputRef}
            image={image}
            photoError={photoError}
          />
        </div>

        <div className="pt-4">
          {" "}
          <IButton
            text={"Create"}
            type="submit"
            bgColor={"bg-regal-blue"}
            textColor={"text-white"}
            className="px-6 mr-2 mt-4"
          />{" "}
        </div>
      </form>
    </div>
  );
}

export default EventCreate;
