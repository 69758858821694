import React, { useEffect, useState } from "react";
import ILabel from "../../../Library/Label/ILabel";
import { useNavigate } from "react-router-dom";
import ISpinner from "../../../Library/Spinner/ISpinner";
import IUserTable from "../../../Library/IUserTable/IUserTable";
import { useScreenSize } from "../../../Context/ScreenContext";
import { MARGIN } from "../../../Constants/Constants";
import { useConnectionService } from "../../../Services/ConnectionsService/ConnectionsService";

function Connections() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [connections, setConnections] = useState<any>();
  const { isMobile } = useScreenSize();
  const { getConnections } = useConnectionService();

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const data = await getConnections();

        setConnections(data);
      } catch (error) {}
      setIsLoading(false);
    };

    fetchConnections();
  }, []);

  const getMargin = () => {
    return isMobile ? MARGIN.MOBILE.MEMBERS : MARGIN.LARGE.MEMBERS;
  };

  if (isLoading) {
    return <ISpinner />;
  }
  return (
    <div className={getMargin()}>
      <div className="pb-4 pt-4">
        <ILabel text="Connections" />
      </div>{" "}
      <IUserTable
        users={connections}
        onRowClick={(userId) => {
          navigate(`/profile/${userId}`);
        }}
      />
    </div>
  );
}

export default Connections;
