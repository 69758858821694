import React, { useState } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

interface AccordionItem {
  header: string;
  body: string;
}

interface AccordionProps {
  items: AccordionItem[];
}

function Accordion({ items }: AccordionProps) {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div>
      <div>
        {items.map((item, index) => (
          <div key={index} className="my-2">
            <button
              className={`flex w-full justify-between items-center rounded-lg py-4 px-4 ${
                openIndex !== index ? "border-b" : ""
              }`}
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            >
              <span
                style={{
                  fontFamily: "Satoshi-Bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  letterSpacing: "-1px",
                }}
                className="text-left mr-4"
              >
                {item.header}
              </span>
              <div className="flex-none">
                {openIndex === index ? (
                  <MinusCircleIcon className="h-5 w-5 text-regal-blue" />
                ) : (
                  <PlusCircleIcon className="h-5 w-5 text-regal-blue" />
                )}
              </div>
            </button>
            {openIndex === index && (
              <div
                className={`font-light text-sm text-gray-500 text-left px-4 border-b pb-[32px]`}
              >
                <span
                  style={{
                    fontFamily: "Satoshi-Regular",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {item.body}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Accordion;
